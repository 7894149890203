import React, { useEffect, useState } from 'react'
import {Link,useLocation,useNavigate} from 'react-router-dom'
import LoadingIndicator from "react-loading-indicator";
import { Cookies, useCookies } from 'react-cookie';
import axios from "axios";
import ErrorPg from '../Error';
import Menu from '../MenuBar';
import Footer from '../Footer';

export default function Success() {
    let location = useLocation();
    const navigate = useNavigate()
    const [cart,setCart] = useState("")
    const [isLoading,setIsLoading] = useState(true)
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [error,setError] = useState("")
    useEffect(() =>{
      if(location.state == "" || location.state == null){
        navigate("/")
      }else{
        const payId = location.state.payId
        let User = cookies.user
        successOrder(payId,User)
      }
    },[])
    const successOrder = (payId,User) => {
      setIsLoading(true)
      axios.post(apiurl+"api/successOrders",null,{
          params:{
              authToken : User.myToken,
              payid : payId,
          }
      })
      .then(res => {
          setCart(res.data);
      }).then(() => {
          setIsLoading(false);
      })        
  }
  if(error) {
    return (
      <ErrorPg />
    )
  }
  else if(isLoading){
    return(
        <div className='loadingbox addloading'>
            <div className="loadercircle container"><LoadingIndicator segmentWidth={4} segmentLength={9}/> </div> 
        </div>
    )
  } 
  else
    return (
      <React.Fragment>
        <Menu />
        <section className='success-page'>
            <div className='container maxwidth'>
                <div className='w90 float-center'>
                  <div className='w50 float-center success-details'>
                    <div className='animation-wrapper container center-text'>
                      <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                          <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                          <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                      </svg>
                      <p>Order Placed Succesfully</p>
                    </div>
                    <div className='order-summary container'>
                      <div className='container cart-heading'>
                        <div className='w95 float-center center-text'>
                            <p>Order Summary</p>
                        </div>
                      </div>
                      <div className='container summarybox'>
                          <div className='container'>
                            <div className='container cart-itemslist' >
                              {cart.map((citem,i) =>{
                                let product = JSON.parse(citem.product)
                                return(
                                <div className='cart-item container' key={i}>
                                    <div className='w90 float-center'>
                                        <div className='container cart-item-details'>
                                            <div className='w30 float-left'>
                                                <div className="cart-itemimg">
                                                    <img src={product.image} alt={product.name} referrerPolicy="no-referrer" />
                                                </div>
                                            </div>
                                            <div className='w70 float-left'>
                                                <div className="w90 float-center">
                                                        <div className="container prod-name">
                                                            <p>{product.name}</p>
                                                        </div>
                                                    <div className="container cart-prod-price">
                                                        <div className="container pricebox cardprices">
                                                            <div className="finalamt mainp">
                                                                <p>₹{product.famnt}</p>
                                                            </div>
                                                            <div className="main-price oldp ">
                                                                <p>₹{product.price}</p>
                                                            </div>
                                                            <div className="percent-off cardoff">
                                                                <p>{product.off}% off</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )
                                })}
                            </div>
                          </div>
                      </div>
                      <div className='continue container payment-options'>
                          <div className='w80 float-center'>
                              <div className='w50 float-left'>
                                  <div className='w98 float-left paynow'>
                                      <button onClick={() => navigate("/",{replace : true})}>Continue Shopping</button>
                                  </div>
                              </div>
                              <div className='w50 float-left'>
                                  <div className='w98 float-right paynow'>
                                      <button onClick={() => navigate("/user-dashboard/my-orders",{replace : true,state: {act : 3}})}>Manage Orders</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
        </section>
        <Footer />
      </React.Fragment>
    )
  }
  