import React from 'react'
import Menu from '../MenuBar'
import Footer from '../Footer'
import Loading from '../Loading';
import ErrorPg from '../Error';
import { useState } from 'react';
import { useCookies,Cookies } from 'react-cookie';
import { useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import moment from 'moment/moment';

export default function OrderDetails() {
    var a = 0;
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [errMsg,setErrMsg] = useState("")
    const [orderDet,setOrderDet] = useState("")
    const [cookies,setCookie,removeCookie] = useCookies('')
    const [product,setProduct] = useState("")
    const [address,setAddress] = useState("")
    const [ordtrack,setOrdTrack] = useState("")
    const params = useParams()
    const invid = params.id
    useEffect(() =>{
        if(a == 0){
            fetchUser()
            console.log(invid)
        }
        a++
    },[])
    const fetchUser = () =>{
        if(cookies.user != undefined && cookies.user != null && cookies.user != ""){
            if(cookies.user.emailverified == "" || cookies.user.emailverified == null){
                window.location.href = "/verify-otp"
            }
            else{
                fetchOrder(cookies.user.myToken)
            }
        }
        else{
            window.location.href = "/"
        }
    }
    const fetchOrder = (id) =>{
        axios.post(apiurl + "api/trackOrder",null,{
            params: {
                authToken : id,
                invid : invid
            }
        })
        .then(res =>{
            console.log(JSON.parse(res.data.product) , res.data)
            setOrderDet(res.data)
            setProduct(JSON.parse(res.data.product))
            setAddress(JSON.parse(res.data.address))
            var msgdata = (res.data.orderstatusdata != "" && res.data.orderstatusdata != null) ? JSON.parse(res.data.orderstatusdata) : ""
            // setMessageData(msgdata[res.data.orderstatus]['msg'])
            fethchTrack()
        }).catch(error =>{
            setErrMsg(true)
            setLoading(false)
            setError(true)
        })
    }
    const fethchTrack = () =>{
        axios.post(apiurl + "api/trackmyorder",null,{
            params: {
                invid : invid
            }
        })
        .then(res =>{
            setOrdTrack(res.data)
            console.log(res.data)
            setLoading(false)
        }).catch(error =>{
            setErrMsg(true)
            setLoading(false)
            setError(true)
        })
    }
    if(loading) {
        return(
            <div className='container mainloading'>
                <Loading />
            </div>
        )
    }
    else if(error){
        return(
            <ErrorPg />
        )
    }else
  return (
    <React.Fragment>
        <Menu />
            <section className='orderdetails dashpage'>
                <div className='my100 maxwidth'>
                    <div className='w90 float-center'>
                        <div className='w30 float-left prodside'>
                            <div className='w90 float-center prodbox'>
                                <div className='w90 float-center'>
                                    <div className='container prodimgord'>
                                        <div className='w90 float-center ordimg'>
                                            <img src={(product.image != undefined && product.image != null && product.image != "") ? product.image : logo} alt={product.name} />
                                        </div>
                                    </div>
                                    <div className='container orderlowdet'>
                                        <div className='container order-title'>
                                            <p>{product.name}</p>
                                        </div>
                                        <div className='container prodprice'>
                                            <p><span>₹</span>{product.famnt}</p>
                                        </div>
                                    </div>
                                    <div className='container proddetord ordertopbar'>
                                        <div className='container ordid'>
                                            <p><span>Order ID:</span> {orderDet.ordid}</p>
                                        </div>
                                        <div className='container orddate'>
                                            <p><span>Order Date:</span> {moment.unix(orderDet.date).format("dddd, MMMM Do YYYY, h:mm:ss a")}</p>
                                        </div>
                                    </div>
                                    <div className='container orddetails invbutton'>
                                        <button>Download Invoice</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(orderDet != null && orderDet != "") &&
                        <div className='w40 float-left addresstrack'>
                            <div className='w98 float-center trackside'>
                                <div className='w95 float-center'>
                                    <div className='container tracksidebox'>
                                        {orderDet.orderstatus == 4 &&
                                        <div className='container cancelbox'>
                                            <div className='w90 float-center'>
                                                <div className='container'>
                                                    <div className='cancelimg'>
                                                        <img src={cancelledImg} alt="Order Cancelled" />
                                                    </div>
                                                </div>
                                                <div className='container float-center ordercancelledbox center-text'>
                                                    <p>This order was cancelled by {orderDet.cbyuser != null && " you"}</p>
                                                </div>
                                                <div className='container float-center orderreason'>
                                                    <div className='container statedreson center-text'>
                                                        <p>Stated Reason for Cancellation</p>
                                                    </div>
                                                    <div className='container resonbox'>
                                                        <div className='w90 float-center'>
                                                            <p>{orderDet.rejectreason}</p>
                                                        </div>
                                                    </div>
                                                </div>  
                                            </div>
                                        </div>
                                        }
                                        {orderDet.orderstatus > 4 &&
                                        <div className='container cancelbox returnbox'>
                                            <div className='w90 float-center'>
                                                <div className='container'>
                                                    <div className='cancelimg'>
                                                        <img src={cancelledImg} alt="Order Cancelled" />
                                                    </div>
                                                </div>
                                                <div className='container float-center ordercancelledbox center-text'>
                                                    <p>{(orderDet.orderstatus == 5) ? "Return Requested" : "Replacement Requested"}</p>
                                                </div>
                                                { messageData != "" &&
                                                <div className='container float-center orderreason'>
                                                    <div className='container statedreson center-text'>
                                                        <p>Stated Reason for {(orderDet.orderstatus == 5) ? "Return" : "Replacement"}</p>
                                                    </div>
                                                    <div className='container resonbox'>
                                                        <div className='w90 float-center'>
                                                            <p>{messageData}</p>
                                                        </div>
                                                    </div>
                                                </div>  
                                                }
                                            </div>
                                        </div>
                                        }
                                        {ordtrack.status.length > 0 &&
                                        <ul>
                                        {ordtrack.status.map((tracks,i) => (
                                            <li key={i}>
                                                {tracks.main == 1 &&
                                                <div className='container mbox'>
                                                    <div className='orhead'>
                                                        <p>{tracks.title}</p>
                                                    </div>
                                                </div>
                                                }
                                                {tracks.main == 0 &&
                                                <div className='container stracksubbox'>
                                                    <div className='container sbbox'>
                                                        <div className='orhead'>
                                                            <p>- {tracks.title}</p>
                                                        </div>
                                                        <div className='ordate'>
                                                            <p> {tracks.date}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                            </li>
                                            ))}
                                            {ordtrack.delivered == 0 &&
                                            <li className='graytrack'>
                                                <div className='container mbox'>
                                                    <div className='orhead'>
                                                        <p>Delivered</p>
                                                    </div>
                                                </div>
                                                <div className='container stracksubbox'>
                                                    <div className='container sbbox'>
                                                        <div className='orhead'>
                                                            <p>- your order is expected to be delivered soon</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            }
                                        </ul>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        <div className='w30 float-left addrside'>
                            <div className='container otherdetails'>
                                <div className='w90 float-center'>
                                    <div className='container float-left'>
                                        <div className='addressbox container float-left'>
                                            <div className='container ordid shiptitle'>
                                                <div className='w90 float-center'>
                                                    <p>Shipping Details</p>
                                                </div>
                                            </div>
                                            <div className='w90 float-center'>
                                                <div className='container address-name'>
                                                    <p>{address.name}</p>
                                                </div>
                                                <div className='container addressfull'>
                                                    <p className='address-p'>
                                                        {address.address}
                                                    </p>
                                                    <p className='address-city'>
                                                        {address.city}
                                                    </p>
                                                    <p className='address-state'>
                                                        {address.state} - {address.pincode}
                                                    </p>
                                                    <p className='address-email'>
                                                        {address.email}
                                                    </p>
                                                    <p className='address-num'>
                                                        {address.contact}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='container float-left'>
                                        <div className='container float-center price-detailsbox'>
                                            <div className='container pdetailshead'>
                                                <div className='w90 float-center'>
                                                    <p>PRICE DETAILS</p>
                                                </div>
                                            </div>
                                            <div className='container pricebif'>
                                                <div className='w90 float-center'>
                                                    <ul>
                                                        <li>
                                                            <div className='m-text'>
                                                                <p>Price </p>
                                                            </div>
                                                            <div className='p-text'>
                                                                <p><span>₹</span>{product.price}</p>
                                                            </div>
                                                        </li>
                                                        <li className='disc'>
                                                            <div className='m-text'>
                                                                <p>Discount</p>
                                                            </div>
                                                            <div className='p-text'>
                                                                <p>-<span>₹</span>{product.discount}</p>
                                                            </div>
                                                        </li>
                                                        <li className='f-amt'>
                                                            <div className='m-text'>
                                                                <p>Total Amount</p>
                                                            </div>
                                                            <div className='p-text'>
                                                                <p><span>₹</span>{product.famnt}</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <Footer white="true"/>
    </React.Fragment>
  )
}
