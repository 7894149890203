import React from 'react'

export default function ProductCard(props) {
    function naming(name,slug){
        var newSlug
        if(slug == "" || slug == null){
            newSlug = name.replace(/ /g, "-")
            newSlug = newSlug.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
        }else{
            newSlug = slug.replace(/ /g, "-")
            newSlug = newSlug.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
        }
        return newSlug.toLowerCase()
    }
  return (
    <div className='container cardmainbox overflow-visible'>
        <a href={"/product/"+ naming(props.data.data.pname,props.data.price.slug) + "?p_id=" +props.data.price.p_id + "&m_id=" + props.data.price.m_id + "&b_id=" + props.data.price.b_id} title={props.data.data.pname} target="_blank" >
            <div className={`w90 float-center productcardmain ${((props.data.price.quant < 1)) ? 'outstockcard' : ''}`}>
                <div className='container productimagebx'>
                    {(props.data.price.img1 != null && props.data.price.img1 != "") ?
                    <div className='productcardimg'>
                        <img src={asseturl + props.data.price.img1} alt={(props.data.price.alt1 != null && props.data.price.alt1 != "") ? props.data.price.alt1 : props.data.data.pname} referrerPolicy='no-referrer'/>
                    </div>
                    :
                    <div className='productcardimg prodnoimage'>
                        <img src={logo} alt='' />
                    </div>
                    }
                </div>
                <div className='container productcarddet'>
                    <div className='w95 float-center'>
                        <div className='container prodcardname'>
                            <p>{props.data.data.pname} 
                            {/* ({(props.data.modelname != undefined && props.data.modelname != null && props.data.modelname != "") && props.data.modelname} {(props.data.brandname != undefined && props.data.brandname != null && props.data.brandname != "") && props.data.brandname}) */}
                            </p>
                        </div>
                        <div className='container prodcardsubtxt'>
                            <p>{props.data.data.short_description}</p>
                        </div>
                        {(props.data.price.quant < 1) ? 
                        <div className='container instock outofstock'>
                            <p><i className='fas fa-ban'></i> Out of Stock</p>
                        </div>
                        :
                        <div className='container instock'>
                            <p><i className='fas fa-check'></i> In Stock</p>
                        </div>
                        }
                        <div className='container cardprices'>
                            <div className='mainp'>
                                <p><span>₹</span>{props.data.price.famnt}</p>
                            </div>
                            <div className='oldp'>
                                <p><span>₹</span>{props.data.price.price}</p>
                            </div>
                            <div className='cardoff'>
                                <p>{props.data.price.off}% OFF</p>
                            </div>
                            {(props.data.new != undefined && props.data.new) &&
                            <div className='cardnewtag'>
                                <p>NEW</p>
                            </div>
                            }
                        </div>
                        <div className='container cartbuttoncard'>
                            <button>Add To Cart</button>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </div>
  )
}
