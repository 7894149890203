import React, { useEffect, useState } from 'react'
import Menu from '../MenuBar'
import Footer from '../Footer'
import Loading from '../Loading'
import ErrorPg from '../Error'
import { Link, useLocation, useParams } from 'react-router-dom'
import axios from 'axios'


export default function CategoryStep2() {
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [errMsg,setErrMsg] = useState("")
    const [subCats,setSubCats] = useState("")
    const [mainCats,setMainCats] = useState("")
    const [mCatDetails,setMcatDetails] = useState("")
    const [subCatLoading,setSubCatLoading] = useState(true)
    const [topCatName,setTopCatName] = useState("")
    const useQuery = () =>{
        return new URLSearchParams(useLocation().search)
    }
    const params = useParams()
    const pID = params.pid
    const catID = params.id
    const sideID = params.sid
    var a = 0

    useEffect(() =>{
        if(a == 0){
            fetchSideCategories(pID)
        }
        a++
    },[])
    const fetchCategories = (id) =>{
        setSubCatLoading(true)
        axios.get(apiurl + "api/caregories/"+id,null,null)
        .then((res) =>{
            setSubCats(res.data)
            setSubCatLoading(false)
        },(error) =>{
            console.log(error.message)
        })
    }
    const fetchSideCategories = (id) =>{
        setSubCatLoading(true)
        axios.get(apiurl + "api/caregoriesWithSub/"+id,null,null)
        .then((res) =>{
            var a = res.data.filter(x => x.id == sideID)
            if(a[0].category2.length > 0){
                var temp = a[0].category2.filter(x => x.id == catID)
                setMcatDetails(temp[0].name)
            }
            setMainCats(a)
            setTopCatName(a[0].name)
            fetchCategories(catID)
            setLoading(false)
        },(error) =>{
            console.log(error.message)
        })
    }
    if(loading) {
        return(
            <div className='container mainloading'>
                <Loading />
            </div>
        )
    }
    else if(error){
        return(
            <ErrorPg />
        )
    }else
  return (
    <React.Fragment>
        <Menu />
            <section className='categorypage'>
                <div className='my100 maxwidth'>
                    <div className='my90 float-center catgpgflex'>
                        <div className='my30 float-left maincatside'>
                            {(mainCats != null && mainCats != "") &&
                            <div className='w90 float-center maincatslist'>
                                <div className='w90 float-center'>
                                    <div className='container catlisttitle'>
                                        <p>{(topCatName != null && topCatName !="" ) ? topCatName : "Categories"}</p>
                                    </div>
                                    <div className='container catslistpg'>
                                        <ul>
                                            {mainCats.map((cat ,j ) =>(
                                                (cat.category2.length > 0) &&
                                                <div key={j}>
                                                {cat.category2.map((mcat ,i) =>(
                                                <Link to={(mcat.next == 0) ? "/c/"+mcat.id+"/"+mcat.slug+"/products" : "/c/"+pID+"/"+cat.id+"/"+mcat.id+"/"+mcat.slug} title={mcat.name} key={i} onClick={() => {fetchCategories(mcat.id),setMcatDetails(mcat.name)}}>
                                                    <li className={(catID == mcat.id) ? "actlcat" : ""}>
                                                        {mcat.name}
                                                    </li>
                                                </Link>
                                                ))}
                                                </div>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        <div className='w70 float-left subcatsside'>
                            <div className='w90 float-center subcatslist'>
                                <div className='container catlisttitle catslside'>
                                    <div className='w98 float-center'>
                                        <p>Available Categories {(mCatDetails != "") && "in "+mCatDetails}</p>
                                    </div>
                                </div>
                                <div className='container subcatlists'>
                                    {subCatLoading ? 
                                    <div className='container subcatloadingpg'>
                                        <Loading />
                                    </div>
                                    :
                                    (subCats != null && subCats != "") &&
                                    <>
                                    {subCats.map((subcat,i) =>(
                                        (subcat.category2 != undefined && subcat.category2.length > 0) ?
                                    <div className='container subcatmainbox' key={i}>
                                        <div className='container subcattitle'>
                                            <p>{subcat.name}</p>
                                        </div>
                                        <div className='container subcatsmlist'>
                                            {subcat.category2.map((cat2, i) =>(
                                                <div className='w20 float-left subcatbx' key={i}>
                                                    <a href={(cat2.next == 0) ? "/c/"+cat2.id+"/"+cat2.slug+"/products" : "/c/"+sideID+"/"+catID+"/"+cat2.id+"/"+cat2.slug} title={cat2.name}>
                                                        <div className='w90 float-center dropsubcat center-text'>
                                                            <div className='container dropsubsimg'>
                                                                {(cat2.img != null) ? 
                                                                <div className='dropssubimg'>
                                                                    <img src={asseturl + cat2.img} alt={cat2.name} referrerPolicy='no-referrer'/>
                                                                </div>
                                                                :
                                                                <div className='dropssubimg dropsunotav'>
                                                                    <img src={logo} alt={cat2.name} referrerPolicy='no-referrer'/>
                                                                </div>
                                                                }
                                                            </div>
                                                            <div className='container dropsubname'>
                                                                <div className='w95 float-center'>
                                                                    <p>{cat2.name}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    :
                                    <div className='w20 float-left subcatbx' key={i}>
                                        <a href={(subcat.next == 0) ? "/c/"+subcat.id+"/"+subcat.slug+"/products" : "/c/"+sideID+"/"+catID+"/"+subcat.id+"/"+subcat.slug} title={subcat.name}>
                                            <div className='w90 float-center dropsubcat center-text'>
                                                <div className='container dropsubsimg'>
                                                    {(subcat.img != null) ? 
                                                    <div className='dropssubimg'>
                                                        <img src={asseturl + subcat.img} alt={subcat.name} referrerPolicy='no-referrer'/>
                                                    </div>
                                                    :
                                                    <div className='dropssubimg dropsunotav'>
                                                        <img src={logo} alt={subcat.name} referrerPolicy='no-referrer'/>
                                                    </div>
                                                    }
                                                </div>
                                                <div className='container dropsubname'>
                                                    <div className='w95 float-center'>
                                                        <p>{subcat.name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    ))}
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <Footer />
    </React.Fragment>
  )
}