import React from 'react'
import Menu from '../MenuBar'
import Footer from '../Footer'
import Sidebar from './Sidebar'
import Loading from '../Loading';
import ErrorPg from '../Error';
import { useState } from 'react';
import { useCookies,Cookies } from 'react-cookie';
import { useEffect } from 'react';
import axios from 'axios';

export default function Orders() {
    var a = 0;
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [errMsg,setErrMsg] = useState("")
    const [orderList,setOrderList] = useState("")
    const [cookies,setCookie,removeCookie] = useCookies('')
    useEffect(() =>{
        if(a == 0){
            fetchUser()
        }
        a++
    },[])
    const fetchUser = () =>{
        if(cookies.user != undefined && cookies.user != null && cookies.user != ""){
            if(cookies.user.emailverified == "" || cookies.user.emailverified == null){
                window.location.href = "/verify-otp"
            }
            else{
                fetchOrder(cookies.user.myToken)
            }
        }
        else{
            window.location.href = "/"
        }
    }
    const fetchOrder = (token) =>{
        axios.post(apiurl + "api/ordersList",null,{
            params : {
                authToken : token
            }
        })
        .then((res) =>{
            console.log(res.data)
            setOrderList(res.data.reverse())
            setLoading(false)
        },(error) =>{
            setErrMsg(true)
            setLoading(false)
            setError(true)
        })
    }
    if(loading) {
        return(
            <div className='container mainloading'>
                <Loading />
            </div>
        )
    }
    else if(error){
        return(
            <ErrorPg />
        )
    }else
  return (
    <React.Fragment>
        <Menu />
            <section className='myorders dashpage'>
                <div className='my100 maxwidth'>
                    <div className='my90 float-center dahflx'>
                        <div className='my30 float-left sidebarsidem'>
                            <Sidebar act="2"/>
                        </div>
                        <div className='w70 float-left'>
                            <div className='container float-center orderslistside'>
                                <div className='container dashtitle center-text'>
                                    <p>My Orders</p>
                                </div>
                                <div className='container orderslist'>
                                    {(orderList != null && orderList != "") ?
                                    orderList.map((ord,i) =>{
                                        var prod = JSON.parse(ord.product)
                                        return(
                                        <div className='orderbx container' key={i}>
                                            <div className='ordercard w95 float-center'>
                                                <div className='w98 float-center'>
                                                    <div className='ordertopbar container'>
                                                        <div className='ordid'>
                                                            <p><span>Order ID : </span>{ord.ordid}</p>
                                                        </div>
                                                        <div className='orddate'>
                                                            <p><span>Order Date : </span>{ord.dateFormat}</p>
                                                        </div>
                                                    </div>
                                                    <div className='container orderprodbox'>
                                                        <div className='w30 float-left'>
                                                            <div className='w90 float-center ordimg'>
                                                                <img src={(prod.image != undefined && prod.image != null && prod.image != "") ? prod.image : logo} alt={prod.name} />
                                                            </div>
                                                        </div>
                                                        <div className='w70 float-left'>
                                                            <div className='container orderdetside'>
                                                                <div className='container order-title'>
                                                                    <p>{prod.name}</p>
                                                                </div>
                                                                <div className='container prodprice'>
                                                                    <p><span>₹</span>{prod.famnt}</p>
                                                                </div>
                                                                {(ord.orderstatus != null && ord.orderstatus != "") &&
                                                                <>
                                                                    {ord.orderstatus < 4 &&
                                                                    <div className='container orddetails'>
                                                                        <a href={'/orders-details/'+ord.invid} title='View Order Details'>
                                                                            <button>View Order Details</button>
                                                                        </a>
                                                                    </div>
                                                                    }
                                                                    {ord.orderstatus < 4 &&
                                                                    <div className='container orddetails'>
                                                                        <a href={'/orders-details/'+ord.invid} title='View Order Details'>
                                                                            <button>View Order Details</button>
                                                                        </a>
                                                                    </div>
                                                                    }
                                                                </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )})
                                    :
                                    <div className='container noprodssearch'>
                                        <div className='w90 float-center'>
                                            <div className='container nosearchimg'>
                                                <img src={noDataImg} alt='Nothing found' />
                                            </div>
                                            <div className='container notfoundtxt center-text'>
                                                <p>No Orders Present</p>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <Footer white="true"/>
    </React.Fragment>
  )
}
