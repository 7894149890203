import React,{useEffect,useState} from 'react'
import Menu from './MenuBar'
import Footer from './Footer'
import { useParams } from 'react-router-dom'
import Loading from './Loading'
import ErrorPg from './Error'
import axios from 'axios'

export default function Terms() {
    const [terms,setTerms] = useState("")
    var a = 0;
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [errMsg,setErrMsg] = useState("")
    let slugId= useParams()
    useEffect(() =>{
        if(a == 0){
            fetchTerms(slugId.slug)
        }
        a++
    },[])
    const fetchTerms = (x) =>{
        axios.post(apiurl + "api/usefullinks",null,{
            params : {
                id : x
            }
        })
        .then(res =>{
            setTerms(res.data)
            setLoading(false) 
        })
        .catch(error =>{
            setError(error)
            setLoading(false)
        })
    }
    if(loading) {
        return(
            <div className='container mainloading'>
                <Loading />
            </div>
        )
    }
    else if(error){
        return(
            <ErrorPg />
        )
    }else
  return (
    <React.Fragment>
        <Menu />
        {(terms != null && terms != "") &&
        <section className='termsandcondtions'>
            <div className='container maxwidth'>
                <div className='w90 float-center'>
                    {terms.map((term,i) =>(
                        <div key={i}>
                            <div className='container maintheading center-text'>
                                <p>{term.name}</p>
                            </div>
                            <div className='container ck-content' dangerouslySetInnerHTML={{__html : term.html}}>

                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
        }
        <Footer />
    </React.Fragment>
  )
}
