import React, { useEffect, useState } from 'react'
import EmptyCart from './images/emptycart.svg';
import {FaStar,FaDownload,FaTrash,FaHeart} from 'react-icons/fa'
import {BsFillBookmarkCheckFill} from 'react-icons/bs'
import { Cookies, useCookies } from 'react-cookie';
import { Link,useNavigate } from 'react-router-dom';
import {FiAlertTriangle, FiX} from 'react-icons/fi'
import LoadingIndicator from "react-loading-indicator";
import axios from 'axios';
import Menu from '../MenuBar';
import Footer from '../Footer';
import ErrorPg from '../Error';

export default function Cart() {
    const [displays,setDisplays] = useState(false)
    const [dels,setDels] = useState("")
    const [logPop,setLogPop] = useState(false)
    const [proceed,setProceed] = useState(false)
    const [quantPop,setQuantPop] = useState(false)
    const [error,setError] = useState("")
    const navigate = useNavigate()
    const [cartItems,setCartItems] = useState([])
    const [isLoading,setIsLoading] = useState(true)
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [quantityCheck,setQuantityCheck] = useState(false)
    const fetchItem = apiurl + "api/cartinfo"
    let cItem = cookies.cartItem
    let checkLog;
    useEffect(() =>{
        setCookie('callback',('/cart'),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
        
        let checkLog = cookies.user
        if(checkLog != "" && checkLog != null){
            fetchCart(checkLog.id,cItem)
        }else{
            fetchCart("",cItem)
        }
    },[])
    
    const fetchCart = (id,cart) =>{
        axios.post(fetchItem,null,{
            params : {
                cart : JSON.stringify(cart),
                uid : id
            }
        }).then(
            (response) => {
                setCartItems(response.data)
                setIsLoading(false)
                let x = 0
                if(response.data !== "" && response.data !== null) {
                    for(var i= 0;i<response.data.products.length;i++){
                        if(response.data.products[i]["maxquant"] < response.data.products[i]["quantity"]){
                            x++
                        }
                    }
                }
                if(x > 0){
                    setQuantityCheck(true)
                }
            },
            (error) =>{
                setError(error)
            }
        )
    }
    const handleRemove = (i) =>{
        setDels(i)
        setLogPop(false)
        setDisplays(true)
    }
    const increaseQuant = (i,func) =>{
        let cartQuant = cookies.cartItem
        let count = cartQuant[i]["quantity"]
        let MaxQuant = cartQuant[i]["maxquant"] 
        let CountCheck = 0
        if(func == 1) {
            CountCheck = 1
            if(count != MaxQuant){
                count = count + 1
            }
        }else{
            CountCheck = 0
            if(count != 1){
                count = count - 1
            }
        }
        cartQuant[i]["quantity"] = count
        setCookie('cartItem',JSON.stringify(cartQuant),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
        if(checkLog != "" && checkLog != null){
            fetchCart(checkLog.id,cartQuant)
        }else{
            fetchCart("",cartQuant)
        }
    }
    const removeItem = (i) =>{
        let cart = cookies.cartItem
        let newcart = cart.filter(function(value, index){
            return index != i
        })
        setCookie('cartItem',JSON.stringify(newcart),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
        window.location.reload()
    }
    const removeOutStock = (i) =>{
        let cart = cartItems
        
        let cartArray = []
        for(var i= 0;i<cart.products.length;i++){
            if(cart.products[i]["maxquant"] > cart.products[i]["quantity"]){
                let cartIds ={
                    pid:cart.products[i]["pid"] ,
                    bid:cart.products[i]["bid"] ,
                    mid:cart.products[i]["mid"] ,
                    quantity:cart.products[i]["quantity"]
                }
                cartArray.push(cartIds)
            }
        }
        setCookie('cartItem',JSON.stringify(cartArray),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
        window.location.reload()
    }
    const placingOrder = () =>{
        let checkLog = cookies.user
        if(quantityCheck == true){
            setDisplays(true)
            setQuantPop(true)
        }
        else if((checkLog != null) && (checkLog != "")) {
          window.location.href = "/address"
        }
        else{
            setDisplays(true)
            setLogPop(true)
            setQuantPop(false)
        }
    }
    function naming(name,slug){
        var newSlug
        if(slug == "" || slug == null){
            newSlug = name.replace(/ /g, "-")
            newSlug = newSlug.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
        }else{
            newSlug = slug.replace(/ /g, "-")
            newSlug = newSlug.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
        }
        return newSlug.toLowerCase()
    }
    
  const wishlist = (pid,bid,mid) =>{
      let checkLog = cookies.user
      if(checkLog != null && checkLog != ""){
        let id = checkLog.id
        axios.post(apiurl + "api/addwishlist",null,{
          params :{
            uid : id,
            pid : pid,
            bid : bid,
            mid : mid
          }
        })
        .then(res =>{
            if(checkLog != "" && checkLog != null){
                fetchCart(checkLog.id)
            }else{
                fetchCart("")
            }
        })
        .catch(error =>{
            setError(error)
        })
      }else{
        setDisplays(true)
        setLogPop(true)
        setQuantPop(false)
      }
  }
  
    if(error) {
        return(
            <ErrorPg />
        )
    }else if(isLoading){
        return(
            <div className='loadingbox'>
                <div className="loadercircle container"><LoadingIndicator segmentWidth={4} segmentLength={9}/> </div> 
            </div>
        )
    }else 
  return (
    <React.Fragment>
        <Menu />
        <section className='cart'>
            <div className='my100 maxwidth'>
                <div className='my90 float-center cartnty'>
                    {(cartItems != "") ? (
                    <div className='my100 cartpgflx'>
                        <div className='w70 float-left cart-items'>
                            <div className='container cart-heading'>
                                <div className='w90 float-center'>
                                    <p>My Cart ({cartItems.products.length})</p>
                                </div>
                            </div>
                            { cartItems.products.map((item , i) =>(
                                <div className='container cart-itemslist' key={i}>
                                    <div className='cart-item container'>
                                        <div className='w90 float-center'>
                                            <div className='container cart-item-details'>
                                                <div className='w30 float-left'>
                                                    <div className="cart-itemimg">
                                                        <Link to={"/product/"+ naming(item.name,item.slug) +
                                                        "?p_id=" + item.pid + 
                                                        "&m_id=" + item.mid + 
                                                        "&b_id=" + item.bid} 
                                                        title={item.name} >
                                                        <img src={item.image} alt={item.name} referrerPolicy="no-referrer" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className='w70 float-left'>
                                                    <div className="w90 float-center">
                                                        <Link to={"/product/"+ naming(item.name,item.slug) +
                                                        "?p_id=" + item.pid + 
                                                        "&m_id=" + item.mid + 
                                                        "&b_id=" + item.bid} 
                                                        title={item.name} >
                                                            <div className="container prod-name">
                                                                <p>{item.name}</p>
                                                            </div>
                                                        </Link>
                                                        <div className="container cart-prod-price">
                                                            <div className="container pricebox cardprices">
                                                                <div className="finalamt mainp">
                                                                    <p><span>₹</span>{item.famnt}</p>
                                                                </div>
                                                                <div className="main-price oldp">
                                                                    <p><span>₹</span>{item.price}</p>
                                                                </div>
                                                                <div className="percent-off cardoff">
                                                                    <p>{item.off}% off</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='container quantity-set'>
                                                            <div className='quantity-head container'>
                                                                <p>Quantity</p>
                                                            </div>
                                                            {(item.maxquant > 0) ? 
                                                            (<div className='container quantity-box'>
                                                                <div className={`minus-q quants ${(item.quantity > 1) ? "" : "disabled"}`}  onClick={() => (item.quantity > 1) ? increaseQuant(i,0) : null} > - </div>
                                                                    <input type="text" name={item.maxquant} value={item.quantity} readOnly/>
                                                                <div className={`plus-q quants ${(item.quantity >= item.maxquant) ? "disabled" : ""}`}  onClick={() =>(item.quantity < item.maxquant) ? increaseQuant(i,1) : null}> + </div>
                                                            </div>) : (
                                                                <div className='out-ofstock-box container'>
                                                                    <p>This Product is currently out of stock.</p>
                                                                </div>
                                                            )
                                                            }
                                                        </div>
                                                        <div className='save-later container'>
                                                            <div className='container float-left'>
                                                                <div className='container float-left sbuts'>
                                                                    {item.wishlist.length > 0 ?
                                                                    <div className="float-left savebuts saved" hidden>
                                                                        <p onClick={() => wishlist(item.pid,item.bid,item.mid)}> <FaHeart/> Remove from Wishlist</p>
                                                                    </div>
                                                                    :
                                                                    <div className="float-left savebuts" hidden>
                                                                        <p onClick={() => wishlist(item.pid,item.bid,item.mid)}> <FaHeart/> Add to Wishlist</p>
                                                                    </div>
                                                                    }
                                                                    <div className=' float-right removebuts'>
                                                                        <p onClick={() => handleRemove(i)}><FaTrash/> Remove</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='my30 float-left'>
                            <div className='w90 float-center price-detailsbox'>
                                <div className='container pdetailshead'>
                                    <div className='w90 float-center'>
                                        <p>PRICE DETAILS</p>
                                    </div>
                                </div>
                                <div className='container pricebif'>
                                    <div className='w90 float-center'>
                                        <ul>
                                            <li>
                                                <div className='m-text'>
                                                    <p>Price ({cartItems.products.length} Items)</p>
                                                </div>
                                                <div className='p-text'>
                                                    <p><span>₹</span>{cartItems.price}</p>
                                                </div>
                                            </li>
                                            <li className='disc'>
                                                <div className='m-text'>
                                                    <p>Discount</p>
                                                </div>
                                                <div className='p-text'>
                                                    <p>- <span>₹</span>{cartItems.discount}</p>
                                                </div>
                                            </li>
                                            <li className='f-amt'>
                                                <div className='m-text'>
                                                    <p>Total Amount</p>
                                                </div>
                                                <div className='p-text'>
                                                    <p><span>₹</span>{cartItems.famnt}</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='container cart-heading tobelowhead placebut'>
                                    <div className='w90 float-center right-text placebutton'>
                                        <button className='buybutton' onClick={placingOrder}>Place Order</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>) :(
                        <div className='container empty-cart center-text'>
                            <div className='w30 float-center empty-img'>
                                <div className="cart-itemimg">
                                    <img src={EmptyCart} alt="Empty Cart" />
                                </div>
                                <div className='container empty-message'>
                                    <p className='m-paracart'>Your cart is empty!</p>
                                    <p className='s-paracart'>Add Items to The Cart</p>
                                </div>
                                <div className='container start-shopbutton'>
                                    <button className="check-drop" onClick={() => navigate("/")}>Start Shopping</button>
                                </div>
                            </div>
                        </div>
                    )
                    }
                </div>
            </div>
        </section>
        <Footer />
        <div className='delete-pop container' style={{display : (displays) ? "block" : "none"}}>
            <div className='container del-pop'>
                <div className='popmainbox center-text'>
                    <div className='w90 float-center'>
                        {(logPop) ? (
                            <>
                            <div className='w90 float-center'>
                                <div className='container center-text popheadbox'>
                                    <div className='popheading container'>
                                        <p>please login to continue</p>
                                    </div>
                                    <div className='popsubtext container'>
                                        <p>Lorem ipsum is a placeholder text commonly used to demonstrate the visual form</p>
                                    </div>
                                    <div className='poplogbuttons container'>
                                        <div className='poploginbutton container float-left'>
                                            <div className='w80 float-center'>
                                                <Link to='/user-login' title=''>Log In</Link>
                                            </div>
                                        </div>
                                        <div className='popor  container float-left'>
                                            <p>OR</p>
                                        </div>
                                        <div className=' container float-left popsignup'>
                                            <Link to='/signup' title=''>Create Account</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='closepop' onClick={() => setDisplays(false)}>
                                <FiX />
                            </div>
                        </>
                        ) : 
                        (
                            <>
                            {(quantPop) ? 
                            <>
                            <div className='icontext container'>
                                <p>Can't place orders because 1 or more products are out of stock</p>
                            </div>
                            <div className='container delbutts'>
                                <div className='w50 float-center'>
                                    <div className='w90 float-center'>
                                        <button className='delbutton' onClick={removeOutStock}>Remove Product</button>
                                    </div>
                                </div>
                            </div>
                            </>
                            :
                            <>
                            <div className='icontext container'>
                                <p>Are you sure you want to remove this product?</p>
                            </div>
                            <div className='container delbutts'>
                                <div className='w50 float-left'>
                                    <div className='w90 float-center'>
                                        <button className='cancelbutton' onClick={() => setDisplays(false)}>No, Cancel</button>
                                    </div>
                                </div>
                                <div className='w50 float-left'>
                                    <div className='w90 float-center'>
                                        <button className='delbutton' onClick={() => removeItem(dels)}>Yes, Remove</button>
                                    </div>
                                </div>
                            </div>
                            </>
                            }
                        </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}

