import React, { useEffect, useState } from 'react';
import {Route,Routes,Navigate, useNavigate } from 'react-router-dom';
import Homepage from './index/Homepage';
import ScrollToTop from './scrollTop';
import ProductPage from './product/ProductPage';
import CategoryStep1 from './category/CategoryStep1';
import CategoryStep2 from './category/CategoryStep2';
import CategoryFinal from './category/CategoryFinal';
import UserLogin from './auth/Login';
import SignUp from './auth/Signup';
import ForgotPass from './auth/ForgotPass';
import OTPverify from './auth/otpverify';
import Cart from './cart/Cart';
import Address from './cart/Address';
import Checkout from './cart/Checkout';
import Success from './cart/Success';
import Orders from './account/Orders';
import OrderDetails from './account/OrderDetails';
import Wishlist from './account/Wishlist';
import Account from './account/Account';
import AddressAcc from './account/AddressAcc';
import ModelsPage from './brands/ModelsPage';
import ModelsFinal from './brands/ModelsFinal';
import AllBrands from './brands/AllBrands';
import Terms from './Terms';
import Dashboard from './account/Dashboard';

function App() {
  return(
    <React.Fragment>
        <ScrollToTop />
        <Routes>
            <Route exact path='/' element={<Homepage />} />
            <Route exact path='/product/:slug' element={<ProductPage />} />
            <Route exact path='/c/:id/:slug' element={<CategoryStep1 />} />
            <Route exact path='/c/:pid/:sid/:id/:slug' element={<CategoryStep2 />} />
            <Route exact path='/c/:id/:slug/products' element={<CategoryFinal />} />
            <Route exact path='/user-login' element={<UserLogin />} />
            <Route exact path='/signup' element={<SignUp />} />
            <Route exact path='/forgot-password' element={<ForgotPass />} />
            <Route exact path='/verify-otp' element = {<OTPverify />} />
            <Route exact path='/cart' element = {<Cart />} />
            <Route exact path='/address' element = {<Address />} />
            <Route exact path='/checkout' element = {<Checkout />} />
            <Route exact path='/success' element = {<Success />} />
            <Route exact path='/orders' element = {<Orders />} />
            <Route exact path='/wishlist' element = {<Wishlist />} />
            <Route exact path='/my-account' element = {<Account />} />
            <Route exact path='/orders-details/:id' element = {<OrderDetails />} />
            <Route exact path='/manage-address' element = {<AddressAcc />} />
            <Route exact path='/brand/:id' element = {<ModelsPage />} />
            <Route exact path='/brand/:bid/model/:mid' element = {<ModelsFinal />} />
            <Route exact path='/allbrand' element = {<AllBrands />} />
            <Route exact path='/policies/:slug' element={<Terms />} />
            <Route exact path='/dashboard' element={<Dashboard />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </React.Fragment>
  )
}

export default App;
