import React, { useEffect, useState } from 'react'
import ProductCard from '../product/ProductCard';
import axios from 'axios';
import Loading from '../Loading';
import Menu from '../MenuBar';
import Footer from '../Footer';
import ErrorPg from '../Error';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

export default function CategoryFinal() {
    var a = 0;
    const navigate = useNavigate()
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [errMsg,setErrMsg] = useState("")
    const [searchParams,setSearchParams] = useSearchParams(window.location.search)
    const [pageNumber,setPageNumber] = useState(0);
    const [prodLoading,setProdLoading] = useState(true)
    const [filterList,setFilterList] = useState("")
    const [products,setProducts] = useState("")
    const [categoryBasic,setCategoryBasic] = useState("")
    const [searchQuery,setSearchQuery] = useState("")
    const [mobShowen,setMobShowen] = useState(false)
    const [showSort,setShowSort] = useState(true)

    const [sort, setSort] = useState([{
        "col" : "viewed",
        "order" : "desc",
    }]);
    const params = useParams()
    var catID = params.id
    const [filter, setFilter] = useState([
        {
            "basic" : {
                "c_id":[catID],
            }
        },
        {
            "price" : ""
        },
        {
            "pdata" : "",
        },
        {
            "sort" : sort
        }
    ]);
    useEffect(() =>{
        if(a == 0){
            getFilters()
            if(screen.width > 900){
                setMobShowen(true)
                setShowSort(true)
            }else{
                setMobShowen(false)
                setShowSort(false)
            }
        }
        a++
    },[])
    const getFilters = () =>{
        axios.get(apiurl + "api/filterList?cid=" + catID)
        .then(res =>{
            console.log(res.data)
            setFilterList(res.data)
            getFilterValue(res.data)
            getBasic()
        })
        .catch(error =>{
            setError(error)
        })
    }
    const getBasic = () =>{
        axios.get(apiurl + "api/caregory/"+catID)
        .then(res =>{
            console.log("basic",res.data)
            setCategoryBasic(res.data)
        })
        .catch(error =>{
            setError(error)
        })
    }
    const getFilterValue = (x) =>{
        let temp = x
        for(var i = 0;i<temp.length;i++){
            const range = searchParams.getAll(temp[i]["colname"] + "[]")
            if(range != null){
                for(var j= 0; j <temp[i]["values"].length;j++){
                    if(range.indexOf(temp[i]["values"][j]["value"]) != -1){
                        temp[i]["values"][j]["isSelected"] = true
                    }
                }
            }
        }
        setFilterList(temp)
        createArray(temp)
    }

    const createArray = (x) =>{
        let dataArray = [];
        let priceArray = [];
        x.map((item,index) =>{
            let obj = []
            if(index != 0){
                item.values.map((item2,index2) =>{
                    if(item2.isSelected){
                        obj.push(item2.value)
                    }
                })
                if(obj.length > 0){
                    if(typeof dataArray[item.colname] == "undefined"){
                        let vals = {}
                        vals[item.colname] = obj
                        dataArray.push(vals)
                    }else{
                        dataArray[item.colname] = obj
                    }
                }
            }else{
                item.values.map((item2,index2) =>{
                    if(item2.isSelected){
                        if(typeof priceArray[0] == "undefined"){
                            priceArray.push(item2.from)
                        }else{
                            priceArray[0] = (item2.from < priceArray[0] ? item2.from : priceArray[0])
                        }
                        if(typeof priceArray[1] == "undefined"){
                            priceArray.push(item2.to)
                        }else{
                            priceArray[1] = (item2.to > priceArray[1] ? item2.to : priceArray[1])
                        }
                    }
                })
            }
        })
        let tempfilter = filter
        tempfilter[2]['pdata'] = dataArray
        tempfilter[1]['price'] = priceArray
        setPageNumber(0)
        getProducts(tempfilter, )
    }

    const getProducts = (filter,page) =>{
        var mpage = page
        axios({
            method : "POST",
            url :apiurl + "api/filterproducts",
            params : {filters : JSON.stringify(filter),page:mpage}
            })
        .then(
            (response) =>{
                    console.log(response.data)
                    setProducts(response.data)
                    setProdLoading(false)
                    setLoading(false)
                },
            (error) =>{
                console.log(error.message)
            }
        )
    }

    const [refresh,setRefresh] = useState("Yes")
    const changeFilter = (x,y) =>{
        let temp = filterList;
        setProdLoading(true)
        let isSel = temp[x]['values'][y]['isSelected']
        if(isSel == true){
            temp[x]['values'][y]['isSelected'] = false
        }else{
            temp[x]['values'][y]['isSelected'] = true
        }
        if(refresh == "Yes") setRefresh("No") 
        else setRefresh("yes")
        console.log("changed")
        filtered(temp)
    }
    
    const selectSort = (x,y) => {
        setProdLoading(true)
        let temp = filterList;
        for(var i=0;i<temp[x]['values'].length;i++){
            if(i == y){
                temp[x]['values'][i]['isSelected'] = true
                let newSort = [{
                    "col" : temp[x]['values'][i]['col'],
                    "order" : temp[x]['values'][i]['order'],
                }];

                setSort(newSort)
                let tempfilter = filter
                tempfilter[3]['sort'] = newSort
                setFilter(tempfilter)
            }else{
                temp[x]['values'][i]['isSelected'] = false
            }
        }
        createArray(temp)
        if(screen.width < 900){
            setShowSort(false)
        }
    }
    const filtered = (temp) =>{
        var string = ""
        for(var i = 0;i<temp.length;i++){
            const colname = temp[i]["colname"]
                for(var j= 0; j <temp[i]["values"].length;j++){
                    if(temp[i]["values"][j]["isSelected"] && (typeof colname != "undefined")){
                        const colvalue = temp[i]['values'][j]['value']
                        searchParams.append(colname,colvalue)
                        if(string != ""){
                            string += "&" + colname + "[]=" +colvalue 
                        }else{
                            string += colname + "[]=" +colvalue 
                        }
                    }
                }
        }
        navigate (window.location.pathname + "?" + string);
        createArray(temp)
    }
    const filterSlide = (i,dir) =>{
        var icon = $(".filttitle[data-slide="+i+"]").find(".filterSlide");
        if(icon.hasClass("showed")){
            window.$("#filt"+i).slideUp()
            icon.removeClass("showed")
        }else{
            window.$("#filt"+i).slideDown()
            icon.addClass("showed")
        }
    }
    
    const [noprods,setNoprods] = useState(false)
    const checkProducts = () =>{
        setTimeout(function(){
            var a = $('.prodcatcard').length
            if(a == 0){
                setNoprods(true)
            }else{
                setNoprods(false)
            }
        },100)
    }
    if(loading) {
        return(
            <div className='container mainloading'>
                <Loading />
            </div>
        )
    }
    else if(error){
        return(
            <ErrorPg />
        )
    }else
  return (
    <React.Fragment>
        <Menu />
            <section className='categoryprodpg'>
                <div className='my100 maxwidth'>
                    <div className='my90 float-center categorypgflex'>
                        {mobShowen &&
                        <div className='my30 float-left filterside'>
                            <div className='w90 float-center filterbox'>
                                <div className='container filtertitle'>
                                    <div className='w90 float-center'>
                                        <p>Filters</p>
                                    </div>
                                </div>
                                {(filterList != null && filterList != "") &&
                                <div className='container filterlists'>
                                    <form method='get' id='filters'>
                                        {filterList.map((fil,j) => (
                                            (j < (filterList.length - 1) && (fil.values.length > 0)) &&
                                            <div className='filtermainbx container' key={j}>
                                                <div className='w90 float-center filtntybx'>
                                                    <div className='container filttitle' data-slide={j}>
                                                        <p>{fil.heading}</p> <span className='filterSlide showed' onClick={() => filterSlide(j,"down")}><i className='fas fa-chevron-down'></i></span>
                                                    </div>
                                                    <div className='filtervals container' id={"filt"+j}>
                                                        {fil.values.map((vals,i) =>(
                                                            (vals.value != null && vals.value != "") &&
                                                            <div className={`container filtvbx ${vals.isSelected ? "selected" : ""}`} key={i}>
                                                                <input type='checkbox' checked = {(vals.isSelected) ? true : false}onChange={() => changeFilter(j,i)} id={"filtr"+i+j} name={fil.colname + "[]"} value={vals.value} />
                                                                <label htmlFor={"filtr"+i+j}>{vals.value}</label>
                                                            </div>
                                                        ))}
                                                    </div>  
                                                </div>
                                            </div>
                                        ))}
                                    </form>
                                </div>
                                }
                            </div>
                            {(screen.width < 900) &&
                            <div className='container applybuttons'>
                                <button onClick={() => setMobShowen(false)}>Apply Filter</button>
                            </div>
                            }
                        </div>
                        }
                        <div className='w70 float-left productsside'>
                            <div className='w98 float-center produccatbx'>
                                <div className='container prodsidetop'>
                                    <div className='w95 float-center'>
                                        <div className='container pagepath'>
                                            <ul>
                                                <li><a href='/' title=''>Home</a> / </li>
                                                {(categoryBasic != null && categoryBasic != "" ) &&
                                                    <li>{categoryBasic.name}</li>
                                                }
                                            </ul>
                                        </div>
                                        <div className='container categsearch'>
                                            <div className='catnameside'>
                                                {(categoryBasic != null && categoryBasic != "" ) &&
                                                    <p>{categoryBasic.name}</p>
                                                }
                                            </div>
                                            <div className='catsearchside'>
                                                <input type="text" placeholder='Search Product' name='search-manager' onChange={(e) => {setSearchQuery(e.target.value),checkProducts()}} />
                                                <i className='fas fa-magnifying-glass'></i>
                                            </div>
                                        </div>
                                        {showSort && <>
                                        {(filterList != null && filterList != "") &&
                                        <div className='container sortby'>
                                            {filterList.map((fil,j) => (
                                                ((j == (filterList.length - 1)) && (fil.values.length > 0)) &&
                                                <div className='topsortbx container' key={j}>
                                                        <div className='topflhead'>{fil.heading} :-</div>
                                                        {fil.values.map((vals,i) =>(
                                                            (vals.value != null && vals.value != "") &&
                                                            <div className={`filtvbx ${vals.isSelected ? "selected" : ""}`} key={i}>
                                                                <input type='checkbox' hidden checked = {(vals.isSelected) ? true : false} onChange={() => selectSort(filterList.length - 1,i)} id={"filtr"+i+j} name={fil.colname + "[]"} value={vals.value} />
                                                                <label htmlFor={"filtr"+i+j}>{vals.value}</label>
                                                            </div>
                                                        ))}
                                                </div>
                                            ))}
                                        </div>
                                        }
                                        </>}
                                    </div>
                                </div>
                                {prodLoading ?
                                <div className='container prodloadbig'>
                                    <Loading />
                                </div>
                                :(products != null && products != "" && products.data.length > 0) ?
                                    <div className='container productscatpg'>
                                        {products.data.filter(post =>{
                                            if(searchQuery == ""){
                                                return post
                                            }
                                            else if(post.data.pname.toLowerCase().includes(searchQuery.toLocaleLowerCase())){
                                                return post
                                            }
                                        }).map((prod,i) =>(
                                            <div className='w33 float-left prodcatcard' key={i}>
                                                <ProductCard data={prod} />
                                            </div>
                                        ))}
                                    </div>
                                    :
                                    <div className='container noprods'>
                                        <div className='w90 float-center'>
                                            <div className='container nosearchimg'>
                                                <img src={noDataImg} alt='Nothing found' />
                                            </div>
                                            <div className='container notfoundtxt center-text'>
                                                <p>Oops! Couldn't Find Any Products</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {noprods && 
                                <div className='container noprods'>
                                    <div className='w90 float-center'>
                                        <div className='container nosearchimg'>
                                            <img src={noDataImg} alt='Nothing found' />
                                        </div>
                                        <div className='container notfoundtxt center-text'>
                                            <p>Oops! Couldn't Find Any Products</p>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <Footer white="true"/>
        {(screen.width < 900) &&
        <div className='container bottomfix'>
            <div className='w50 float-left'>
                <button onClick={() => setMobShowen(true)}><i class="fa-solid fa-filter"></i> Filter</button>
            </div>
            <div className='w50 float-left'>
                <button onClick={() => setShowSort(true)}><i class="fa-solid fa-sort"></i>Sort</button>
            </div>
        </div>
        }
    </React.Fragment>
  )
}
