import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import Loading from '../Loading';
import Menu from '../MenuBar';
import Footer from '../Footer';
import ErrorPg from '../Error';
import { useParams } from 'react-router-dom';

export default function ModelsFinal() {
    var a = 0;
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [errMsg,setErrMsg] = useState("")
    const [modelData,setModelData] = useState("")
    const params = useParams()
    const brandID = params.bid
    const modelID = params.mid
    useEffect(() =>{
        if(a == 0){
            getModelData()
        }
        a++
    },[])
    const getModelData = () =>{
        axios.post(apiurl + "api/getBrandData",{
            bid : brandID,
            mid : modelID
        },null)
        .then((res) =>{
            console.log(res.data)
            setModelData(res.data)
            setLoading(false)
        },(error) =>{
            setErrMsg(error.message)
            setError(true)
        })
    }
    if(loading) {
        return(
            <div className='container mainloading'>
                <Loading />
            </div>
        )
    }
    else if(error){
        return(
            <ErrorPg />
        )
    }else
  return (
    <React.Fragment>
        <Menu />
            <section className='modelfpage'>
                <div className='my100 maxwidth'>
                    <div className='my90 float-center modelfpgflex'>
                        <div className='my25 float-left modelcards'>
                            <div className='w90 float-center modelcardmain'>
                                <div className={`container modelimage ${(modelData.brandinfo.model.img != null && modelData.brandinfo.model.img != "") ? "" : "noimage"}`}>
                                    <div className='modelimage'>
                                        <img src={(modelData.brandinfo.model.img != null && modelData.brandinfo.model.img != "") ? asseturl+modelData.brandinfo.model.img : logo} alt={modelData.brandinfo.model.name}/>
                                    </div>
                                </div>
                                <div className='container modeldatabx'>
                                    <div className='w90 float-center'>
                                        <div className='container modelname'>
                                            <p>{modelData.brandinfo.model.name}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w75 float-left'>
                            <div className='w90 float-center'>
                                <div className='container catlisttitle catslside'>
                                    <div className='w98 float-center'>
                                        <p>Available Categories for {modelData.brandinfo.model.name}</p>
                                    </div>
                                </div>
                                <div className='container subcatslist'>
                                    {modelData.list.map((item,i) =>(
                                    (item.subcategories.length > 0) ?
                                    <div className='container subcatmainbox' key={i}>
                                        <div className='container subcattitle'>
                                            <p>{item.name}</p>
                                        </div> 
                                        <div className='container subcatsmlist'>
                                            {item.subcategories.map((cat2, j) =>(
                                                <div className='w20 float-left subcatbx' key={j}>
                                                    <a href={"/c/"+cat2.id+"/"+cat2.slug+"/products"} title={cat2.name}>
                                                        <div className='w90 float-center dropsubcat center-text'>
                                                            <div className='container dropsubsimg'>
                                                                {(cat2.icon != null && cat2.icon != "") ? 
                                                                <div className='dropssubimg'>
                                                                    <img src={asseturl + cat2.icon} alt={cat2.name} referrerPolicy='no-referrer'/>
                                                                </div>
                                                                :
                                                                <div className='dropssubimg dropsunotav'>
                                                                    <img src={logo} alt={cat2.name} referrerPolicy='no-referrer'/>
                                                                </div>
                                                                }
                                                            </div>
                                                            <div className='container dropsubname'>
                                                                <div className='w95 float-center'>
                                                                    <p>{cat2.name}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                        :
                                    <div className='w20 float-left subcatbx' key={i}>
                                        <a href={"/c/"+item.id+"/"+item.slug+"/products"} title={item.name}>
                                            <div className='w90 float-center dropsubcat center-text'>
                                                <div className='container dropsubsimg'>
                                                    {(item.icon != null && item.icon != "") ? 
                                                    <div className='dropssubimg'>
                                                        <img src={asseturl + item.icon} alt={item.name} referrerPolicy='no-referrer'/>
                                                    </div>
                                                    :
                                                    <div className='dropssubimg dropsunotav'>
                                                        <img src={logo} alt={item.name} referrerPolicy='no-referrer'/>
                                                    </div>
                                                    }
                                                </div>
                                                <div className='container dropsubname'>
                                                    <div className='w95 float-center'>
                                                        <p>{item.name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <Footer/>
    </React.Fragment>
  )
}
