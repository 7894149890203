import React, { useState } from 'react'
import {BsStar,BsStarFill} from 'react-icons/bs'
import revImg from './revimg.png'
import { Cookies, useCookies } from 'react-cookie';
import LoadingIndicator from "react-loading-indicator";
import {FiAlertTriangle} from 'react-icons/fi'
import { Rating } from 'react-simple-star-rating'
import {FaTimes} from 'react-icons/fa'

export default function WriteReview(props) {
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [revLoading,setRevLoading] = useState(false)
    const [star1,setStar1] = useState(4)
    const [starsErr,setStarsErr] = useState("")
    const [expmessage,setExpMessage] = useState("")
    const [star2,setStar2] = useState(4)
    const [starsErr2,setStarsErr2] = useState("")
    const [delmessage,setDelMessage] = useState("")
    const [img1,setImg1] = useState("")
    const [imgsrc1,setImgSrc1] = useState("")
    const [img2,setImg2] = useState("")
    const [imgsrc2,setImgSrc2] = useState("")
    const [img3,setImg3] = useState("")
    const [imgsrc3,setImgSrc3] = useState("")
    const [img4,setImg4] = useState("")
    const [imgsrc4,setImgSrc4] = useState("")
    const [img5,setImg5] = useState("")
    const [imgsrc5,setImgSrc5] = useState("")
    const [img6,setImg6] = useState("")
    const [imgsrc6,setImgSrc6] = useState("")
    const [item1,setItem1] = useState(true)
    const [item2,setItem2] = useState(false)
    
    const getExpMessage = (e) =>{
        setExpMessage(e.target.value)
    }
    const getDelMessage = (e) =>{
        setDelMessage(e.target.value)
    }
    const getimg1 = (e) =>{
        setImgSrc1(e.target.files[0])
        setImg1(URL.createObjectURL(e.target.files[0]))
    }
    const getimg2 = (e) =>{
        setImgSrc2(e.target.files[0])
        setImg2(URL.createObjectURL(e.target.files[0]))
    }
    const getimg3 = (e) =>{
        setImgSrc3(e.target.files[0])
        setImg3(URL.createObjectURL(e.target.files[0]))
    }
    const getimg4 = (e) =>{
        setImgSrc4(e.target.files[0])
        setImg4(URL.createObjectURL(e.target.files[0]))
    }
    const getimg5 = (e) =>{
        setImgSrc5(e.target.files[0])
        setImg5(URL.createObjectURL(e.target.files[0]))
    }
    const getimg6 = (e) =>{
        setImgSrc6(e.target.files[0])
        setImg6(URL.createObjectURL(e.target.files[0]))
    }

const [ratingValue, setRatingValue] = useState(0)
  
  const handleRating = (rate) => {
    setRatingValue(rate)
    if(rate == 20){
        setStar1(1)
    }else if(rate == 40){
        setStar1(2)
    }else if(rate == 60){
        setStar1(3)
    }else if(rate == 80){
        setStar1(4)
    }else if(rate == 100){
        setStar1(5)
    }
  }
  const handleRatingDelivery = (rate) =>{
    if(rate == 20){
        setStar2(1)
    }else if(rate == 40){
        setStar2(2)
    }else if(rate == 60){
        setStar2(3)
    }else if(rate == 80){
        setStar2(4)
    }else if(rate == 100){
        setStar2(5)
    } 
  }
    const submitReview = (e) =>{
        e.preventDefault()
        setRevLoading(true)
        const formData = new FormData();
        formData.append("pid",props.prod.mainproduct.price.p_id)
        formData.append("bid",props.prod.mainproduct.price.b_id)
        formData.append("mid",props.prod.mainproduct.price.m_id)
        formData.append("uid",cookies.user.id)
        formData.append("star",star1)
        formData.append("comment",expmessage)
        formData.append("star2",star2)
        formData.append("comment2",delmessage)
        formData.append("img1",imgsrc1)
        formData.append("img2",imgsrc2)
        formData.append("img3",imgsrc3)
        formData.append("img4",imgsrc4)
        formData.append("img5",imgsrc5)
        formData.append("img6",imgsrc6)

        fetch(gapiurl + "api/uploadRev",{
            headers : {
                'Accept': 'application/json'
            },
            method : "POST",
            body  : formData
        })
        .then(res => res.json())
        .then((json) =>{
            console.log(json)
            setRevLoading(false)
            window.location.reload()
        })
        .catch(error =>{
            console.log(error)
        })
    }
    const moveNext = () =>{
        setRevLoading(true)
        if(expmessage == ""){
            setStarsErr("Please write about your product experience")
            console.log("empty")
            setRevLoading(false)
        }else{
            setItem1(false)
            setItem2(true)
            setRevLoading(false)
        }
    }
    const moveBack = () =>{
        setItem1(true)
        setItem2(false)
    }
    const closerev = () =>{
        setItem1(true)
        setItem2(false)
        props.hide(false)
    }
    if(revLoading) {
        return(
            <div className='writereviewpop container'>
                <div className='container revpopbox'>
                    <div className='writebox'>
                        <div className='loadingbox revloading dashloading'>
                            <div className="loadercircle container"><LoadingIndicator segmentWidth={4} segmentLength={9}/> </div> 
                        </div>
                    </div>
                </div>
            </div>)
    }
    else
  return (
    <div className='writereviewpop container' style={{display : props.show ? "block" : "none"}}>
        <div className='container revpopbox'>
            <div className='writebox'>
                <div className='closerevpop' onClick={closerev}>
                    <FaTimes />
                </div>
                <div className='w90 float-center'>
                    <div className='container mainheadongreview'>
                        <p>Write a Review</p>
                    </div>
                    <form onSubmit={submitReview}>
                        {item1 &&
                            <div className='container item1'>
                                <div className='container prodexp'>
                                    <div className='writerevbox container'>
                                        <div className='container'>
                                            <div className='headingr container left-text'>
                                                <p>Product Experience</p>
                                            </div>
                                            <div className='container'>
                                                <Rating 
                                                    onClick={handleRating}
                                                    ratingValue={ratingValue}
                                                    initialValue={4}
                                                    fillColorArray={['#f17a45', '#f19745', '#f1a545', '#f1b345', '#f1d045']} 
                                                />
                                            </div>
                                            <div className='container float-left'>
                                                <div className='headingr container left-text'>
                                                    <p>Message *</p>
                                                </div>
                                                <div className='container float-center textmessage'>
                                                    <textarea
                                                    rows="4"
                                                    value={expmessage}
                                                    onChange={getExpMessage}
                                                    placeholder='Message you would like share about your product experience'
                                                    id='expmessage'
                                                    ></textarea>
                                                </div>
                                                <div className='container messageerror'>
                                                    <p>{starsErr}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='container float-center imguploads'>
                                            <div className='w60 float-left'>
                                                <div className='headingr container left-text'>
                                                    <p>Add Images</p>
                                                </div>
                                                <div className='container'>
                                                    <div className='container float-center'>
                                                        <div className='w33 float-left'>
                                                            <div className='w90 float-left'>
                                                                <label htmlFor='img1'>
                                                                    <div className='revieup'>
                                                                        <img src={(img1 == "") ? revImg : img1} alt="Upload Image" />
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className='w33 float-left'>
                                                            <div className='w90 float-center'>
                                                                <label htmlFor='img2'>
                                                                    <div className='revieup'>
                                                                        <img src={(img2 == "") ? revImg : img2} alt="Upload Image" />
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className='w33 float-left'>
                                                            <div className='w90 float-right'>
                                                                <label htmlFor='img3'>
                                                                    <div className='revieup'>
                                                                        <img src={(img3 == "") ? revImg : img3} alt="Upload Image" />
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <input type="file" onChange={getimg1} id="img1"></input>
                                                <input type="file"  onChange={getimg2} id="img2"></input>
                                                <input type="file"  onChange={getimg3} id="img3"></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='nextbutton container'>
                                    <button type="button" className='tonext' onClick={moveNext}>Next</button>
                                </div>
                            </div>
                            }
                            {item2 &&
                            <div className='container item2'>
                                <div className='container prodexp'>
                                    <div className='writerevbox container'>
                                        <div className='container'>
                                            <div className='container float-center'>
                                                <div className='headingr container left-text'>
                                                    <p>Delivery Experience</p>
                                                </div>
                                                <div className='container'>
                                                    <Rating 
                                                        onClick={handleRatingDelivery}
                                                        ratingValue={ratingValue}
                                                        initialValue={4}
                                                        fillColorArray={['#f17a45', '#f19745', '#f1a545', '#f1b345', '#f1d045']} 
                                                    />
                                                </div>
                                            </div>
                                            <div className='container float-left'>
                                                <div className='headingr container left-text'>
                                                    <p>Message</p>
                                                </div>
                                                <div className='container float-center textmessage'>
                                                    <textarea
                                                    rows="4"
                                                    value={delmessage}
                                                    onChange={getDelMessage}
                                                    placeholder='Message you would like to share about your delivery experience'
                                                    id='expmessage'
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='container float-center imguploads'>
                                            <div className='w60 float-left'>
                                                <div className='headingr container left-text'>
                                                    <p>Add Images</p>
                                                </div>
                                                <div className='container'>
                                                    <div className='container float-left'>
                                                        <div className='w33 float-left'>
                                                            <div className='w90 float-center'>
                                                                <label htmlFor='img4'>
                                                                    <div className='revieup'>
                                                                        <img src={(img4 == "") ? revImg : img4} alt="Upload Image" />
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className='w33 float-left'>
                                                            <div className='w90 float-center'>
                                                                <label htmlFor='img5'>
                                                                    <div className='revieup'>
                                                                        <img src={(img5 == "") ? revImg : img5} alt="Upload Image" />
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className='w33 float-left'>
                                                            <div className='w90 float-center'>
                                                                <label htmlFor='img6'>
                                                                    <div className='revieup'>
                                                                        <img src={(img6 == "") ? revImg : img6} alt="Upload Image" />
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <input type="file" onChange={getimg4} id="img4"></input>
                                                <input type="file"  onChange={getimg5} id="img5"></input>
                                                <input type="file"  onChange={getimg6} id="img6"></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='container submits'>
                                    <div className='nextbutton w50 float-left'>
                                        <div className='w90 float-left'>
                                            <button type="button" className='tonext toback' onClick={moveBack}>Back</button>
                                        </div>
                                    </div>
                                    <div className='w50 float-left submitbutton'>
                                        <div className='w90 float-left'>
                                            <button>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}
