import React, { useEffect, useState ,useCallback } from 'react'
import { Link,useLocation,useNavigate } from 'react-router-dom'
import { Cookies, useCookies } from 'react-cookie';
import {FaStar,FaDownload,FaTrash} from 'react-icons/fa'
import {AiOutlineClose} from 'react-icons/ai'
import {BsCheckCircleFill} from 'react-icons/bs'
import {FiAlertTriangle} from 'react-icons/fi'
import ofImg from './images/of.png'
import walImg from './images/wallet.png'
import resImg from './images/res.png'
import appliedImg from './images/applied.png'
import coinImg from './images/coin.png'
import gstImg from './images/gst.png'
import axios from "axios";
import LoadingIndicator from "react-loading-indicator";
import ErrorPg from '../Error';
import Footer from '../Footer';
import Menu from '../MenuBar';

export default function Checkout() {
    const [cartItems,setCartItems] = useState([])
    const [isLoading,setIsLoading] = useState(true)
    const [displays,setDisplays] = useState(false)
    const [error,setError] = useState("")
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [promoCode, setPromoCode] = useState()
    const [promoLoading,setPromoLoading] = useState()
    const [usedCoin,setUsedCoin] = useState()
    const [appliedPromocode,setAppliedPromocode] = useState()
    const [promoVal,setPromoVal] = useState("")
    const [offerpop,setOfferpop] = useState(false)
    const [compName,setCompName] = useState("")
    const [ErrCompName,setErrCompName] = useState("")
    const [gstbox,setGstbox] = useState(true)
    const [gstnumber,setGstnumber] = useState("")
    const [ErrGstNum,setErrGstNum] = useState("")
    const [gstApplied,setGstapplied] = useState(false)
    const [toResell,setToResell] = useState(false)
    const [resellBox,setResellBox] = useState(true)
    const [ErrMargin,setErrMargin] = useState("")
    const [margin,setMargin] = useState("")
    const fetchItem = apiurl + "api/cartinfo"
    let location = useLocation();
    let cItem = cookies.cartItem
    let User = cookies.user
    
    const navigate = useNavigate()
    useEffect(() =>{
        fetchCart("",0)
        console.log(User)
     },[])
    
     function fetchCart(code,coins,company,gst,resell) {
        if(location.state == "" || location.state == null) {
            navigate("/")
        }
        else{
            setIsLoading(true)
            axios({
                method : "POST",
                url:fetchItem,
                params : {
                    cart: JSON.stringify(cItem),
                    address : location.state.addressID,
                    myToken : User.myToken,
                    promo : code,
                    coin : coins,
                    company : company,
                    companyGst : gst,
                    resell : resell
                }
            }).then(
                (response) => {
                    setCartItems(response.data)
                    const script = document.createElement("script")
                    if(response.data.gateway == "Razorpay"){
                        script.src = "https://checkout.razorpay.com/v1/checkout.js";
                        script.async = true;
                    }else{
                        script.src="https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/namxQx98040159498976.js";
                        script.async = true;
                    }
                    document.body.appendChild(script);
                    setIsLoading(false)
                    console.log(response.data)
                },
                (error) =>{
                    setError(error)
                }
            )
        }
        }
    
    function naming(name,slug){
        var newSlug
        if(slug == "" || slug == null){
            newSlug = name.replace(/ /g, "-")
            newSlug = newSlug.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
        }else{
            newSlug = slug.replace(/ /g, "-")
            newSlug = newSlug.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
        }
        return newSlug.toLowerCase()
    }
    // ***************************promo code ***************************
    const fetchPromocodes = () =>{
        setOfferpop(true)
        setPromoLoading(true)
        axios.post(apiurl + "api/promoList",null,{
            params : {
                categories : cartItems.categories,
                famnt : cartItems.famnt
            }
        })
        .then(res => {
            setPromoCode(res.data)
            setPromoLoading(false)
        })
    }
    const checkPromo = (val) =>{
        setPromoVal(val.target.value)
    }
    const typedPromo = (e) =>{
        e.preventDefault();
        applyPromoCode(promoVal)
    }
    const applyPromoCode = (code) => {
        setPromoLoading(true)
        axios.post(apiurl+"api/checkpromo",null,{
            params:{
                myToken : User.myToken,
                code:code
            }
        })
        .then(res => {
            if(res.data.status == "Failed"){
                alert(res.data.message)
            }else{
                setAppliedPromocode(code)
                fetchCart(code,0,compName,gstnumber,margin)
                setUsedCoin(0)
            }
        }).then(() => {
            setPromoLoading(false)
            setOfferpop(false)
        })
    }
    const removeCode = () =>{
        fetchCart("",0,compName,gstnumber)
    }
    // **********************promo code end **********************

    // *************************Coins ******************************
    const redeemCoin = () => {
        setAppliedPromocode('')
        setUsedCoin(cartItems.usableBalance)
        fetchCart("",cartItems.usableBalance)
    }
    const removeCoins = () =>{
        setAppliedPromocode('')
        fetchCart("",0,compName,gstnumber,margin)
        setUsedCoin(0)
    }
    // *************************Coins end******************************
    
    // *************************GST ******************************
    const getCompName = (val) =>{
        setCompName(val.target.value)
    }
    const getGstNum = (val) =>{
        setGstnumber(val.target.value)
    }
    const showgstform = () =>{
        setGstbox(false)
    }
    const hidegstform = () =>{
        setGstbox(true)
    }
    const applyGst = (e) =>{
        e.preventDefault();
        if(compName == ""){
            setErrCompName("Enter valid company name")
        }
        else if((gstnumber == "") || (gstnumber.length < 15) || (gstnumber.length > 15)) {
            setErrGstNum("Enter a valid GST number")
        }
        else{
            fetchCart(appliedPromocode,usedCoin,compName,gstnumber,margin)
            setGstapplied(true)
            console.log(compName,gstnumber)
            setGstbox(true)
        }
    }
    const removeGst = () =>{
        setCompName("")
        setGstnumber("")
        fetchCart(appliedPromocode,usedCoin,"","",margin)
        setGstapplied(false)
        setGstbox(true)
    }
    
    // *************************GST ******************************

    // *************************Resell ******************************
    const resellPop = () =>{
        setResellBox(false)
    }
    
    const getMargin = (val) =>{
        setMargin(val.target.value)
    }
    const hideMargin = () =>{
        setResellBox(true)
    }
    const applyResell = (e) =>{
        e.preventDefault()
        if(margin == ""){
            setErrMargin("Please enter a valid value")
        }else{
            fetchCart(appliedPromocode,usedCoin,compName,gstnumber,margin)
            setResellBox(true)
            setToResell(true)
        }
    }
    const removeResell = () =>{
        fetchCart(appliedPromocode,usedCoin,compName,gstnumber,"")
        setResellBox(true)
        setToResell(false)
    }
    // *************************Resell end******************************
    // *************************Payment Razorpay ******************************
    const payNow = () => {
        var options = {
            description: cartItems.combinedName,
            image: cartItems.products[0].image,
            currency: 'INR',
            key: 'rzp_test_H2wvycE7ZwEOPs', // Your api key rzp_test_S62RugJQ8pHEs7
            amount: (cartItems.famnt + cartItems.shipping)*100,
            name: 'Purchasing Products',
            prefill: {
                email: User.email,
                contact: User.contact,
                name: User.name,
                // method: paymentMethod.toLowerCase()
            },
            "handler": function (response){
                paymentSuccessful(response.razorpay_payment_id,"Online","");
            },
            theme: {color: global.themecolor}
        }
    var rzp = new window.Razorpay(options);
    rzp.open()
    }
    const paymentSuccessful = (payid,mode,paytmdata) => {
        setIsLoading(true)
        axios.post(apiurl+"api/checkout", null, {
            params:{
                myToken : User.myToken,
                cart : JSON.stringify(cartItems),
                company : compName,
                companyGst : gstnumber,
                mode : mode,
                paymentID : payid,
                paymentresponse  : paytmdata
            }
        })
        .then(res => {
            if(res.data == 1){
                removeCookie('cartItem')
                setTimeout(function(){
                    navigate("/success",{replace: true ,state : {payId : payid}})
                },500);
            }else{
                alert("Something Wrong! Please try again after some time")
            }
        })
        .catch((error) => {
            setError(error)
        })
    }
    // *************************Payment Razorpay  end******************************
    // ***************************COD***************************************
        const codOrder = (x,y) =>{
            if(x>0){
                setDisplays(true)
            }else{
                if(cartItems.gateway == "Razorpay"){
                    codPayment(0)
                }
                else{
                    codPaytm(0)
                }
            }
        }
        const codPayment = (x) =>{
            setDisplays(false)
            const d = new Date();
            let str = d.toLocaleDateString() + d.toLocaleTimeString();
            str = str.replace(/:/g, '');
            str = str.replace(/PM/g, '');
            str = str.replace(/AM/g, '');
            str = str.replace(/ /g, '');
            if(x>0){
                var options = {
                    description: cartItems.combinedName,
                    image: cartItems.products[0].image,
                    currency: 'INR',
                    key: 'rzp_test_H2wvycE7ZwEOPs', // Your api key
                    amount: (x)*100,
                    name: 'Purchasing Products',
                    prefill: {
                        email: User.email,
                        contact: User.contact,
                        name: User.name,
                    },
                    "handler": function (response){
                        paymentSuccessful(response.razorpay_payment_id,"COD","");
                    },
                    theme: {color: global.themecolor}
                }
                var rzp = new window.Razorpay(options);
                    rzp.open()
                }
                else{
                    paymentSuccessful(str,"COD")
                }
            }
        
            const codPaytm = (x) =>{
                setDisplays(false)
                setPaytmLoading(true)
                axios.post(apiurl +"/Paytm_PHP_Checksum-master/test.php?orderid="+cartItems.orderid+
                    "&amount="+x+
                    "&cust_id="+cookies.user.id)
                .then(async(res) =>{
                    if(res.data != ""){
                        console.log(res.data)
                        makeCodPaymentPaytm(res.data)
                    }
                })
            }

            const makeCodPaymentPaytm = (x) =>{
                var config = {
                    "root":"",
                    "style": {
                    "bodyBackgroundColor": "#fafafb",
                    "borderRadius" : "5px",
                    "bodyColor": "",
                    "themeBackgroundColor": "#0FB8C9",
                    "themeColor": "#ffffff",
                    "headerBackgroundColor": "#fff",
                    "headerColor": "#333",
                    "errorColor": "",
                    "successColor": "green",
                    "card": {
                        "padding": "",
                        "backgroundColor": ""
                    }
                    },
                    "data": {
                    "orderId": cartItems.orderid,
                    "token": x,
                    "tokenType": "TXN_TOKEN",
                    "amount": cartItems.advanceCOD 
                    },
                    "payMode": {
                    "labels": {},
                    "filter": {
                        "exclude": []
                    },
                    "order": [
                        "CC",
                        "DC",
                        "NB",
                        "UPI",
                        "PPBL",
                        "PPI",
                        "BALANCE"
                    ]
                    },
                    "website": "WEBSTAGING",
                    "flow": "DEFAULT",
                    "merchant": {
                    "mid": PaytmMerchantId,
                    "redirect": false
                    },
                    "handler": {
                    "transactionStatus":
                        function transactionStatus(paymentStatus){
                            console.log("payment status" ,paymentStatus);
                            if(paymentStatus.STATUS == "TXN_SUCCESS"){
                                paymentSuccessful(paymentStatus.TXNID , "COD" , paymentStatus)      
                            }
                        },
                        "notifyMerchant":
                        function notifyMerchant(eventName,data){
                            console.log("Closed");
                        }
                        }
                        };    
                    if (window.Paytm && window.Paytm.CheckoutJS) {
                            window.Paytm.CheckoutJS.init(config).
                    then(function onSuccess() {
                        setPaytmLoading(false)
                        window.Paytm.CheckoutJS.invoke();
                    }).catch(function onError(error) {
                        console.log("Error => ", error);
                    });
                }
            }
    // ***************************COD End***************************************
    if(error) {
        return(
            <ErrorPg />
        )
    }else if(isLoading){
        return(
            <div className='loadingbox addloading'>
                <div className="loadercircle container"><LoadingIndicator segmentWidth={4} segmentLength={9}/> </div> 
            </div>
        )
    }else 
  return (
      <React.Fragment>
        <Menu />
        <section className='checkout-box address'>
            <div className='container maxwidth'>
                <div className='w90 float-center cartnty'>
                    <div className='w60 float-left'>
                        <div className='container'>
                            <div className='w50 float-left'>
                                <div className='w98 float-left'>
                                    <div className='container cart-heading'>
                                        <div className='w95 float-center'>
                                            <p>Delivery Address</p>
                                        </div>
                                    </div>
                                    <div className='container deladdress'>
                                        <div className='addressbox container float-left'>
                                            <Link to="/address"  title='address' replace >
                                            <div className='w90 float-center'>
                                                <div className='container address-name'>
                                                    <p>{cartItems.address.name}</p>
                                                </div>
                                                <div className='container addressfull'>
                                                    <p className='address-p'>
                                                        {cartItems.address.address}
                                                    </p>
                                                    <p className='address-city'>
                                                        {cartItems.address.city}
                                                    </p>
                                                    <p className='address-state'>
                                                        {cartItems.address.state} - {cartItems.address.pincode}
                                                    </p>
                                                    <p className='address-email'>
                                                        {cartItems.address.email}
                                                    </p>
                                                    <p className='address-num'>
                                                        {cartItems.address.contact}
                                                    </p>
                                                </div>
                                                <div className='container deliverhere'>
                                                    <button>Change Delivery Address</button>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <div className='w50 float-left'>
                                <div className='w98 float-right'>
                                    <div className='container'>
                                        <div className='container cart-heading'>
                                            <div className='w95 float-center'>
                                                <p>Price Details</p>
                                            </div>
                                        </div>
                                        <div className='container float-center price-detailsbox'>
                                            <div className='container pricebif'>
                                                <div className='w90 float-center'>
                                                    <ul>
                                                        <li>
                                                            <div className='m-text'>
                                                                <p>Price ({cartItems.products.length} Items)</p>
                                                            </div>
                                                            <div className='p-text'>
                                                                <p><span>₹</span>{cartItems.price - cartItems.reselled}</p>
                                                            </div>
                                                        </li>
                                                        {
                                                        (cartItems.reselled > 0) &&
                                                        <li className=' prapplied'>
                                                            <div className='m-text'>
                                                                <p>Resell amount <BsCheckCircleFill/></p>
                                                            </div>
                                                            <div className='p-text'>
                                                                <p><span>₹</span>{cartItems.reselled}</p>
                                                            </div>
                                                        </li>
                                                        }
                                                        <li className='disc'>
                                                            <div className='m-text'>
                                                                <p>Discount</p>
                                                            </div>
                                                            <div className='p-text'>
                                                                <p>- <span>₹</span>{cartItems.discount}</p>
                                                            </div>
                                                        </li>
                                                        {
                                                        (cartItems.promodiscount > 0) &&
                                                        <li className='disc prapplied'>
                                                            <div className='m-text'>
                                                                <p>Promo Code Applied <BsCheckCircleFill/></p>
                                                            </div>
                                                            <div className='p-text'>
                                                                <p>-<span>₹</span>{cartItems.promodiscount}</p>
                                                            </div>
                                                        </li>
                                                        }
                                                        {
                                                        (cartItems.usedCoin > 0) &&
                                                        <li className='disc prapplied'>
                                                            <div className='m-text'>
                                                                <p>Coins Used <BsCheckCircleFill/></p>
                                                            </div>
                                                            <div className='p-text'>
                                                                <p>-<span>₹</span>{cartItems.usedCoin}</p>
                                                            </div>
                                                        </li>
                                                        }
                                                        <li className='f-amt'>
                                                            <div className='m-text'>
                                                                <p>Total Amount</p>
                                                            </div>
                                                            <div className='p-text'>
                                                                <p><span>₹</span>{cartItems.famnt}</p>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        (cartItems.discount > 0) &&
                                    <div className='container cashback'>
                                        <div className='w80 float-center center-text'>
                                            <p>You will save <span>₹</span> {cartItems.discount} on this product</p>
                                        </div>
                                    </div>
                                    }
                                    {
                                        (cartItems.cbtotal > 0) &&
                                    <div className='container cashback'>
                                        <div className='w80 float-center center-text'>
                                            <p>You will receive a cashback of <span>₹</span> {cartItems.cbtotal}</p>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='container cart-heading'>
                            <div className='w95 float-center'>
                                <p>Items in Cart</p>
                            </div>
                        </div>
                        { cartItems.products.map((item , i) =>(
                            <div className='container cart-itemslist' key={i}>
                                <div className='cart-item container'>
                                    <div className='w90 float-center'>
                                        <div className='container cart-item-details'>
                                            <div className='w30 float-left'>
                                                <div className="cart-itemimg">
                                                    <Link to={"/product/"+ naming(item.name,item.slug) +
                                                    "?p_id=" + item.pid + 
                                                    "&m_id=" + item.mid + 
                                                    "&b_id=" + item.bid} 
                                                    title={item.name} >
                                                    <img src={item.image} alt={item.name} referrerPolicy="no-referrer" />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className='w70 float-left'>
                                                <div className="w90 float-center">
                                                    <Link to={"/product/"+ naming(item.name,item.slug) +
                                                    "?p_id=" + item.pid + 
                                                    "&m_id=" + item.mid + 
                                                    "&b_id=" + item.bid} 
                                                    title={item.name} >
                                                        <div className="container prod-name">
                                                            <p>{item.name}</p>
                                                        </div>
                                                    </Link>
                                                    <div className="container cart-prod-price">
                                                        <div className="container pricebox cardprices">
                                                            <div className="finalamt mainp">
                                                                <p><span>₹</span>{item.famnt}</p>
                                                            </div>
                                                            <div className="main-price oldp">
                                                                <p><span>₹</span>{item.price}</p>
                                                            </div>
                                                            <div className="percent-off cardoff">
                                                                <p>{item.off}% off</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='w40 float-left'>
                        <div className='container alloffers'>
                            <div className='container offers ofmain'>
                                <div className='container'>
                                    <div className='w90 float-center cart-heading'>
                                        <div className='w95 float-center'>
                                            <div className='container'>
                                                <p>Offers and Benefits</p>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                {(cartItems.promodiscount > 0) &&
                                    <div className='container '>
                                        <div className='w90 float-center cart-heading promoapp'>
                                            <div className='offersbox w95 float-center'>
                                                <div className='ofpara'>
                                                    <div className='ofimg'>
                                                        <img src={appliedImg} alt="Offers" />
                                                    </div>
                                                    <p>Promocode "{appliedPromocode}" Applied</p>
                                                </div>
                                                <p className='remove-promo' onClick={removeCode}>Remove</p>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                <div className='container '>
                                    <div className='w90 float-center cart-heading' onClick={fetchPromocodes}>
                                        <div className='offersbox w95 float-center'>
                                            <div className='ofpara'>
                                                <div className='ofimg'>
                                                    <img src={ofImg} alt="Offers" />
                                                </div>
                                                <p>Apply a promo code</p>
                                            </div>
                                            <p>View Offers</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(cartItems.usedCoin > 0) ? 
                            <div className='container offers'>
                                <div className='container '>
                                    <div className='w90 float-center cart-heading promoapp'>
                                        <div className='offersbox w95 float-center'>
                                            <div className='ofpara'>
                                                <div className='ofimg'>
                                                    <img src={coinImg} alt="Offers" />
                                                </div>
                                                <p>Redeemed {cartItems.usedCoin} coins successfully</p>
                                            </div>
                                            <p className='remove-promo' onClick={removeCoins}>Remove</p>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            cartItems.usableBalance > 0 &&
                            <div className='container offers'>
                                <div className='container '>
                                    <div className='w90 float-center cart-heading' onClick={redeemCoin}>
                                        <div className='offersbox w95 float-center'>
                                            <div className='ofpara'>
                                                <div className='ofimg'>
                                                    <img src={walImg} alt="Offers" />
                                                </div>
                                                <p>Reddem {cartItems.usableBalance} Coins</p>
                                            </div>
                                            <p>Redeem Now</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            {
                            (resellBox) ?
                                <>
                                { (toResell) ? 
                                <div className='container offers'>
                                    <div className='container '>
                                        <div className='w90 float-center cart-heading promoapp'>
                                            <div className='offersbox w95 float-center'>
                                                <div className='ofpara'>
                                                    <div className='ofimg'>
                                                        <img src={resImg} alt="Offers" />
                                                    </div>
                                                    <p>Resell applied successfully</p>
                                                </div>
                                                <p className='remove-promo' onClick={removeResell}>Remove</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='container offers' hidden>
                                    <div className='container '>
                                        <div className='w90 float-center cart-heading' onClick={resellPop}>
                                            <div className='offersbox w95 float-center'>
                                                <div className='ofpara'>
                                                    <div className='ofimg'>
                                                        <img src={resImg} alt="Offers" />
                                                    </div>
                                                    <p>Resell This Product</p>
                                                </div>
                                                <p>Resell</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    }
                                </>
                                :
                                <div className='container'>
                                    <div className='w90 float-center gst-form'>
                                        <div className='container gst-heading center-text'>
                                            <p>Fill your margin to resell this product</p>
                                        </div>
                                        <div className='container gst-details'>
                                            <form onSubmit={applyResell}>
                                                <div className='container infields'>
                                                    <div className='w95 float-center'>
                                                        <input
                                                            type="number"
                                                            required
                                                            placeholder="Enter Margin"
                                                            value={margin}
                                                            onChange={getMargin}
                                                            
                                                            />
                                                    </div>
                                                    <div className='error-message container'>
                                                        <p>{ErrMargin}</p>
                                                    </div>
                                                </div>
                                                <div className='container deliverhere applygst'>
                                                    <div className='w60 float-left'>
                                                        <div className='w90 float-center'>
                                                            <button>Apply</button>
                                                        </div>
                                                    </div>
                                                    <div className='w40 float-left cancelgst'>
                                                        <div className='w90 float-center center-text'>
                                                            <p onClick={hideMargin}>Cancel</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            }
                            {(gstbox) ? 
                            <>
                            {(gstApplied) ? 
                            <div className='container offers'>
                                <div className='container '>
                                    <div className='w90 float-center cart-heading promoapp'>
                                        <div className='offersbox w95 float-center'>
                                            <div className='ofpara'>
                                                <div className='ofimg'>
                                                    <img src={gstImg} alt="Offers" />
                                                </div>
                                                <p>Successfully applied for GST</p>
                                            </div>
                                            <p className='remove-promo' onClick={removeGst}>Remove</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            cartItems.gstclaimbutton != "Hide" &&
                            <div className='container offers'>
                                <div className='container '>
                                    <div className='w90 float-center cart-heading' onClick={showgstform}>
                                        <div className='offersbox w95 float-center'>
                                            <div className='ofpara'>
                                                <div className='ofimg'>
                                                    <img src={gstImg} alt="Offers" />
                                                </div>
                                                <p>GST Claim</p>
                                            </div>
                                            <p>Claim now</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            </>
                            :
                            <div className='container'>
                                <div className='w90 float-center gst-form'>
                                    <div className='container gst-heading center-text'>
                                        <p>Fill details to apply GST claim</p>
                                    </div>
                                    <div className='container gst-details'>
                                        <form onSubmit={applyGst}>
                                            <div className='container infields'>
                                                <div className='w95 float-center'>
                                                    <input
                                                        type="text"
                                                        required
                                                        placeholder="Company Name"
                                                        value={compName}
                                                        onChange={getCompName}
                                                        id="name"
                                                        />
                                                </div>
                                                <div className='error-message container'>
                                                    <p>{ErrCompName}</p>
                                                </div>
                                            </div>
                                            <div className='container infields'>
                                                <div className='w95 float-center'>
                                                    <input
                                                        type="text"
                                                        required
                                                        placeholder="GST Number"
                                                        value={gstnumber}
                                                        onChange={getGstNum}
                                                        
                                                        />
                                                </div>
                                                <div className='error-message container'>
                                                    <p>{ErrGstNum}</p>
                                                </div>
                                            </div>
                                            <div className='container deliverhere applygst'>
                                                <div className='w60 float-left'>
                                                    <div className='w90 float-center'>
                                                        <button>Apply</button>
                                                    </div>
                                                </div>
                                                <div className='w40 float-left cancelgst'>
                                                    <div className='w90 float-center center-text'>
                                                        <p onClick={hidegstform}>Cancel</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        <div className='payments container'>
                            <div className='container payheadbox'>
                                <div className='w90 float-center cart-heading'>
                                    <div className='w95 float-center'>
                                        <div className='container'>
                                            <p>Payment</p>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div className='container payment-options'>
                                <div className='w95 float-center'>
                                    {cartItems.codAvailable == 0 &&
                                    <div className='w50 float-left'>
                                        <div className='w90 float-center cod'>
                                            <button onClick={() => codOrder(cartItems.advanceCOD,cartItems.famnt)}>Cash on Delivery</button>
                                        </div>
                                    </div>
                                    }
                                    <div className='w50 float-right'>
                                        <div className='w90 float-center paynow'>
                                            <button onClick={cartItems.gateway =="Razorpay" ? payNow : paytmPay}>Pay Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className='offers-popup' style={{display : (offerpop) ? "block" : "none"}}>
            <div className='w40 float-right offerboxpop'>
                <div className='w95 float-center'>
                    <div className='container offerlist-heading'>
                        <div className='w90 float-center'>
                            <p>Offers Available</p>
                        </div>
                    </div>
                    <div className='container enter-promo'>
                        <form onSubmit={typedPromo}>
                            <div className='container e-promo'>
                                <div className='w80 float-left promoinput'>
                                    <input 
                                    type="text"
                                     placeholder='Alredy have a promocode enter here' 
                                     required 
                                     value={promoVal} 
                                     onChange={checkPromo} 
                                     ></input>
                                </div>
                                <div className='w20 float-left applybut'>
                                    <button>Apply</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {
                    (promoLoading) ? 
                    <div className="loadercircle container"><LoadingIndicator segmentWidth={4} segmentLength={9}/> </div> 
                    : (
                    <div className='container'>
                        { (promoCode != "" && promoCode != null ) &&
                        promoCode.map((promo,index) => (
                        <div className='container' key={index}>
                            <div className='w95 float-center offer-list'>
                                <div className='offerbox container' onClick={() => applyPromoCode(promo.code)}>
                                    <div className='w95 float-center'>
                                        <div className='w70 float-left'>
                                            <div className='container offer-head'>
                                                <p>{promo.title}</p>
                                            </div>
                                            <div className='container offer-details'>
                                                <p>
                                                    {promo.description}
                                                </p>
                                            </div>
                                            <div className='code'>
                                                <p>{promo.code}</p>
                                            </div>
                                        </div>
                                        <div className='w30 float-left'>
                                            <div className='container float-center applybut'>
                                                <button>Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>      
                        </div>  
                        ))}
                    </div>
                    )}
                </div>
                <div className='offerpopclose' onClick={() => setOfferpop(false)}> <AiOutlineClose /></div>
            </div>
        </div>
        <div className='delete-pop container' style={{display : (displays) ? "block" : "none"}}>
            <div className='container del-pop'>
                <div className='w30 float-center poptextbox center-text'>
                    <div className='w90 float-center'>
                        <div className='container iconalert'>
                        <FiAlertTriangle />
                        </div>
                        <div className='icontext container'>
                            <p>You have to make a advance payment of ₹ {cartItems.advanceCOD} and rest ₹ {cartItems.famnt - cartItems.advanceCOD} on delivery</p>
                        </div>
                        <div className='container delbutts'>
                            <div className='w50 float-left'>
                                <div className='w90 float-center'>
                                    <button className='delbutton greenbut' onClick={ () => cartItems.gateway =="Razorpay" ? codPayment(cartItems.advanceCOD,cartItems.famnt) :  codPaytm(cartItems.advanceCOD,cartItems.famnt) }>Continue</button>
                                </div>
                            </div>
                            <div className='w50 float-left'>
                                <div className='w90 float-center'>
                                    <button className='cancelbutton' onClick={() => setDisplays(false)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </React.Fragment>
  )
}
