import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './baseData'
import './css/stylesheet.css'
import './css/responsive.css'
import { BrowserRouter} from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId='71219934866-1mca60k6hqj4gkltg4h670mfsvpirle9.apps.googleusercontent.com'>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </GoogleOAuthProvider>
);
