import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useCookies,Cookies } from 'react-cookie'
import ProductCard from '../product/ProductCard';
import axios from 'axios';
import Loading from '../Loading';
import Menu from '../MenuBar';
import Footer from '../Footer';
import ErrorPg from '../Error';

export default function Homepage() {
    var a = 0;
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [errMsg,setErrMsg] = useState("")
    const [banners,setBanners] = useState("")
    const [categories,setCategories] = useState("")
    const [products,setProducts] = useState("")
    const [offers,setOffers] = useState("")
    const [brands,setBrands] = useState("")
    const [cookies,setCookie,removeCookie] = useCookies('')
    useEffect(() =>{
        if(a == 0){
            setCookie('callback',('/'),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
            fetchBanners();
        }
        a++
    },[])

    const fetchBanners = () =>{
        axios.get(apiurl + "api/banners",null,null)
        .then((res) =>{
            setBanners(res.data)
            fetchCategories()
        },(error) =>{
            setErrMsg(error.message)
            setError(true)
        })
    }
    const fetchCategories = () =>{
        axios.get(apiurl + "api/toppicks/0",null,null)
        .then((res) =>{
            fetchProducts()
            setCategories(res.data)
        },(error) =>{
            setErrMsg(error.message)
            setError(true)
        })
    }
    const fetchProducts = () =>{
        axios.get(apiurl + "api/all-productWithOffer",{
            params : {
                page : 1,
                col : "date",
                order : "desc"
            }
        })
        .then((res) =>{
            setProducts(res.data.data)
            console.log(res.data.data)
            fetchOffers()
        },(error) =>{
            setErrMsg(error.message)
            setError(true)
        })
    }
    const fetchOffers = () =>{
        axios.get(apiurl + "api/trendings",null,null)
        .then((res) =>{
            setOffers(res.data)
            fetchBrands()
        },(error) =>{
            setErrMsg(error.message)
            setError(true)
        })
    }
    const fetchBrands = () =>{
        axios.post(apiurl + "api/getBrands",{
            p_id : 0
        },null)
        .then((res) =>{
            setBrands(res.data)
            setLoading(false)
        },(error) =>{
            setErrMsg(error.message)
            setError(true)
        })
    }
    const bannerOptions = {
        slidesPerView : 1,
        spaceBetween:30,
        loop:true,
        autoHeight: true,
        pagination :{
          clickable: true,
        },
        navigation :true,
        modules : [Pagination, Navigation]
    }

    const categoriesOptions = {
        spaceBetween:0,
        loop:true,
        autoHeight: true,
        navigation :{
            prevEl: '.prevnav',
            nextEl: '.nextnav',
            clickable : true
        },
        modules : [Navigation]
    }
    
    if(loading) {
        return(
            <div className='container mainloading'>
                <Loading />
            </div>
        )
    }
    else if(error){
        return(
            <ErrorPg />
        )
    }else
  return (
    <React.Fragment>
        <Menu />
        <section className='banners'>
            <div className='container bannerbox'>
                {banners != null && banners != "" &&
                <>
                <Swiper className='bannerswiper' {...bannerOptions}>
                    {banners.map((banner,i) =>(
                    <SwiperSlide key={i}>
                        {screen.width > 900  ? 
                        <div className='bannerimg container'>
                            <a href={banner.url} title="">
                                <img src={banner.banner} alt='' referrerPolicy='no-referrer'/>
                            </a>
                        </div>
                        :
                        <div className='bannerimg container mobbanner'>
                            <a href={banner.url} title="">
                                <img src={banner.mobilebanner} alt='' referrerPolicy='no-referrer'/>
                            </a>
                        </div>
                        }
                    </SwiperSlide>
                    ))}
                </Swiper>
                </>
                }
            </div>
        </section>
        <section className='categlist'>
            <div className='container'>
                <div className='w90 float-center categbxmain'>
                    <div className='container headingsbox left-text'>
                        <div className='container mainheading'>
                            <p>Browse Our Hottest Categories</p>
                        </div>
                    </div>
                    {categories != null && categories != "" && <>
                    <div className='container categoriesidx'>
                        <div className='w90 float-center cat90'>
                        <Swiper {...categoriesOptions} 
                            slidesPerView={2}
                            breakpoints={{
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 0,
                            },
                            768: {
                                slidesPerView: 4,
                                spaceBetween: 0,
                            },
                            1024: {
                                slidesPerView: 7,
                                spaceBetween: 0,
                            },
                            }} className='categsiper'>
                            {categories.map((cat,i) =>(
                            <SwiperSlide key={i}>
                                <div className='container categorycard'>
                                    <a href='#' title={cat.alt}>
                                        <div className='w95 float-center categcardmain'>
                                            <img src={cat.banner} alt={cat.alt} referrerPolicy='no-referrer'/>
                                        </div>
                                    </a>
                                </div>
                            </SwiperSlide>
                            ))}
                        </Swiper>
                        </div>
                        {categories.length > 7 &&<>
                        <div className='navbutton prevnav'>
                            <i className='fas fa-chevron-left'></i>
                        </div>
                        <div className='navbutton nextnav'>
                            <i className='fas fa-chevron-right'></i>
                        </div>
                        </>}
                    </div>
                    </>}
                </div>
            </div>
        </section>
        {(brands != null && brands != "") &&
        <section className='brands'>
            <div className='container maxwidth'>
                <div className='w90 float-center brandstnty'>
                    <div className='container headingsbox center-text'>
                        <div className='container mainheading'>
                            <p>Brands We Work With</p>
                        </div>
                    </div>
                    <div className='container brandsbox'>
                        <div className='container float-center cat90'>
                            {brands.map((brand,i) =>(
                                <div className='w16 float-left' key={i}>
                                    <div className='brandimage container'>
                                        <a href={'/brand/'+brand.id} title={brand.name}>
                                            <div className='brandimg w90 float-center'>
                                                <img src={asseturl + brand.img} alt={brand.name} referrerPolicy='no-referrer'/>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        }
        <section className='cardsproducts'>
            <div className='container maxwidth'>
                <div className='w90 float-center'>
                    <div className='container headingsbox center-text'>
                        <div className='container mainheading'>
                            <p>Featured Products</p>
                        </div>
                    </div>
                    <div className='container productslist'>
                        {products != null && products != "" && <>
                            {products.map((prod,i) =>(
                                <div className='w25 float-left' key={i}>
                                    <ProductCard data={prod}/>
                                </div>
                            ))}
                        </>}
                    </div>
                </div>
            </div>
        </section>
        <section className='offerbanner'>
            <div className='container'>
                <div className='w90 float-center offerbannerimg'>
                    <img src='https://robu.in/wp-content/uploads/2023/06/Raspberry-Pi_In-stock-1.jpg' alt='' />
                </div>
            </div>
        </section>
        {offers != null && offers != "" && <>
        <section className='offersmid'>
            <div className='container offersbox'>
                <div className='w90 float-center offersflx'>
                    {offers.map((of,i) =>(
                    <div className='w33 float-left offerimagebox' key={i}>
                        <a href='#' title=''>
                            <div className='w95 float-center offimg'>
                                <img src={of.banner} alt='' referrerPolicy='no-referrer' />
                            </div>
                        </a>
                    </div>
                    ))}
                </div>
            </div>
        </section>
        </>}
        <Footer />
    </React.Fragment>
  )
}
