import React,{useEffect, useState} from 'react';
import Signupicon from './sidesign.png'
import {AiFillEye,AiFillEyeInvisible,AiFillFacebook} from 'react-icons/ai'
import axios from 'axios';
import { Cookies, useCookies } from 'react-cookie';
import { Link,useNavigate,useLocation } from 'react-router-dom';
import {FcGoogle} from 'react-icons/fc'
// import GoogleLogin from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
import LoadingIndicator from 'react-loading-indicator'
// import Errorpage from '../Errorpage';
import {BiLockOpenAlt,BiUser,BiPhone,BiEnvelope} from 'react-icons/bi'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Loading from '../Loading';
import { GoogleLogin } from '@react-oauth/google';
import Menu from '../MenuBar';
import Footer from '../Footer';

export default function SignUp() {
    const [hidden1,setHidden1] = useState(true)
    const [hidden2,setHidden2] = useState(true)
    const [isDisabled,setIsDisabled] = useState(true)
    const [isGid,setIsGid] = useState("")
    const [isFid,setIsFid] = useState("")
    const [userImg,setUserImg] = useState("")
    const [stepTwo,setStepTwo] = useState(false)
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [firstname, setFirstname] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [confirmpassword, setConfirmpassword] = useState("");
    const [ErrEmail,setErrEmail] = useState("")
    const [ErrContact,setErrContact] = useState("")
    const [ErrName,setErrName] = useState("")
    const [ErrPassmatch,setErrPassmatch] = useState("")
    const [ErrTerms,setErrTerms] = useState("")
    const [showGoogleLogin,setShowGoogleLogin] = useState(false)
    
    const navigate = useNavigate()
    let userLog = cookies.user
    useEffect(() => {
          if(userLog != "" && userLog != null){
              navigate("/")
          }else{
            setIsLoading(false)
          }
    },[])
    const isChecked = (e) =>{
      if(e.target.checked){
          setIsDisabled(false)
      }else{
          setIsDisabled(true)
      }
    } 
    const Hidden2 = () =>{
        setHidden2(false)
    }
    const Hidden1 = () =>{
        setHidden1(false)
    }
    const show2 = () =>{
      setHidden2(true)
     }
    const show1 = () =>{
      setHidden1(true)
   }
    function getName(val) {
      setFirstname(val.target.value) 
      }
      function getEmail(val) {
          setEmail(val.target.value) 
      }
      function getPhone(val) {
          setPhone(val.target.value) 
      }
      function getCPass(val) {
          setConfirmpassword(val.target.value) 
      }
      var strong = 0;
      function getPass(val){
          var a = $("#password").val();
          setPassword(val.target.value) 
          $(".strongpara").html('');
          $(".strongpara").css({'color':'rgba(0,0,0,0.6)'})
          if(a.length > 8){
              strong = strong + 1;
          }else{
              $(".strongpara").html('Must Be Minimum 8 Characters');
          }
          if((/[A-Z]/.test(a)) == true){
              strong = strong + 1;
          }else{
              $(".strongpara").html('Must Contain a Capital Alphabet');
          }  
          if((/[a-z]/.test(a)) == true){
              strong = strong + 1;
          }else{
              $(".strongpara").html('Must Contain a Small Alphabet');
          }    
          if((/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(a)) == true){
              strong = strong + 1;
          }else{
              $(".strongpara").html('Must Contain a Special Character');
          }   
          if((/\d/.test(a)) == true){
              strong = strong + 1;
          }else{
              $(".strongpara").html('Must Contain a Numeric Digit');
          }    
          if(strong==0){
              $('.strongpassword').css({"display":"none"})
          }else{
              $('.strongpassword').css({"display":"block"})
          }
          if(strong==1){
              $('.borders').css({
                  "width":"19%",
                  "background":"#CD1407"
              });
          }
          if(strong==2){
              $('.borders').css({
                  "width":"38%",
                  "background": "#F18D7D"});
          }
          if(strong==3){
              $('.borders').css({
                  "width":"57%",
                  "background": "#F39F12"});
          }
          if(strong==4){
              $('.borders').css({
                  "width":"76%",
                  "background": "#6699CC"});
          }
          if(strong==5){
              $('.borders').css({
                  "width":"95%",
                  "background": "#7CFC00"});
              $(".strongpara").html("You're all set to go!");
          }
          return strong;
      } 
       $("#password-confirm").on("keyup", function (){
          confirm_pass();
     });
  //Confirm Password
  function confirm_pass(){
      var a = window.$("#password-c").val();
      var b = window.$("#password").val();
      if(a == b){
          $("#password-confirm").css({ borderBottom:"0.5px solid #75d078" });
          return 1;
      }else{
          $("#password-confirm").css({ borderBottom:"0.5px solid red" });
          return 0;
      }
  } 
  
      function handleSubmit(e) {
        e.preventDefault();
        // perform all neccassary validations
        if(firstname == ""){
            setErrName("Enter valid name")
        }
        else if(email == ""){
            setErrEmail("Enter valid email")
        }
        else if(/\S+@\S+\.\S+/.test(email) == false){
            setErrEmail("Enter valid email")
        }
        else if((phone == "") || (phone.length < 10)) {
            setErrContact("Enter valid phone number")
        }
        else if(strong >= 5){
            setErrPassmatch("Enter valid password")
        }
        else if (password !== confirmpassword) {
            setErrPassmatch("Passwords don't match");
        }
        else if(!isChecked){
            setErrTerms("You have to Accept Terms and Conditions")
        }
        else{
            setIsLoading(true),
            axios.post(apiurl + "api/userSignup",{
                name: firstname,
                password : password,
                email : email,
                contact : phone,
                gid : isGid,
                fid : isFid,
                img : userImg
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(
                (response) =>{
                    if(response.data.status == "Failed"){
                        setIsLoading(false)
                        if(response.data.err == "Email"){
                            setIsLoading(false)
                            setErrEmail(response.data.Message)
                        } else if(response.data.err == "Contact"){
                            setIsLoading(false)
                            setErrContact(response.data.Message)
                        }
                    }else{
                        setCookie('user',JSON.stringify(response.data.user));
                        navigate("/verify-otp")
                        window.location.reload()
                    }
                },
                (error) =>{
                    setError(error)
                }
            )
        }
      }
      // *************Google Signup***********
      
      let callback
      if((typeof cookies.callback == "undefined") || (cookies.callback == "")){
          callback = "/"
      }else{
          callback = cookies.callback
      }
      function decodeJwtResponse (token) {
          var base64Url = token.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
      
          return JSON.parse(jsonPayload);
      }
      const handleCredentialResponse = (response) =>{
          console.log("Encoded JWT ID token: " + response.credential);
          const responsePayload = decodeJwtResponse(response.credential);
  
          console.log("ID: " + responsePayload.sub);
          console.log('Full Name: ' + responsePayload.name);
          console.log('Given Name: ' + responsePayload.given_name);
          console.log('Family Name: ' + responsePayload.family_name);
          console.log("Image URL: " + responsePayload.picture);
          console.log("Email: " + responsePayload.email);
          var logData = {
          "displayName" : responsePayload.name,
          "email" : responsePayload.email,
          "phoneNumber" : "",
          "photoURL" : responsePayload.picture,
          "uid" : responsePayload.sub,
          "provider" : "Google"
          }
          socialLogin(logData)
        }
        
      const responseFacebook = (response) => {
          var logData = {
          "displayName" : response.name,
          "email" : response.email,
          "phoneNumber" : "",
          "photoURL" : response.picture.data.url,
          "uid" : response.id,
          "provider" : "Facebook"
          }
          socialLogin(logData)
        }
        const socialLogin = (logData) => {
          setIsLoading(true)
          axios.post(apiurl+"api/userGoogleLogin", {
              loginData : JSON.stringify(logData),
          },
          { headers: { 'Content-Type': 'application/json' } })
          .then(res => {
            console.log(res.data)
              if(res.data.newuser != 1){
                  setCookie('user',JSON.stringify(res.data.user),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
                  if(res.data.notverified != 0){
                      window.location.href = "/verify-otp"
                  }else{
                      window.location.href = callback
                  }
              }else{
                  setCookie('tempUser',JSON.stringify(logData),{path : "/",maxAge: 10 })
                  setIsLoading(true)
                  window.location.reload()
              }
              setIsLoading(false)
          }).catch(error => { 
              setIsLoading(false)
              setError(error)
          })
      }
      useEffect(() => {
          let tempUser = cookies.tempUser
          if((typeof cookies.tempUser != "undefined") && (cookies.tempUser != "")){
              setStepTwo(true)
              setFirstname(tempUser.displayName);
              setEmail(tempUser.email)
              setPassword(tempUser.uid + "@JVGp")
              setConfirmpassword(tempUser.uid + "@JVGp")
              setUserImg(tempUser.photoURL)
              if(tempUser.provider == "Google"){
                  setIsGid(tempUser.uid)
              }else{
                  setIsFid(tempUser.uid)
              }
          }else{
              setStepTwo(false)
          }
      },[])
        const responseGoogleFail = (response) => {
          // console.log(response,"Login Failed");
          setError(error)
        }
      // *************Google Signup end***********
      
    const responseMessage = (response) => {
        handleCredentialResponse(response)
    };
    const errorMessage = (error) => {
        console.log(error);
    };
    if(error) {
        return (
            <p>{error.message}</p>
        )
    }else if(isLoading){
        return (
            <div className='loadingbox signloading'>
                <div className="loadercircle container"><Loading /></div> 
            </div>
            )
    }
    else
    return(
        <React.Fragment>
            <Menu />
            <section className='formbox'>
                <div className='container maxwidth'>
                    <div className='container float-center sign-flex'>
                        <div className='w70 float-center formwhitebg'>
                            <div className='container float-left front-layer signup'>
                                <div className='container float-right mainform'>
                                    <div className='w80 float-center'>
                                        {(stepTwo) ? 
                                        <div className='container sign-up-text center-text'>
                                            <div className='sign-e container'>
                                                <p>Continue Signing Up</p>
                                            </div>
                                            <div className='signup-p container'>
                                                <p>
                                                    Please fill the below details in order to proceed further
                                                </p>
                                            </div>
                                        </div>
                                        :
                                        <div className='container signboxes'>
                                            <div className='signtopbox'>
                                                <div className='signtext '>
                                                    <Link to="/user-login">Log In</Link>
                                                </div>
                                                <div className='signtext actside'>
                                                    <p>Sign Up</p>
                                                </div>
                                            </div>
                                        </div>
                                         }
                                        <div className='container'>
                                            <form  autoComplete="off" onSubmit={handleSubmit}>
                                                {!stepTwo && 
                                                <div className='container infields'>
                                                    <div className='container float-center fieldbox'>
                                                        <BiUser />
                                                        <input
                                                            required
                                                            placeholder="Name"
                                                            value={firstname}
                                                            onChange={getName}
                                                            />
                                                    </div>
                                                    <div className='error-message container'>
                                                        <p>{ErrName}</p>
                                                    </div>
                                                </div>
                                                }
                                                {!stepTwo && 
                                                <div className='container infields'>
                                                    <div className='container float-center fieldbox'>
                                                        <BiEnvelope/>
                                                        <input
                                                            type="email"
                                                            required
                                                            placeholder="E-mail"
                                                            value={email}
                                                            onChange={getEmail}
                                                            
                                                            />
                                                    </div>
                                                    <div className='error-message container'>
                                                        <p>{ErrEmail}</p>
                                                    </div>
                                                </div>
                                                }
                                                <div className='container infields'>
                                                    <div className='container w95 float-center fieldbox'>
                                                        <BiPhone/>
                                                        <input
                                                            type="number"
                                                            required
                                                            placeholder="Phone No."
                                                            value={phone}
                                                            onChange={getPhone}
                                                            />
                                                    </div>
                                                    <div className='error-message container'>
                                                        <p>{ErrContact}</p>
                                                    </div>
                                                </div>
                                                <div className={`container infields ${(stepTwo) ? "hiddenFiels" : ""}`}>
                                                    <div className='container float-center fieldbox'>
                                                    <BiLockOpenAlt />
                                                        <input
                                                            required
                                                            type={(hidden1) ?  'password' : 'text'}
                                                            placeholder="Password"
                                                            value={password}
                                                            id="password"
                                                            onChange={getPass}
                                                            />
                                                            <div className='show-hide' >
                                                               {(hidden1) ?  <AiFillEyeInvisible onClick={Hidden1}/> : <AiFillEye onClick={show1}/>}
                                                            </div>
                                                        </div>
                                                        <div className="container strongpassword">
                                                            <hr className="borders" style={{width: "95%", background: "rgb(124, 252, 0)"}} />
                                                        <p className="strongpara">You're all set to go!</p>
                                                    </div>
                                                </div>
                                                <div className={`container infields ${(stepTwo) ? "hiddenFiels" : ""}`} >
                                                    <div className='container float-center fieldbox' id="password-confirm">
                                                    <BiLockOpenAlt />
                                                        <input
                                                            required
                                                            id="password-c"
                                                            placeholder="Confirm Password"
                                                            type={(hidden2) ?  'password' : 'text'}
                                                            value={confirmpassword}
                                                            onChange={getCPass}
                                                            />
                                                        <div className='show-hide'>
                                                           {(hidden2) ?  <AiFillEyeInvisible  onClick={Hidden2} /> : <AiFillEye onClick={show2}/>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='error-message container'>
                                                    <p>{ErrPassmatch}</p>
                                                </div>
                                                <div className='container infields termslabel'>
                                                    <label>
                                                        <input type="checkbox" required name='check' onChange={isChecked} />
                                                        By signing up you agree to our <Link to="#" title=""> Terms and Conditions</Link>
                                                    </label>
                                                </div>
                                                <div className='error-message container'>
                                                    <p>{ErrTerms}</p>
                                                </div>
                                                <div className='container infields center-text'>
                                                    <button type='submit' disabled={isDisabled} className='subbut' >Sign Up</button>
                                                </div>
                                            </form>
                                        </div>
                                        <div className='container forgot-passowrd newacc center-text'>
                                            <p>Already have an account? <Link to="/user-login" title="">Log In Here</Link></p>
                                        </div>
                                        {showGoogleLogin && <>
                                        {(!stepTwo) &&
                                        <>
                                            <div className='ortext container center-text'>
                                                <p>or</p>
                                            </div>
                                            <div className='social-signups container center-text'>
                                                <div className='container social-head'>
                                                    <p>Continue with your social network</p>
                                                </div>
                                                <div className='container g-button'>
                                                    <GoogleLogin onSuccess={responseMessage} theme='outline' shape='pill' type='icon' onError={errorMessage} useOneTap/>
                                                </div>
                                            </div>
                                            </>
                                        }   
                                        </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    )
}