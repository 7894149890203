import React, { useEffect, useState } from 'react'
import { Cookies, useCookies } from 'react-cookie';
import {BiHomeSmile,BiBuilding} from 'react-icons/bi'
// import noAddress from './address.svg'
import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios';
import LoadingIndicator from "react-loading-indicator";
import ErrorPg from '../Error';
import Menu from '../MenuBar';
import Footer from '../Footer';

export default function Address() {
    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies('');
    
    let User = cookies.user
    let cartItems = cookies.cartItem
    useEffect (() =>{
        if(User == "" || User == null) {
            navigate("/")
        }
        else if(cartItems == "" || cartItems == null){
            navigate("/")
        }
        else if(cookies.user.emailverified == "" || cookies.user.emailverified == null){
            navigate("/verify-otp")
        }
        else{
            fetAddress();
        }
    },[]) 
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState()
    const [newName,setNewName] = useState("")
    const [ErrName,setErrName] = useState("")
    const [newPhone,setNewPhone] = useState("")
    const [ErrPhone,setErrPhone] = useState("")
    const [newEmail,setNewEmail] = useState("")
    const [ErrEmail,setErrEmail] = useState("")
    const [address,setAddress] = useState("")
    const [ErrAddress,setErrAddress] = useState("")
    const [pincode,setPincode] = useState("")
    const [ErrPincode,setErrPincode] = useState("")
    const [city,setCity] = useState("")
    const [ErrCity,setErrCity] = useState("")
    const [state,setState] = useState("")
    const [ErrState,setErrState] = useState("")
    const [landmark,setLandmark] = useState("")
    const [addType,setAddType] = useState("Home")
    const [existingAddress,setExistingAddress] = useState([])
    const fetAddress = async () =>{
        setLoading(true)
        if(User !== "" || User !== null){
            axios.post(apiurl + "api/userAddress",{
                authToken : User.myToken
            },
            {
                headers : {
                    'Content-Type': 'application/json'
                }
            })
            .then(res =>{
                if(res.data.status == "OK"){
                    setExistingAddress(res.data.addressList)
                    if(res.data.addressList == "" || res.data.addressList == null){
                        setNewName(User.name)
                        setNewEmail(User.email)
                        setNewPhone(User.contact)
                    }
                }
                setLoading(false)
            }).catch(error => {
                setError(error)
            })
        }
    }
    function getNewName(val) {
        setNewName(val.target.value) 
    }
    function getNewPhone(val) {
        setNewPhone(val.target.value) 
    }
    function getNewEmail(val) {
        setNewEmail(val.target.value) 
    }
    function getAddress(val) {
        setAddress(val.target.value) 
    }
    function getPincode(val) {
        setPincode(val.target.value) 
    }
    function getCity(val) {
        setCity(val.target.value) 
    }
    function getState(val) {
        setState(val.target.value) 
    }
    function getLandmark(val) {
        setLandmark(val.target.value) 
    }
    function getAddType(val) {
        setAddType(val.target.value) 
    }
    const NewAddress = (e) =>{
        e.preventDefault();
    // perform all neccassary validations
    if(newName == ""){
        setErrName("Enter valid name")
    }
    else if((newPhone == "") || (newPhone.length < 10)) {
        setErrPhone("Enter valid phone number")
    }
    else if(/\S+@\S+\.\S+/.test(newEmail) == false){
        setErrEmail("Enter valid email")
    }
    else if(newEmail == ""){
        setErrEmail("Enter valid email")
    }
    else if(address == ""){
        setErrAddress("Enter valid address")
    }
    else if(city == ""){
        setErrCity("Enter valid city")
    }
    else if(pincode == "" || pincode.length < 6){
        setErrPincode("Enter valid pincode")
    }
    else{
        setLoading(true)
        axios.post(apiurl + "api/addAddress",{
            authToken : User.myToken,
            uid : User.id,
            name : newName,
            email : newEmail,
            contact : newPhone,
            altcontact : "",
            address : address,
            pincode : pincode,
            city: city,
            state : state,
            landmark : landmark,
            addressType : addType
        },
        {headers : {
            'Content-Type': 'application/json'
        }}
        )
        .then(res =>{
            if(res.data.status == "OK"){
                console.log("Successfully added")
                fetAddress();
                setLoading(false)
            }else{
                console.log("Error Occured")
            }
        }).catch(error =>{
            console.log(error.message)
            setLoading(false)
        })
    }
    }
    if(loading){
        return(
            <div className='loadingbox'>
                <div className="loadercircle container"><LoadingIndicator segmentWidth={4} segmentLength={9}/> </div> 
            </div>
        )
    }else if(error){
        return (
            <ErrorPg />
        )
    }
    else{
  return (
    <React.Fragment>
        <Menu />
        <section className='address'>
            <div className='container maxwidth'>
                <div className='w90 float-center cartnty'>
                    <div className='saved-address w60 float-left'>
                        <div className='container cart-heading'>
                            <div className='w95 float-center'>
                                <p>Select Delivery Address</p>
                            </div>
                        </div>
                        {(existingAddress == "" || existingAddress == null) ? (
                            <div className='empty-address container center-text'>
                                <div className='container emptyadds'>
                                    {/* <div className='emptyadd'>
                                        <img src={noAddress} alt="No address" />
                                    </div> */}
                                    <div className='container noaddress-p'>
                                        <p>No Address available</p>
                                    </div>
                                    <div className='container label-name'>
                                        <label htmlFor='name' >Add Address</label>
                                    </div>
                                </div>
                            </div>
                        ) :
                        (
                        <div className='container addresses-box'>
                            {
                        existingAddress.map((add,index) => (
                            <div className='w50 float-left' key={index}>
                                <div className='w98 addressbox'>
                                    <Link to="/checkout" state={{ addressID: add.id }}  title='address' replace >
                                        <div className='w90 float-center'>
                                            <div className='container address-name'>
                                                <p>{add.name}</p>
                                            </div>
                                            <div className='container addressfull'>
                                                <p className='address-p'>
                                                    {add.address}
                                                </p>
                                                <p className='address-city'>
                                                    {add.city}
                                                </p>
                                                <p className='address-state'>
                                                    {add.state} - {add.pincode}
                                                </p>
                                                <p className='address-email'>
                                                    {add.email}
                                                </p>
                                                <p className='address-num'>
                                                    {add.contact}
                                                </p>
                                            </div>
                                            <div className='container deliverhere'>
                                                <button>Deliver to this Address</button>
                                            </div>
                                        </div>
                                        <p className='address-type'>{(add.type == "Home") ? <BiHomeSmile /> : <BiBuilding />} {add.type}</p>
                                    </Link>
                                </div>
                            </div>
                        ))}
                        </div>
                        )}
                    </div>
                    <div className='w40 float-left addformbox'>
                        <div className='w90 float-center create-address'>
                            <div className='container cart-heading'>
                                <div className='w95 float-center'>
                                    <p>Add a New Delivery Address</p>
                                </div>
                            </div>
                            <div className='container new-address-form'>
                                <div className='w95 float-center'>
                                    <form onSubmit={NewAddress}>
                                        <div className='container infields'>
                                            <div className='w95 float-center'>
                                                <input
                                                    type="text"
                                                    required
                                                    placeholder="Name"
                                                    value={newName}
                                                    onChange={getNewName}
                                                    id="name"
                                                    />
                                            </div>
                                            <div className='error-message container'>
                                                <p>{ErrName}</p>
                                            </div>
                                        </div>
                                        <div className='container infields'>
                                            <div className='w95 float-center'>
                                                <input
                                                    type="number"
                                                    required
                                                    placeholder="Phone Number"
                                                    value={newPhone}
                                                    onChange={getNewPhone}
                                                    
                                                    />
                                            </div>
                                            <div className='error-message container'>
                                                <p>{ErrPhone}</p>
                                            </div>
                                        </div>
                                        <div className='container infields'>
                                            <div className='w95 float-center'>
                                                <input
                                                    type="email"
                                                    required
                                                    placeholder="Email"
                                                    value={newEmail}
                                                    onChange={getNewEmail}
                                                    
                                                    />
                                            </div>
                                            <div className='error-message container'>
                                                <p>{ErrEmail}</p>
                                            </div>
                                        </div>
                                        <div className='container infields'>
                                            <div className='w95 float-center'>
                                                <input
                                                    type="text"
                                                    required
                                                    placeholder="Address"
                                                    value={address}
                                                    onChange={getAddress}
                                                    
                                                    />
                                            </div>
                                            <div className='error-message container'>
                                                <p>{ErrAddress}</p>
                                            </div>
                                        </div>
                                        <div className='w50 float-left infields'>
                                            <div className='w90 float-center'>
                                                <input
                                                    type="text"
                                                    required
                                                    placeholder="City"
                                                    value={city}
                                                    onChange={getCity}
                                                    
                                                    />
                                            </div>
                                            <div className='error-message container'>
                                                <p>{ErrCity}</p>
                                            </div>
                                        </div>
                                        <div className='w50 float-left  infields'>
                                            <div className='w90 float-center'>
                                                <input
                                                    type="text"
                                                    required
                                                    placeholder="State"
                                                    value={state}
                                                    onChange={getState}
                                                    
                                                    />
                                            </div>
                                            <div className='error-message container'>
                                                <p>{ErrState}</p>
                                            </div>
                                        </div>
                                        <div className='w50 float-left infields'>
                                            <div className='w90 float-center'>
                                                <input
                                                    type="number"
                                                    placeholder="Pincode"
                                                    value={pincode}
                                                    onChange={getPincode}
                                                    required
                                                    />
                                            </div>
                                            <div className='error-message container'>
                                                <p>{ErrPincode}</p>
                                            </div>
                                        </div>
                                        <div className='w50 float-left infields'>
                                            <div className='w90 float-center'>
                                                <input
                                                    type="text"
                                                    placeholder="Landmark"
                                                    value={landmark}
                                                    onChange={getLandmark}
                                                    
                                                    />
                                            </div>
                                        </div>
                                        <div className='container infields addresstypes'>
                                            <div className='w95 float-center'>
                                                <div className='container addt-head'>
                                                    <p>Select an address type</p>
                                                </div>
                                                <div className='container typesofadd'>
                                                    <input
                                                        type="radio"
                                                        placeholder="Address Type"
                                                        value="Home"
                                                        id="Home"
                                                        name='address-type'
                                                        onChange={getAddType}
                                                        checked ={addType === "Home"}
                                                        />
                                                    <label htmlFor="Home"><BiHomeSmile /> Home</label>
                                                    <input
                                                        type="radio"
                                                        placeholder="Address Type"
                                                        value="Office"
                                                        id="Office"
                                                        name='address-type'
                                                        onChange={getAddType}
                                                        checked ={addType === "Office"}
                                                        />
                                                    <label htmlFor="Office"><BiBuilding /> Office</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='container deliverhere'>
                                            <button>Add Address</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </React.Fragment>
  )
}
}