import React, { useEffect, useRef, useState } from 'react'
import MenuSearch from './MenuSearch'
import axios from 'axios';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import Loading from './Loading';
import { useCookies,Cookies } from 'react-cookie';


export default function Menu(props) {
    const [showPop,setShowPop] = useState(false)
    const [menuCats,setMenuCats] = useState("")
    const [subCats,setSubCats] = useState("")
    const [showSubDrop,setShowSubDrop] = useState(false)
    const [showSubcats,setShowSubcats] = useState(false)
    const [catCollapse,setCapCollapse] = useState(false)
    const [brands,setBrands] = useState("")
    const [brandsDrop,setBrandsDrop] = useState(false)
    const [actDrop,setActDrop] = useState(null)
    const [subsLoading,setSubsLoading] = useState(false)
    const [cookies,setCookie,removeCookie] = useCookies('')
    const swiperRef = useRef()
    var a = 0;
    
    function naming(name,slug){
        var newSlug
        if(slug == "" || slug == null){
            newSlug = name.replace(/ /g, "-")
            newSlug = newSlug.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
        }else{
            newSlug = slug.replace(/ /g, "-")
            newSlug = newSlug.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
        }
        return newSlug.toLowerCase()
    }
    useEffect(() =>{
        if(a == 0){
            fetchCategories()
            checkLogin()
            checkcart()
            if(screen.width < 900){
                fetchBrands()
            }
        }
        a++
    },[])
    
    const fetchBrands = () =>{
        axios.post(apiurl + "api/getBrands",{
            p_id : 0
        },null)
        .then((res) =>{
            setBrands(res.data)
        },(error) =>{
            setErrMsg(error.message)
            setError(true)
        })
    }
    const [loggedIn,setLoggedIn] = useState(false)
    const [logDetails,setLogDetails] = useState("")
    const checkLogin = () =>{
        if(cookies.user != undefined && cookies.user != null && cookies.user !=""){
            setLoggedIn(true)
            setLogDetails(cookies.user)
        }else{
            setLoggedIn(false)
            setLogDetails("")
        }
    }
    const [cartList,setCartList] = useState("")
    const checkcart = () =>{
        if(cookies.cartItem != undefined && cookies.cartItem != null && cookies.cartItem !=""){
            setCartList(cookies.cartItem)
        }else{
            setCartList("")
        }
    }
    useEffect(() =>{
        checkcart()
    },[cookies.cartItem])
    const fetchCategories = () =>{
        axios.get(apiurl + "api/caregories/0",null,null)
        .then((res) =>{
            setMenuCats(res.data)
        },(error) =>{
            console.log(error.message)
        })
    }
    const fetchSubCategories = (id) =>{
        setSubsLoading(true)
        setActDrop(id)
        setShowSubcats(true)
        axios.get(apiurl + "api/caregoriesWithSub/"+id,null,null)
        .then((res) =>{
            if(res.data.status == "fail"){
                setSubCats("")
                setSubsLoading(false)
            }else{
                setSubCats(res.data)
                setSubsLoading(false)
            }
        },(error) =>{
            console.log(error.message)
        })
    }
    const leftSubEv = () =>{
        setShowSubcats(false)
    }
    const catOptions = {
        slidesPerView: "auto",
        freeMode: true,
        spaceBetween:20,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        modules : [Autoplay]
    }
    const logOut = () =>{
        removeCookie('user',{path : "/"})
        removeCookie('userToken',{path : "/"})
        window.location.href = '/'
    }
    const menuSlide = () =>{
        $('.linkbox').css("left","0")
    }
    const menuSlideBack = () =>{
        $('.linkbox').css("left","-100%")
    }
    
  return (
        <>
            <nav className="menurk">
                <div className="mfix my100 float-left"  onMouseEnter={() => setShowSubDrop(false)}>
                    <div className="container menulinks-box maxwidth">
                        <div className="w90 overflow-visible mobile-menu-top">
                            <div className="logobox">
                                <div className="menu-bars mobsvgs pc_hide" onClick={menuSlide}>
                                    <i className="fas fa-bars"></i>
                                </div>
                                <a href="/" title="">
                                    <div className="mobile-logo">
                                        <img className="logo" src={logo} alt="" title=""/>
                                    </div>
                                </a>
                                <div className='rside-menu pc_hide'>
                                    <div className="menubutton">
                                        <a href='/cart' title='Go to Cart' style={{background : (cartList.length > 0) ? "var(--maincolor2)" : "var(--maincolor)" }}>
                                            <div className='logicon'>
                                                <i className='fas fa-shopping-cart'></i>
                                            </div>
                                            <div className='cartct'>{(cartList.length > 0) ? cartList.length : 0 }</div>
                                        </a>
                                    </div>
                                    <div className='menubutton'>
                                        <a href={loggedIn ? '/dashboard' : '/user-login'} title='Log In' style={{background : (loggedIn) ? "var(--maincolor2)" : "var(--maincolor)" }}>
                                            <div className='logicon' style={{background : (loggedIn) ? "var(--maincolor2)" : "var(--maincolor)" }}>
                                                <i className='fas fa-user' style={{color : "#fff"}}></i>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="mid-search">
                                <MenuSearch />
                            </div>
                           <div className="search-mobile mobsvgs pc_hide mob_hide">
                                <i className="fas fa-magnifying-glass"></i>
                            </div>
                            <div className="linkbox">
                                <div className="white-area-menu">
                                    <ul>
                                        <li className="pc_hide">
                                            <a href="/" title="Mobspare99">
                                                <div className="side-logo">
                                                    <img className="logo" src={logo} alt="" title=""/>
                                                </div>
                                            </a>
                                        </li>
                                        <li className='pc_hide menushop'>
                                            <div className='container categoriestitle' style={{background : (!catCollapse) ? "transparent" : "var(--maincolor3)"}}>
                                                <p>Categories</p>
                                                {!catCollapse ? <span onClick={() => setCapCollapse(true)}><i className='fas fa-plus'></i></span> : <span onClick={() => setCapCollapse(false)}><i className='fas fa-minus'></i></span>}
                                            </div>
                                            {catCollapse && 
                                            <>
                                            <div className='container categoriesdrop'>
                                                <div className='container float-left mobileshopside'>
                                                    <div className='w98 float-center mobshopbx'>
                                                        {(menuCats != null && menuCats != "") && 
                                                        <div className='container shoplists'>
                                                            <ul>
                                                                {menuCats.map((cats,i) =>(
                                                                i < 12 &&
                                                                <li key={i}>
                                                                    <div className='menucatslide'>
                                                                        <a href={(cats.next == 0) ? "/c/"+cats.id+"/"+cats.slug+"/products" :"/c/"+cats.id+"/"+cats.slug} title={cats.name}>
                                                                            <div className='caticon'>
                                                                                <img src={asseturl + cats.img} alt={cats.name} referrerPolicy='no-referrer'/>
                                                                            </div>
                                                                            <span>
                                                                                {cats.name}
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {menuCats.length > 12 &&
                                            <div className='menuviewall container'>
                                                <a href='/allbrands' title=''>View All Categories</a>
                                            </div>
                                            }
                                            </>
                                            }
                                        </li>
                                        {(brands != null && brands != "") &&
                                        <li className='pc_hide menushop menubrands'>
                                            <div className='container categoriestitle' style={{background : (!brandsDrop) ? "transparent" : "var(--maincolor3)"}}>
                                                <p>Brands</p>
                                                {!brandsDrop ? <span onClick={() => setBrandsDrop(true)}><i className='fas fa-plus'></i></span> : <span onClick={() => setBrandsDrop(false)}><i className='fas fa-minus'></i></span>}
                                            </div>
                                            {brandsDrop && 
                                            <>
                                            <div className='container categoriesdrop'>
                                                <div className='container float-left mobileshopside'>
                                                    <div className='w98 float-center mobshopbx'>
                                                    {brands.map((brand,i) =>(
                                                        i < 12 &&
                                                        <div className='w33 float-left' key={i}>
                                                            <div className='brandimage container'>
                                                                <a href={'/brand/'+brand.id} title={brand.name}>
                                                                    <div className='brandimg w90 float-center'>
                                                                        <img src={asseturl + brand.img} alt={brand.name} referrerPolicy='no-referrer'/>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    </div>
                                                </div>
                                            </div>
                                            {brands.length > 12 &&
                                            <div className='menuviewall container'>
                                                <a href='#' title=''>View All Brands</a>
                                            </div>
                                            }
                                            </>
                                            }
                                        </li>
                                        }
                                        {loggedIn ? 
                                        <li className='menubutton loggedin'>
                                            <a href='#' title={logDetails.name} style={{background : "var(--maincolor2)"}}>
                                                <div className='logicon'>
                                                    <img src={(logDetails.img != null && logDetails.img != "" ) ? logDetails.img : userDef} alt={logDetails.name} />
                                                </div>
                                                <span>
                                                    Hi, {(logDetails.name != null && logDetails.name != "" ) ? logDetails.name : "User"}
                                                </span>
                                            </a>
                                            <div className='dropdownrk'>
                                                <ul>
                                                    <a href='/my-account' title='My Account'>
                                                        <li>
                                                            My Account
                                                        </li>
                                                    </a>
                                                    <a href='/orders' title='My Orders'>
                                                        <li>
                                                            My Orders
                                                        </li>
                                                    </a>
                                                    <a href='/wishlist' title='My Wishlist'>
                                                        <li>
                                                            My Wishlist
                                                        </li>
                                                    </a>
                                                    <a href='/manage-address' title='Manage Address'>
                                                        <li>
                                                            Manage Address
                                                        </li>
                                                    </a>
                                                    <li className='logbut'>
                                                        <button className='logoutbutton' onClick={logOut}>Log Out <i className='fas fa-right-from-bracket'></i></button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        : 
                                        <li className='menubutton'>
                                            <a href='/user-login' title='Log In'>
                                                <div className='logicon'>
                                                    <i className='fas fa-user'></i>
                                                </div>
                                                <span>
                                                    Log In
                                                </span>
                                            </a>
                                        </li>
                                        }
                                        <li className='menubutton'>
                                            <a href='/cart' title='Go to Cart' style={{background : (cartList.length > 0) ? "var(--maincolor2)" : "var(--maincolor)" }}>
                                                <div className='logicon'>
                                                    <i className='fas fa-shopping-cart'></i>
                                                </div>
                                                <span>
                                                    Cart
                                                </span>
                                                <div className='cartct'>{(cartList.length > 0) ? cartList.length : 0 }</div>
                                            </a>
                                        </li>
                                        {/* <li className="pc_hide mailmobiles">
                                            <a href="" title="" target="_blank">
                                                <i className="fas fa-phone"></i>
                                            </a>
                                        </li>
                                        <li className="pc_hide mailmobiles">
                                            <a href="" title="Email Us">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 352c-16.53 0-33.06-5.422-47.16-16.41L0 173.2V400C0 426.5 21.49 448 48 448h416c26.51 0 48-21.49 48-48V173.2l-208.8 162.5C289.1 346.6 272.5 352 256 352zM16.29 145.3l212.2 165.1c16.19 12.6 38.87 12.6 55.06 0l212.2-165.1C505.1 137.3 512 125 512 112C512 85.49 490.5 64 464 64h-416C21.49 64 0 85.49 0 112C0 125 6.01 137.3 16.29 145.3z"/></svg>
                                                
                                            </a>
                                        </li> */}
                                        <li className="pc_hide socialconts">
                                            <ul>
                                                <a href="#" title="" target="_blank">
                                                    <li>
                                                        <i className="fab fa-facebook-f"></i>
                                                    </li>
                                                </a>
                                                <a href="#" title="" target="_blank">
                                                    <li>
                                                        <i className="fab fa-instagram"></i>
                                                    </li>
                                                </a>
                                                <a href="#" title="" target="_blank">
                                                    <li>
                                                        <i className="fab fa-twitter"></i>
                                                    </li>
                                                </a>
                                                <a href="#" title="" target="_blank">
                                                    <li>
                                                        <i className="fab fa-linkedin"></i>
                                                    </li>
                                                </a>
                                                <a href="#" title="" target="_blank">
                                                    <li>
                                                        <i className="fab fa-youtube"></i>
                                                    </li>
                                                </a>
                                                <a href="#" title="" target="_blank">
                                                    <li>
                                                        <i className="fab fa-pinterest"></i>
                                                    </li>
                                                </a>
                                                <a href="#" title="" target="_blank">
                                                    <li>
                                                        <i className="fab fa-whatsapp"></i>
                                                    </li>
                                                </a>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="black-area-menu" onClick={menuSlideBack}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='my100 float-left menucats mob_hide'>
                    <div className='menucategsbox my100 maxwidth'>
                        <div className='my90 float-center menucatslist'>
                            <div className='menucatlistbox'>
                                <div className='menucatbutton' onMouseEnter={() => setShowSubDrop(true)}>
                                    <div className='catbutton'>
                                        <div className='logicon'>
                                            <i className='fas fa-bars'></i>
                                        </div>
                                        <span>
                                            All Categories
                                        </span>
                                    </div>
                                </div>
                                <div className='catsside'>
                                    {(menuCats != null && menuCats != "" ) && 
                                    <Swiper {...catOptions} ref={swiperRef}>
                                        {menuCats.map((cats,i) =>(
                                        <SwiperSlide key={i} className='catlink'>
                                            <div className='menucatslide' onMouseEnter={() => setShowSubDrop(false)}>
                                                <a href={(cats.next == 0) ? "/c/"+cats.id+"/"+cats.slug+"/products" :"/c/"+cats.id+"/"+cats.slug} title={cats.name}>
                                                    <div className='caticon'>
                                                        <img src={asseturl + cats.img} alt={cats.name} referrerPolicy='no-referrer'/>
                                                    </div>
                                                    <span>
                                                        {cats.name}
                                                    </span>
                                                </a>
                                            </div>
                                        </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            {showSubDrop && 
            <div className='container categoriespop' >
                <div className='container catpopbox maxwidth'>
                    <div className='w90 float-center catsbox'  onMouseLeave={() => setShowSubDrop(false)}>
                        <div className='w20 float-left catlistside'>
                            <div className='w90 float-center'>
                                <ul>
                                    {(menuCats != null && menuCats != "" ) && 
                                    <>
                                    {menuCats.map((cats,i) =>(
                                        <a href={(cats.next == 0) ? "/c/"+cats.id+"/"+cats.slug+"/products" : "/c/"+cats.id+"/"+cats.slug} title={cats.name} className={(actDrop == cats.id) ? "acttabdrop" : ""} onMouseEnter={() => fetchSubCategories(cats.id)} key={i}>
                                            <li>
                                                {cats.name}
                                            </li>
                                        </a>
                                    ))}
                                    </>
                                    }
                                </ul>
                            </div>
                        </div>
                        {showSubcats && 
                        <div className={`subctasside w80 float-left ${subsLoading ? "subloadflx" : ""}`}>
                            {subsLoading ? 
                            <div className='w98 float-center subcatlistsdrop subloadbx'>
                                <Loading />
                            </div>
                            :
                            (subCats != null && subCats != "") &&
                            <div className='w98 float-center subcatlistsdrop'>
                                {subCats.map((subcat,i) => (
                                <div className='w16 float-left subcatbx' key={i}>
                                    <a href={(subcat.next == 0) ? "/c/"+subcat.id+"/"+subcat.slug+"/products" : "/c/0/"+actDrop+"/"+subcat.id+"/"+subcat.slug} title=''>
                                        <div className='w90 float-center dropsubcat center-text'>
                                            <div className='container dropsubsimg'>
                                                {(subcat.img != null) ? 
                                                <div className='dropssubimg'>
                                                    <img src={asseturl + subcat.img} alt={subcat.name} referrerPolicy='no-referrer'/>
                                                </div>
                                                :
                                                <div className='dropssubimg dropsunotav'>
                                                    <img src={logo} alt={subcat.name} referrerPolicy='no-referrer'/>
                                                </div>
                                                }
                                            </div>
                                            <div className='container dropsubname'>
                                                <div className='w95 float-center'>
                                                    <p>{subcat.name}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                ))}
                            </div>
                            }
                        </div>
                        }
                    </div>
                </div>
            </div>
            }
            {showPop &&
            <div className='container popup'>
                <div className='popflex container'>
                    <div className='popboxmain'>
                        <div className='container poptop'>
                            <div className='w90 float-center poptopflex'>
                                <div className='popheadp'>
                                    <p>Feel free to shoot a message</p>
                                </div>
                                <div className='popclose' onClick={() => setShowPop(false)}>
                                    <i className='fas fa-times'></i>
                                </div>
                            </div>
                        </div>
                        <div className='container popform'>
                            <div className='w90 float-center'>
                                <form>
                                    <div className='container forminpt'>
                                        <label>
                                            <i className='fas fa-user'></i>
                                        </label>
                                        <input type='text' placeholder='Your Name' required />
                                    </div>
                                    <div className='container forminpt'>
                                        <label>
                                            <i className='fas fa-envelope'></i>
                                        </label>
                                        <input type='email' placeholder='Email Address' required />
                                    </div>
                                    <div className='container forminpt'>
                                        <label>
                                            <i className='fas fa-phone'></i>
                                        </label>
                                        <input type='number' placeholder='Phone Number' required />
                                    </div>
                                    <div className='container forminpt'>
                                        <label>
                                            <i className='fas fa-map-pin'></i>
                                        </label>
                                        <input type='text' placeholder='Your Address' required />
                                    </div>
                                    <div className='container forminpt'>
                                        <label>
                                            <i className='fas fa-message'></i>
                                        </label>
                                        <textarea rows={4} placeholder='Enter Your Message' ></textarea>
                                    </div>
                                    <div className='container forminpt mainbutton'>
                                        <button><span className='blayer'></span> <span className='flayer'>SEND MESSAGE</span></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    )
}
