import React, { useEffect, useState } from 'react'
import Footer from '../Footer'
import Menu from '../MenuBar'
import Sidebar from './Sidebar'
import {BiEditAlt} from 'react-icons/bi'
import {MdPassword} from 'react-icons/md'
import {HiArrowRight} from 'react-icons/hi'
import {FiEdit,FiCheck} from 'react-icons/fi'
import { Cookies, useCookies } from 'react-cookie';
import LoadingIndicator from "react-loading-indicator";
import axios from 'axios'
import ErrorPg from '../Error'
import Loading from '../Loading'

export default function Account() {
    var a = 0;
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [errMsg,setErrMsg] = useState("")
    const [act,setAct] = useState("1")
    const [userName,setUserName] = useState("")
    const [newNumber,setNewNumber] = useState("")
    const [displays,setDisplays] = useState(false)
    const [passpop,setPassPop] = useState(false)
    const [userImg,setUserImg] = useState("")
    const [ErruserImg,setErruserImg] = useState("")
    const [ErruserName,setErruserName] = useState("")
    const [ErrnewNumber,setErrNewNumber] = useState("")
    const [email,setEmail] = useState("")
    const [src2,setsrc2] = useState("")
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    useEffect(() =>{
        if(a == 0){
            fetchUser()
        }
        a++
    },[])
    const fetchUser = () =>{
        if(cookies.user != undefined && cookies.user != null && cookies.user != ""){
            if(cookies.user.emailverified == "" || cookies.user.emailverified == null){
                window.location.href = "/verify-otp"
            }
            else{
                setUserName(cookies.user.name)
                setEmail(cookies.user.email)
                setNewNumber(cookies.user.contact)
                setUserImg(cookies.user.img)
                setLoading(false)
            }
        }
        else{
            window.location.href = "/"
        }
    }
    const updateProfile = (e) =>{
        e.preventDefault()
        if(userName == ""){
            setErruserName("Please enter valid name")
        }else if(newNumber == "" || newNumber.length < 10){
            setErrNewNumber("Please enter valid Number")
        }else{
            setLoading(true)  
            const formData = new FormData()
            formData.append('authToken',cookies.user.myToken)
            formData.append('img',userImg)
            formData.append('name',userName)
            formData.append('contact',newNumber)
            fetch(apiurl + "api/updateProfile",{
                headers : {
                    'Accept' : 'application/json'
                },
                method : "POST",
                body  : formData
            })
            .then(res => res.json())
            .then(json =>{
                if(json.status == "OK"){ 
                    setCookie('user',JSON.stringify(json.user),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
                    setLoading(false)
                    setDisplays(true)
                }
            }).catch(error =>{
                setError(error)
            })
        }
    }
    const nameNew = (val) =>{
        setUserName(val.target.value)
    }
    const getNum = (val) =>{
        setNewPhone(val.target.value)
    }
    const getImg = (val) =>{
        setUserImg(val.target.files[0])
        setsrc2(URL.createObjectURL(val.target.files[0]))
    }
    
    // ****************************Password  *************************
    const [oldPass,setOldPass] = useState("")
    const [erroldPass,setErrOldPass] = useState("")
    const [confPass,setConfPass] = useState("")
    const [errConfPass,setErrConfPass] = useState("")
    const [newPass,setNewPass] = useState("")
    const [errnewPass,setErrNewPass] = useState("")
    
    const getoldPass = (val) =>{
        setOldPass(val.target.value)
    }
    const getnewPass = (val) =>{
        setNewPass(val.target.value)
    }
    const getconfPass = (val) =>{
        setConfPass(val.target.value)
    }

    const changePass = (e) =>{
        e.preventDefault()
        if(newPass < 8){
            setErrNewPass("Password must be at least 8 characters long")
        }
        if(newPass != confPass){ 
            setErrConfPass("Confirm Passwords don't match")
        }
        else{
            setLoading(true)
            axios.post(apiurl+"api/changePassword",null,{
                params:{
                    authToken : cookies.user.myToken,
                    old:oldPass,
                    password:newPass
                }
            })
            .then(res =>{
                if(res.data.status == "OK"){
                    setOldPass("")
                    setNewPass("")
                    setConfPass("")
                    setDisplays(true)
                    setPassPop(true)
                }else{
                    setErrOldPass("Please enter valid old password")
                }
            })
            .then(() => {
                setLoading(false)
            }).catch(error =>{
                setError(error)
            })
        }
    }
    if(loading) {
        return(
            <div className='container mainloading'>
                <Loading />
            </div>
        )
    }
    else if(error){
        return(
            <ErrorPg />
        )
    }else
  return (
    <React.Fragment>
        <Menu />
            <section className='myorders dashpage'>
                <div className='my100 maxwidth'>
                    <div className='my90 float-center dahflx'>
                        <div className='my30 float-left sidebarsidem'>
                            <Sidebar act="1"/>
                        </div>
                        <div className='w70 float-left'>
                            <div className='container float-center orderslistside'>
                                <div className='container dashtitle center-text'>
                                    <p>My Account</p>
                                </div>
                                <div className='container'>
                                    <div className='w50 float-center tabs-top '>
                                        <div className={`w50 float-left tabsmain ${(act == "1") ? "acttabt" : ""}`} onClick={() => setAct(1)}>
                                            <div className='w90 float-center'>
                                                <p> <BiEditAlt /> Edit Your Profile</p>
                                            </div>
                                        </div>
                                        <div className={`w50 float-left tol tabsmain ${(act == "2") ? "acttabt" : ""}`}  onClick={() => setAct(2)}>
                                            <div className='w90 float-center'>
                                                <p><MdPassword />Change Password</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='container'>
                                    <div className='w50 float-center profilemain'>
                                        {(act == "1") &&
                                        <>
                                        <div className='container profile-heading'>
                                            <div className='w90 float-center center-text'>
                                                <p>Edit your Profile</p>
                                            </div>
                                        </div>
                                        <div className='profiles container'>
                                            <div className='container '>
                                                <div className='w98 float-center details'>
                                                    <div className='container float-left'>
                                                        <form onSubmit={updateProfile}>
                                                            <div className='profileimg container'>
                                                                <div className='profileimage'>
                                                                    {(cookies.user.img == "" || cookies.user.img == null) ?
                                                                        <img src={(src2 == "") ? userDef : src2} alt='Profile Image'  referrerPolicy='no-referrer'/>
                                                                        :
                                                                        (
                                                                        (User.img.startsWith("https")) ?
                                                                        <img src={(src2 == "") ? cookies.user.img : src2} alt='Profile Image' referrerPolicy='no-referrer' />
                                                                        :
                                                                        <img src={(src2 == "") ? global.asset + cookies.user.img : src2} alt='Profile Image' referrerPolicy='no-referrer' />
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='container changeimg'>
                                                                <input type="file"  onChange={getImg} id="changeimg"></input>
                                                                <label htmlFor='changeimg'><FiEdit/> Change Image</label>
                                                            </div>
                                                            <div className='container ins'>
                                                                <input 
                                                                type="text" 
                                                                onChange={nameNew}
                                                                value={userName}
                                                                required
                                                                placeholder='Enter Your Name'
                                                                ></input>
                                                                <p>{ErruserName}</p>
                                                            </div>
                                                            <div className='container ins notedit'>
                                                                <input type="email"
                                                                required
                                                                readOnly
                                                                value={email}
                                                                ></input>
                                                            </div>
                                                            <div className='container ins notedit'>
                                                                <input type="number"
                                                                required
                                                                readOnly
                                                                value={newNumber}
                                                                placeholder='Enter Your Phone Number'
                                                                ></input>
                                                                <p>{ErrnewNumber}</p>
                                                            </div>
                                                            <div className='container ins logoutButton'>
                                                                <button>Apply <HiArrowRight /></button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </> 
                                        }
                                        {
                                        (act == "2") &&
                                        <>
                                        <div className='container profile-heading'>
                                            <div className='w90 float-center center-text'>
                                                <p>Change Password</p>
                                            </div>
                                        </div>
                                        <div className='profiles container'>
                                            <div className='container '>
                                                <div className='w98 float-center details'>
                                                    <div className='container float-left'>
                                                        <form onSubmit={changePass}>
                                                            <div className='container ins'>
                                                                <input 
                                                                type="password" 
                                                                placeholder='Enter Your Old Password '
                                                                value={oldPass}
                                                                onChange={getoldPass}
                                                                required
                                                                ></input>
                                                                <p>{erroldPass}</p>
                                                            </div>
                                                            <div className='container ins'>
                                                                <input 
                                                                type="password" 
                                                                placeholder='Enter Your New Password '
                                                                value={newPass}
                                                                onChange={getnewPass}
                                                                required
                                                                ></input>
                                                                <p>{errnewPass}</p>
                                                            </div>
                                                            <div className='container ins'>
                                                                <input 
                                                                type="password" 
                                                                placeholder='Confirm Your New Password '
                                                                value={confPass}
                                                                onChange={getconfPass}
                                                                required
                                                                ></input>
                                                                <p>{errConfPass}</p>
                                                            </div>
                                                            <div className='container ins logoutButton'>
                                                                <button>Apply <HiArrowRight /></button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer white="true"/>
        <div className='delete-pop container successpop' style={{display : (displays) ? "block" : "none"}}>
            <div className='container del-pop'>
                {(passpop) ? 
                <div className='w30 float-center poptextbox center-text'>
                    <div className='w90 float-center'>
                        <div className='animation-wrapper container center-text'>
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <p>Password Updated Succesfully</p>
                        </div>
                        <div className='container gobackbut logoutButton'>
                            <button onClick={() => setDisplays(false)}>Okay</button>
                        </div>
                    </div>
                </div>
                : 
                <div className='w30 float-center poptextbox center-text'>
                    <div className='w90 float-center'>
                        <div className='animation-wrapper container center-text'>
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <p>Profile Updated Succesfully</p>
                        </div>
                        <div className='container gobackbut logoutButton'>
                            <button onClick={() => setDisplays(false)}>Okay</button>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    </React.Fragment>
  )
}
