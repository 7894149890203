import React from 'react'
import Menu from '../MenuBar'
import Footer from '../Footer'
import Sidebar from './Sidebar'
import Loading from '../Loading';
import ErrorPg from '../Error';
import { useState } from 'react';
import { useCookies,Cookies } from 'react-cookie';
import { useEffect } from 'react';
import axios from 'axios';
import ProductCard from '../product/ProductCard';

export default function Wishlist() {
    var a = 0;
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [errMsg,setErrMsg] = useState("")
    const [orderList,setOrderList] = useState("")
    const [cookies,setCookie,removeCookie] = useCookies('')
    useEffect(() =>{
        if(a == 0){
            fetchUser()
        }
        a++
    },[])
    const fetchUser = () =>{
        if(cookies.user != undefined && cookies.user != null && cookies.user != ""){
            if(cookies.user.emailverified == "" || cookies.user.emailverified == null){
                window.location.href = "/verify-otp"
            }
            else{
                fetchOrder(cookies.user.myToken)
            }
        }
        else{
            window.location.href = "/"
        }
    }
    const fetchOrder = (token) =>{
        axios.post(apiurl + "api/userDashboard",null,{
            params : {
                authToken : token,
                website : 1
            }
        })
        .then((res) =>{
            console.log(res.data)
            setOrderList(res.data)
            setLoading(false)
        },(error) =>{
            setErrMsg(true)
            setLoading(false)
            setError(true)
        })
    }
    function naming(name,slug){
        var newSlug
        if(slug == "" || slug == null){
            newSlug = name.replace(/ /g, "-")
            newSlug = newSlug.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
        }else{
            newSlug = slug.replace(/ /g, "-")
            newSlug = newSlug.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
        }
        return newSlug.toLowerCase()
    }
    if(loading) {
        return(
            <div className='container mainloading'>
                <Loading />
            </div>
        )
    }
    else if(error){
        return(
            <ErrorPg />
        )
    }else
  return (
    <React.Fragment>
        <Menu />
            <section className='myorders dashpage'>
                <div className='my100 maxwidth'>
                    <div className='my90 float-center dahflx'>
                        <div className='my30 float-left sidebarsidem'>
                            <Sidebar act="3"/>
                        </div>
                        <div className='w70 float-left'>
                            <div className='container float-center orderslistside'>
                                <div className='container dashtitle center-text'>
                                    <p>My Wishlist</p>
                                </div>
                            </div>
                            <div className='container wishcardlist'>
                                {(orderList.wishlist != undefined && orderList.wishlist != null && orderList.wishlist != "") ?
                                orderList.wishlist.map((ord ,i )=>(
                                    <div className='w33 float-left wishcardbox' key={i}>
                                        <a href={"/product/"+ naming(ord.name,ord.slug) + "?p_id=" +ord.pid + "&m_id=" + ord.mid + "&b_id=" + ord.bid} title={ord.name} >
                                            <div className='w90 float-center wishcard'>
                                                <div className='container wishimg'>
                                                    <img src={(ord.image != null && ord.image != "") ? ord.image : logo} alt={ord.name} referrerPolicy='no-referrer'/>
                                                </div>
                                                <div className='container prodcardname'>
                                                    <div className='w90 float-center'>
                                                        <p>{ord.name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))
                                :
                                <div className='container noprodssearch'>
                                    <div className='w90 float-center'>
                                        <div className='container nosearchimg'>
                                            <img src={noDataImg} alt='Nothing found' />
                                        </div>
                                        <div className='container notfoundtxt center-text'>
                                            <p>No Items in Wishlist</p>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <Footer white="true"/>
    </React.Fragment>
  )
}
