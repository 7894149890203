import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import { Cookies, useCookies } from 'react-cookie';
import Loading from '../Loading';
import ErrorPg from '../Error';
import Menu from '../MenuBar';
import Footer from '../Footer';

export default function Dashboard() {
    var a = 0;
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [errMsg,setErrMsg] = useState("")
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    useEffect(() =>{
        if(a == 0){
            fetchUser()
        }
        a++
    },[])
    const fetchUser = () =>{
        if(cookies.user != undefined && cookies.user != null && cookies.user != ""){
            if(cookies.user.emailverified == "" || cookies.user.emailverified == null){
                window.location.href = "/verify-otp"
            }
            else{
                setLoading(false)
            }
        }
        else{
            window.location.href = "/"
        }
    }
    if(loading) {
        return(
            <div className='container mainloading'>
                <Loading />
            </div>
        )
    }
    else if(error){
        return(
            <ErrorPg />
        )
    }else
  return (
    <React.Fragment>
        <Menu />
        <section className='dashboard'>
            <div className='container maxwidth'>
                <div className='w90 float-center dashboxes'>
                    <div className='w25 float-left dashcardbox'>
                        <a href='/my-account' title=''>
                            <div className='w90 float-center dashcard'>
                                <div className='w90 float-center'>
                                    <div className='container dashcardname'>
                                        <p>My Account</p>
                                    </div>
                                    <div className='container dashcardsubtxt'>
                                        <p>Visit my account to get and update your account details</p>
                                    </div>
                                    <div className='container dashicon'>
                                        <div className='iconsidedash'>
                                            <i className="fa-solid fa-user"></i>
                                        </div>
                                        <div className='gobutton'>
                                            <i className="fa-solid fa-chevron-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className='w25 float-left dashcardbox'>
                        <a href='/orders' title=''>
                            <div className='w90 float-center dashcard'>
                                <div className='w90 float-center'>
                                    <div className='container dashcardname'>
                                        <p>My Orders</p>
                                    </div>
                                    <div className='container dashcardsubtxt'>
                                        <p>Visit my orders to get order details</p>
                                    </div>
                                    <div className='container dashicon'>
                                        <div className='iconsidedash'>
                                            <i className="fa-solid fa-cart-arrow-down"></i>
                                        </div>
                                        <div className='gobutton'>
                                            <i className="fa-solid fa-chevron-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className='w25 float-left dashcardbox'>
                        <a href='/wishlist' title=''>
                            <div className='w90 float-center dashcard'>
                                <div className='w90 float-center'>
                                    <div className='container dashcardname'>
                                        <p>My Wishlist</p>
                                    </div>
                                    <div className='container dashcardsubtxt'>
                                        <p>Visit my wishlist to check products you have wishlisted</p>
                                    </div>
                                    <div className='container dashicon'>
                                        <div className='iconsidedash'>
                                            <i className="fa-solid fa-heart"></i>
                                        </div>
                                        <div className='gobutton'>
                                            <i className="fa-solid fa-chevron-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className='w25 float-left dashcardbox'>
                        <a href='/manage-address' title=''>
                            <div className='w90 float-center dashcard'>
                                <div className='w90 float-center'>
                                    <div className='container dashcardname'>
                                        <p>Manage Address</p>
                                    </div>
                                    <div className='container dashcardsubtxt'>
                                        <p>Visit manage address to check saved addresses or update an address</p>
                                    </div>
                                    <div className='container dashicon'>
                                        <div className='iconsidedash'>
                                            <i className="fa-solid fa-house"></i>
                                        </div>
                                        <div className='gobutton'>
                                            <i className="fa-solid fa-chevron-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <Footer white="true" />
    </React.Fragment>
  )
}
