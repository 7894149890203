import React, { useEffect, useRef, useState } from 'react'
import Loading from '../Loading';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { useCookies,Cookies } from 'react-cookie'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Menu from '../MenuBar';
import Footer from '../Footer';
import ErrorPg from '../Error';
import { FiAlertTriangle, FiX } from 'react-icons/fi';
import LoadingIndicator from 'react-loading-indicator';
import WriteReview from '../reviews/WriteReview';


export default function ProductPage() {
    function naming(name,slug){
        var newSlug
        if(slug == "" || slug == null){
            newSlug = name.replace(/ /g, "-")
            newSlug = newSlug.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
        }else{
            newSlug = slug.replace(/ /g, "-")
            newSlug = newSlug.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
        }
        return newSlug.toLowerCase()
    }
    var a = 0;
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [errMsg,setErrMsg] = useState("")
    const [productData,setProductData] = useState("")
    const [minf,setMinf] = useState("")
    const [actImg,setActImg] = useState(0)
    const swiperRef = useRef()
    const intRef = useRef()
    const opRef  = useRef()
    const useQuery = () =>{
       return new URLSearchParams(useLocation().search)
    }
    const [cookies,setCookie,removeCookie] = useCookies('')
    const query = useQuery()
    const pid = query.get("p_id")
    const bid = query.get("b_id")
    const mid = query.get("m_id")

    const handleScroll = () =>{
        if(screen.width > 900){
            if(intRef.current.offsetTop < $(window).scrollTop()){
                opRef.current.style.opacity = 1
                opRef.current.style.bottom = 0
            }
            else{
                opRef.current.style.opacity = 0
                opRef.current.style.bottom = "-100%"
            }
        }
    }
    useEffect(() =>{
        window.addEventListener("scroll",handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    },[handleScroll])

    useEffect (() =>{
        setLoading(true)
        if(a == 0){
            setCookie('callback',('/'),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
            fetchProductData()
            checkcart()
        }
        a++;
    },[bid,mid])

    const fetchProductData = () =>{
        axios.get(apiurl + "api/singleproduct?p_id=" + pid + "&m_id=" + mid + "&b_id=" + bid + "&inf=" + minf)
        .then((res) =>{
            console.log(res.data)
            setProductData(res.data)
            checkWishlist(pid,bid,mid)
            fetchReviews()
        },(error) =>{
            setErrMsg(error.message)
            setError(true)
        })
    }
    const [popDisplay,setPopDisplay] = useState(false);
    const [revpop,setRevpop] = useState("")
    const [revpopimg,setRevpopimg] = useState("")
    const [idact,setIdact] = useState(0)
    let nav;
    const handlePop = (x,k) =>{
      setRevpop(x);
      setRevpopimg(x.images[k])
      setIdact(k)
      setPopDisplay(true)
  }
  const handleImage = (e) =>{
      let src = e.target.src
      let index = e.target.alt
      setRevpopimg(src)
      setIdact(index)
  }
  const [existing,setExisting] = useState(false);
  
    const [reviews,setReviews] = useState("")
    const fetchReviews = () =>{
        axios.get(apiurl + "api/reviews?p_id=" + pid + "&m_id=" + mid + "&b_id=" + bid + "&page=1&u_id=" )
        .then((res) =>{
            setReviews(res.data)
            setLoading(false)
        },(error) =>{
            setErrMsg(error.message)
            setError(true)
            setLoading(false)
        })
    }
    const [addedToCart,setAddedToCart] = useState(false)
    const checkcart = () =>{
        if(cookies.cartItem != undefined && cookies.cartItem != null && cookies.cartItem !=""){
            for(let i=0;i<cookies.cartItem.length;i++){
                if((cookies.cartItem[i]["pid"] == pid) && (cookies.cartItem[i]["mid"] == mid)  && (cookies.cartItem[i]["bid"] == bid)){
                    setAddedToCart(true)
                }else{
                    setAddedToCart(false)
                }
            }
        }else{
            setAddedToCart(false)
        }
    }
    const imgOptions = {
        slidesPerView : 1,
        spaceBetween:10,
        loop:true,
        pagination :{
          clickable: true,
        },
        autoHeight: true,
        breakpoints:{
            900: {
                allowTouchMove: true,
                allowMouseMove: true,
                navigation :true,
                pagination :true,
            },
            950: {
                allowTouchMove: false,
                allowMouseMove: false,
                navigation :false,
                pagination :false,
            }
        },
        modules : [Pagination, Navigation]
    }
    const gotoSlide = (i) =>{
        setActImg(i)
        swiperRef.current?.swiper.slideTo(i)
    }
    const addtoCart = (x) =>{
        let cart ={
          pid:pid,
          bid:bid,
          mid:mid,
          quantity:1,
          inf:minf 
        }
        let cartArray = []
        let item = cookies.cartItem
        if((item != null) && (item != "")) {
          cartArray = item
        }
        cartArray.push(cart)
        setCookie('cartItem',JSON.stringify(cartArray),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
        
        if(x == "") {
            // window.location.reload() 
            setAddedToCart(true)
        }
        else{
            window.location.href = "/cart"
        }
    }

    const [wishlisted ,setWishlisted] = useState(false)
    const [displays,setDisplays] = useState(false)
    
  const checkWishlist = (pid,bid,mid) =>{
    let checkLog = cookies.user
    if(checkLog !=undefined && checkLog != null && checkLog != ""){
    let id = checkLog.id
        axios.post(apiurl + "api/checkWishlist",null,{
            params : {
                pid:pid,
                bid:bid,
                mid:mid,
                uid:id
            }
        }).then(res =>{
            if(res.data == 1){
            setWishlisted(true)
            }else{
            setWishlisted(false)
            }
        })
    }
    }
    const wishlist = (pid,bid,mid) =>{
        let checkLog = cookies.user
        if(checkLog !=undefined && checkLog != null && checkLog != ""){
          let id = checkLog.id
          axios.post(apiurl + "api/addwishlist",null,{
            params :{
              uid : id,
              pid : pid,
              bid : bid,
              mid : mid
            }
          })
          .then(res =>{
            if(wishlisted){
              setWishlisted(false)
            }else{
              setWishlisted(true)
            }
          })
          .catch(error =>{
            setError(error)
          })
        }else{
          setDisplays(true)
          setReviewing(false)
        }
    }

    
    const [canRev,setCanRev] = useState(true)
    const [reviewPop,setReviewPop] = useState(false)
    const [cantReviewPop,setCantReviewPop] = useState(false)
    const [showReview , setShowReview] = useState(false)
    const [reviewLoading , setReviewLoading] = useState(false)
    const [reviewing,setReviewing] = useState(false)

    
    const writeReview = () =>{
        setReviewLoading(true)
        let checkLog = cookies.user
        if(checkLog != null && checkLog != ""){
            canReview(checkLog.id)
        }else{
            setReviewLoading(false)
            setDisplays(true)
            setReviewing(true)
        }
    }
    const canReview = (x) =>{
        axios.post(apiurl + "api/canReview",null,{
        params :{
            usertoken : cookies.user.myToken,
            uid : x,
            pid : pid,
            mid: mid,
            bid : bid
        }
        })
        .then(res =>{
        if(res.data == 1){
            setReviewLoading(false)
            setCantReviewPop(false)
            setShowReview(true)
            setCanRev(true)
        }else{
            setCanRev(false)
            setReviewLoading(false)
            setCantReviewPop(true)
            setShowReview(false)
        }
        })
    }
    if(loading) {
        return(
            <div className='container mainloading'>
                <Loading />
            </div>
        )
    }
    else if(error){
        return(
            <ErrorPg />
        )
    }else
  return (
    <React.Fragment>
        <Menu />
        <section className='productpage'>
            <div className='my100 prodpagebox'>
                <div className='my100 prodtopbx'>
                    <div className='my100 maxwidth'>
                        <div className='my90 float-center prodwhitebox'>
                            <div className='my100 float-center prodmflex'>
                                <div className='my50 float-left prodimgside'>
                                    <div className='container prodimgsidem'>
                                        <div className='smallimgside'>
                                        {productData.imgarray.map((imgs,i) =>(
                                            <div className={`smallimage ${(actImg == i) ? "actimg" : ""}`} key={i} onClick={() => gotoSlide(i)}>
                                                <img src={imgs.img} alt='' referrerPolicy='no-referrer'/>
                                            </div>
                                        ))}
                                        </div>
                                        <div className='prodbiogimageside'>
                                            <Swiper className="productswiper" {...imgOptions} ref={swiperRef}>
                                                {productData.imgarray.map((imgs,i) =>(
                                                <SwiperSlide key={i} >
                                                    <div className='productimage'>
                                                        <span data-fancybox="gallery" data-src={imgs.img}>
                                                            <img src={imgs.img} alt='' referrerPolicy='no-referrer'/>
                                                        </span>
                                                    </div>
                                                </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </div>
                                        {wishlisted ? 
                                        
                                        <div className='wishlistprod wishlisted' onClick={() => wishlist(productData.mainproduct.price.p_id,productData.mainproduct.price.b_id,productData.mainproduct.price.m_id)}>
                                            <i className='fas fa-heart'></i>
                                            <p>Remove From Wishlist</p>
                                        </div>
                                        :
                                        <div className='wishlistprod' onClick={() => wishlist(productData.mainproduct.price.p_id,productData.mainproduct.price.b_id,productData.mainproduct.price.m_id)}>
                                            <i className='fas fa-heart'></i>
                                            <p>Add to Wishlist</p>
                                        </div>
                                        }
                                        <div className='clicktozoom'>
                                            <i className="fa-solid fa-expand"></i> <p>Click to Zoom</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='w50 float-left productdataside'>
                                    <div className='w90 float-center prodbxnty'>
                                        <div className='container productname'>
                                            <p>{productData.mainproduct.data.pname}</p>
                                        </div>
                                        <div className='prodratings container'>
                                            <div className='stars'>
                                                <i className='fas fa-star'></i><i className='fas fa-star'></i><i className='fas fa-star'></i><i className='fas fa-star'></i><i className='fas fa-star'></i>
                                            </div>
                                            <div className='startxt'>
                                                <p>({productData.ratings.overall} out of 5)</p>
                                            </div>
                                        </div>
                                        <div className='container productpgprice'>
                                            <div className='propgmainp'>
                                                <p><span>₹</span>{productData.mainproduct.price.famnt}</p>
                                            </div>
                                            <div className='progoldp'>
                                                <p><span>₹</span>{productData.mainproduct.price.price}</p>
                                            </div>
                                        </div>
                                        {(productData.alsoavailablein.length > 0) &&
                                        <div className='container variations'>
                                            <div className='container variationhead'>
                                                <p>{productData.category.brandheading}</p>
                                            </div>
                                            <div className='container variationlist'>
                                                <ul>
                                                {productData.alsoavailablein.map((vart , i) => (
                                                    <Link to={"/product/"+
                                                    naming(productData.mainproduct.data.pname,productData.mainproduct.price.slug)+"?p_id=" + 
                                                    productData.mainproduct.price.p_id +
                                                    "&m_id=" + vart.first + 
                                                    "&b_id=" + vart.id } title={vart.name}  key={i}>
                                                        <li className={(vart.id === productData.mainproduct.price.b_id) ? "active-b" : ""}>
                                                            {vart.name}
                                                        </li>
                                                    </Link>
                                                ))}
                                                </ul>
                                            </div>
                                        </div>
                                        }
                                        {(productData.modelList.length > 1) &&
                                        <div className='container variations'>
                                            <div className='container variationhead'>
                                                <p>{productData.category.modelheading}</p>
                                            </div>
                                            <div className='container variationlist'>
                                                <ul>
                                                {productData.modelList.map((vart , i) => (
                                                    <Link to={"/product/"+
                                                    naming(productData.mainproduct.data.pname,productData.mainproduct.price.slug)+"?p_id=" + 
                                                    productData.mainproduct.price.p_id +
                                                    "&m_id=" + vart.first + 
                                                    "&b_id=" + vart.id } title={vart.name}  key={i}>
                                                        <li className={(vart.id === productData.mainproduct.price.m_id) ? "active-b" : ""}>
                                                            {vart.name}
                                                        </li>
                                                    </Link>
                                                ))}
                                                </ul>
                                            </div>
                                        </div>
                                        }
                                        {(productData.bullets != null && productData.bullets != "") && <>
                                        <div className='container prodhighlights'>
                                            <ul>
                                                {productData.bullets.map((bullet,i) =>(
                                                    <li key={i}>{bullet}</li>
                                                ))}
                                            </ul>
                                        </div>
                                        </>}
                                        <div className='container cartbuttons' ref={intRef}>
                                            <button className='cartbuttonpg' onClick={() => (addedToCart) ?  window.location.href = "/cart":  addtoCart("") }>{addedToCart ? "Added to Cart" : "Add to Cart"}</button>
                                            <button className='buybuttonpg' onClick={() => addtoCart("buy")}>Buy Now</button>
                                        </div>
                                        <div className='compareline container' style={{display:"none"}}>
                                            <div className='compareside'>
                                                <ul>
                                                    <li>
                                                        <i className="fa-solid fa-shuffle"></i> Compare
                                                    </li>
                                                    <li>
                                                        <i className="far fa-heart"></i> Add to Wishlist
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='shareside'>
                                                <ul>
                                                    <li>Share </li>
                                                    <li>
                                                        <a href={'https://www.facebook.com/sharer/sharer.php?u='+window.location.href} title='Share on Facebook' target='_blank'>
                                                            <i className='fab fa-facebook-f'></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={'mailto:?subject=Check This '+window.location.href} title='Share on Mail' target='_blank'>
                                                            <i className='fas fa-envelope'></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={'https://www.linkedin.com/sharing/share-offsite/?url='+window.location.href} title='Share on Linkedin' target='_blank'>
                                                            <i className='fab fa-linkedin'></i>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={'https://api.whatsapp.com/send?text='+window.location.href} title='Share on Whatsapp' target='_blank'>
                                                            <i className='fab fa-whatsapp'></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='container pointstfour'>
                                            {productData.iconsarray.map((icons ,i ) =>(
                                            <div className='w33 float-left pointboxppg' key={i}>
                                                <div className='w90 float-center'>
                                                    <div className='container pointpgtop'>
                                                        <div className='pointpgicon'>
                                                            <img src={icons.img} alt={icons.name} referrerPolicy='no-referrer'/>
                                                        </div>
                                                        <div className='pointpgtext'>
                                                            <p>{icons.name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            ))}
                                        </div>
                                        <div className='container paymentmethos'>
                                            <p>Payment Methods - </p>
                                            <img src={prodPay} alt='Payment Methods' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='my100 prodbottombox'>
                    <div className='my100 maxwidth'>
                        <div className='my90 float-center prodloweflx'>
                            <div className='w60 float-left specsside'>
                                <div className='w95 float-center'>
                                    <div className='container descboxtitle'>
                                        <p>Description</p>
                                    </div>
                                    <div className='container descbox ck-content' dangerouslySetInnerHTML={{__html : productData.mainproduct.data.description}}>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className='my40 float-left'>
                                <div className='w95 float-right specsbox'>
                                    <div className='w90 float-center'>
                                        <div className='container descboxtitle'>
                                            <p>Specifications</p>
                                        </div>
                                        <div className='container descbox ck-content'>
                                            {productData.mainproduct.highlights.map((specs,index) => (
                                            (specs.value.length > 0) &&
                                            <div key={index} className='desctabbox'>
                                                <div className='container float-center spectitle'>  
                                                    <p>{specs.name}</p>
                                                </div>
                                                <div className='container specstable'>
                                                    <table>
                                                        <tbody>
                                                        {specs.value.map((specv,i) => (
                                                            <tr  key={i}>
                                                            <td>{specv.name}</td>
                                                            <td>{specv.value}</td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container reviews'>
                    <div className='container maxwidth'>
                        <div className='w90 float-center revbox'>
                            <div className='w95 float-center revdata'>
                                <div className='container reviewtitle'>
                                    <p>Customer Reviews</p>
                                </div>
                                <div className='container revsbox'>
                                    <div className='w50 float-left totalrevsside'>
                                        <div className='container startstopbx center-text'>
                                            <div className='container revstartsbig float-left'>
                                                <p>{productData.ratings.overall}</p>
                                            </div>
                                            <div className='container revtopstar float-left'>
                                                <i className='fas fa-star'></i><i className='fas fa-star'></i><i className='fas fa-star'></i><i className='fas fa-star'></i><i className='fas fa-star'></i>
                                            </div>
                                            <div className='container overallsub float-left'>
                                                <p>{productData.ratings.overallcount} Reviews</p>
                                            </div>
                                        </div>
                                        <div className='reviewsbif container'>
                                            <div className='reviewbifline container'>
                                                <div className='revibfstars'>
                                                    <i className='fas fa-star'></i><i className='fas fa-star'></i><i className='fas fa-star'></i><i className='fas fa-star'></i><i className='fas fa-star'></i>
                                                </div>
                                                <div className='revibifline'>
                                                    <div className='revperct' style={{width: productData.ratings.fiveperc+"%"}}></div>
                                                </div>
                                                <div className='revbifct'>
                                                    <p>{productData.ratings.five}</p>
                                                </div>
                                            </div>
                                            <div className='reviewbifline container'>
                                                <div className='revibfstars'>
                                                    <i className='fas fa-star'></i><i className='fas fa-star'></i><i className='fas fa-star'></i><i className='fas fa-star'></i><i className='far fa-star empstart'></i>
                                                </div>
                                                <div className='revibifline'>
                                                    <div className='revperct' style={{width: productData.ratings.fourperc+"%"}}></div>
                                                </div>
                                                <div className='revbifct'>
                                                    <p>{productData.ratings.four}</p>
                                                </div>
                                            </div>
                                            <div className='reviewbifline container'>
                                                <div className='revibfstars'>
                                                    <i className='fas fa-star'></i><i className='fas fa-star'></i><i className='fas fa-star'></i><i className='far fa-star empstart'></i><i className='far fa-star empstart'></i>
                                                </div>
                                                <div className='revibifline'>
                                                    <div className='revperct' style={{width: productData.ratings.threeperc+"%"}}></div>
                                                </div>
                                                <div className='revbifct'>
                                                    <p>{productData.ratings.three}</p>
                                                </div>
                                            </div>
                                            <div className='reviewbifline container'>
                                                <div className='revibfstars'>
                                                    <i className='fas fa-star'></i><i className='fas fa-star'></i><i className='far fa-star empstart'></i><i className='far fa-star empstart'></i><i className='far fa-star empstart'></i>
                                                </div>
                                                <div className='revibifline'>
                                                    <div className='revperct' style={{width: productData.ratings.twoperc+"%"}}></div>
                                                </div>
                                                <div className='revbifct'>
                                                    <p>{productData.ratings.two}</p>
                                                </div>
                                            </div>
                                            <div className='reviewbifline container'>
                                                <div className='revibfstars'>
                                                    <i className='fas fa-star'></i><i className='far fa-star empstart'></i><i className='far fa-star empstart'></i><i className='far fa-star empstart'></i><i className='far fa-star empstart'></i>
                                                </div>
                                                <div className='revibifline'>
                                                    <div className='revperct' style={{width: productData.ratings.oneperc+"%"}}></div>
                                                </div>
                                                <div className='revbifct'>
                                                    <p>{productData.ratings.one}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w50 writerevside float-left'>
                                        <div className='w90 float-right writerevboxtxt'>
                                            <div className='addrevtitle container center-text'>
                                                <p>ADD A REVIEW</p>
                                            </div>
                                            <div className='container norevies'>
                                                <div className='container reviewimage'>
                                                    <img src={revImg} alt='Add a Review' />
                                                </div>
                                                <div className='container writerevbut'>
                                                    <button onClick={writeReview}><i className="fa-regular fa-pen-to-square"></i> Write a Review</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {(reviews != null && reviews != "") &&
                                <div className='container reviewslist'>
                                    <div className='container reviewslisttitle'>
                                        <p>{productData.ratings.overallcount} REVIEWS FOR {productData.mainproduct.data.pname} </p>
                                    </div>
                                    <div className='container revieslistflx'>
                                        {reviews.map((rev,i) =>(
                                        <div className='w50 float-left' key={i}>
                                            <div className='w98 float-center reviewed'>
                                                <div className='w90 float-center'>
                                                    <div className='container reviewby'>
                                                        <div className='reviewname'>
                                                            <p>{rev.name}</p>
                                                        </div>
                                                        <div className='reviewdate'>
                                                            <p>{rev.date}</p>
                                                        </div>
                                                    </div>
                                                    <div className='container revedstars revibfstars'>
                                                        {[...Array(rev.rating)].map((i,index) => {
                                                        return (
                                                            <i className='fas fa-star' key={index}></i>
                                                        )})}
                                                    </div>
                                                    <div className='container reviewtxt'>
                                                        <p>
                                                            {rev.review}
                                                        </p>
                                                    </div>
                                                    {(rev.images != null && rev.images.length > 0) &&
                                                    <div className='container revimages'>
                                                        {rev.images.map((rimg, k) =>(
                                                            <img src={rimg} alt="Review Image" key={k} onClick={() => handlePop(rev,k)} referrerPolicy='no-referrer'/>
                                                        ))}
                                                    </div>
                                                    }
                                                    <div className='container reviewreaction'>
                                                        <div className='revlike revlike'>
                                                            <i className='fas fa-thumbs-up'></i> {rev.likes}
                                                        </div>
                                                        <div className='revdislike revlike'>
                                                            <i className='fas fa-thumbs-down'></i> {rev.dislikes}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        ))}
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='lastcta'>
            <div className='container maxwidth'>
                <div className='w40 float-center lastctabox center-text'>
                    <div className='lastctatop container'>
                        <p>Get Expert Support.</p>
                    </div>                    
                    <div className='container lastctatitle'>
                        <p>
                            Need Help? <br/>Talk To Our Experts Now !!
                        </p>
                    </div>
                    <div className='container lastctabut'>
                        <button>Contact Us</button>
                    </div>                 
                </div>
            </div>
        </section>
        <Footer />
        
        {reviewLoading &&
        <div className='delete-pop success-pop container' style={{display : (reviewLoading) ? "block" : "none"}}>
          <div className='container del-pop'>
              <div className='w30 float-center poptextbox center-text'>
                  <div className='w90 float-center'>
                      <div className='poploading'>
                        <LoadingIndicator segmentWidth={4} segmentLength={9}/> 
                      </div>
                  </div>
              </div>
          </div>
        </div>
        }
        {(cantReviewPop) ?
        <div className='delete-pop success-pop container' style={{display : (cantReviewPop) ? "block" : "none"}}>
          <div className='container del-pop'>
              <div className='w30 float-center poptextbox center-text'>
                  <div className='w90 float-center'>
                      <div className='container iconalert'>
                          <FiAlertTriangle />
                      </div>
                      <div className='icontext container'>
                          <p>You can't review this product its either because you have already reviewed it or some other reason</p>
                      </div>
                      <div className='container gobackbut logoutButton'>
                          <button onClick={() =>{setCantReviewPop(false)}}>Okay</button>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        :
        <WriteReview prod={productData} show={showReview} hide={setShowReview} />
        }
        {(revpop != "") &&
        <div className="review-pop container" style={{display : (popDisplay) ? "block" : "none"}}>
            <div className="reviewbox container maxwidth">
                <div className="w70 float-center review-popbox">
                    <div className="w60 float-left revimage-side">
                        <div className="revimagepop">
                            <img src={revpopimg} alt="review image" />
                        </div>
                        <div className="container img-thumbs">
                            <div className="w90 float-center">
                                <ul>
                                    {revpop.images.map((popimg ,index) =>(
                                    <li key={index}>
                                        <div className="rev-img">
                                            <img src={popimg} alt={index} id={idact} className={(idact == index) ? "activeimg" : ""} onClick={handleImage}/>
                                        </div>
                                    </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="w40 float-left review-side">
                        <div className="w98 float-center">
                            <div className='container revbox'>
                                <div className='w98 float-center reviewed'>
                                    <div className='w90 float-center'>
                                        <div className='container reviewby'>
                                            <div className='reviewname'>
                                                <p>{revpop.name}</p>
                                            </div>
                                            <div className='reviewdate'>
                                                <p>{revpop.date}</p>
                                            </div>
                                        </div>
                                        <div className='container revedstars revibfstars'>
                                            {[...Array(revpop.rating)].map((i,index) => {
                                            return (
                                                <i className='fas fa-star' key={index}></i>
                                            )})}
                                        </div>
                                        <div className='container reviewtxt'>
                                            <p>
                                                {revpop.review}
                                            </p>
                                        </div>
                                        <div className='container reviewreaction'>
                                            <div className='revlike revlike'>
                                                <i className='fas fa-thumbs-up'></i> {revpop.likes}
                                            </div>
                                            <div className='revdislike revlike'>
                                                <i className='fas fa-thumbs-down'></i> {revpop.dislikes}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {revpop.reply.map(rep => (
                                <div className='container' key={rep.id}>
                                    <div className='w98 float-center seller-resp'>
                                        <div className='w90 float-center'>
                                        <div className='container merchresp'>
                                            <p>{rep.name}</p>
                                            <p className='resp-date'>{rep.date}</p>
                                        </div>
                                        <div className='container respmessage'>
                                            <p>{rep.review}</p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="close-pop" onClick={() => setPopDisplay(false)}>
                        <p><FiX/></p>
                    </div>
                </div>
            </div>
        </div>
        }
        <div className='delete-pop container' style={{display : (displays) ? "block" : "none"}}>
            <div className='container del-pop'>
                <div className='popmainbox center-text'>
                    <div className='w90 float-center'>
                        <div className='w90 float-center'>
                            <div className='container center-text popheadbox'>
                                <div className='popheading container'>
                                    <p>please login to continue</p>
                                </div>
                                <div className='popsubtext container'>
                                    <p>Lorem ipsum is a placeholder text commonly used to demonstrate the visual form</p>
                                </div>
                                <div className='poplogbuttons container'>
                                    <div className='poploginbutton container float-left'>
                                        <div className='w80 float-center'>
                                            <a href='/user-login' title='Log In'>Log In</a>
                                        </div>
                                    </div>
                                    <div className='popor  container float-left'>
                                        <p>OR</p>
                                    </div>
                                    <div className=' container float-left popsignup'>
                                        <a href='/signup' title='Create Account'>Create Account</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='closepop' onClick={() => setDisplays(false)}>
                            <FiX />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {(screen.width > 900) &&
        <div className='fixedprod container' ref={opRef}>
            <div className='container maxwidth'>
                <div className='w90 float-center fxprodflx'>
                    <div className='fxprodpside'>
                        <div className='fxprodimage'>
                            <img src={asseturl + productData.mainproduct.price.img1} alt='' referrerPolicy='no-referrer'/>
                        </div>
                        <div className='fxprodtitle'>
                            <p>
                                {productData.mainproduct.data.pname}
                            </p>
                        </div>
                    </div>
                    <div className='fxprodbutside'>
                        <div className='container cartbuttons'>
                            <button className='cartbuttonpg' onClick={() => (addedToCart) ?  window.location.href = "/cart":  addtoCart("") }>{addedToCart ? "Added to Cart" : "Add to Cart"}</button>
                            <button className='buybuttonpg' onClick={() => addtoCart("buy")}>Buy Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
    </React.Fragment>
  )
}
