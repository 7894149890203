import React from 'react'
import LoadingIndicator from 'react-loading-indicator'

export default function Loading() {
  return (
    <div className='loadingbox'>
        <LoadingIndicator segmentWidth={4} segmentLength={9}/>
    </div>
  )
}
