import React, { useEffect, useState } from 'react'
import Menu from '../MenuBar'
import Footer from '../Footer'
import Loading from '../Loading'
import ErrorPg from '../Error'
import { Link, useLocation, useParams } from 'react-router-dom'
import axios from 'axios'

export default function CategoryStep1() {
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [errMsg,setErrMsg] = useState("")
    const [subCats,setSubCats] = useState("")
    const [mainCats,setMainCats] = useState("")
    const [mCatDetails,setMcatDetails] = useState("")
    const [subCatLoading,setSubCatLoading] = useState(true)
    const useQuery = () =>{
        return new URLSearchParams(useLocation().search)
    }
    const params = useParams()
    const catID = params.id
    var a = 0
    const location = useLocation()
    useEffect(() =>{
        if(a == 0){
            fetchCategories()
        }
        a++
    },[])
    const fetchCategories = () =>{
        axios.get(apiurl + "api/caregories/0",null,null)
        .then((res) =>{
            var a = res.data.filter(x => x.id == catID)
            setMcatDetails(a[0].name)
            setMainCats(res.data)
            fetchSubCategories(catID)
            setLoading(false)
        },(error) =>{
            console.log(error.message)
        })
    }
    useEffect(() =>{
        if(a == 0){
            fetchSubCategories(catID)
        }
        a++
    },[catID])
    const fetchSubCategories = (id) =>{
        setSubCatLoading(true)
        axios.get(apiurl + "api/caregoriesWithSub/"+id,null,null)
        .then((res) =>{
            if(res.data.status == "fail"){
                setSubCats("")
                setSubCatLoading(false)
            }else{
                setSubCats(res.data)
                setSubCatLoading(false)
            }
        },(error) =>{
            console.log(error.message)
        })
    }
    if(loading) {
        return(
            <div className='container mainloading'>
                <Loading />
            </div>
        )
    }
    else if(error){
        return(
            <ErrorPg />
        )
    }else
  return (
    <React.Fragment>
        <Menu />
            <section className='categorypage'>
                <div className='my100 maxwidth'>
                    <div className='my90 float-center catgpgflex'>
                        <div className='my30 float-left maincatside'>
                            {(mainCats != null && mainCats != "") &&
                            <div className='w90 float-center maincatslist'>
                                <div className='w90 float-center'>
                                    <div className='container catlisttitle'>
                                        <p>Categories</p>
                                    </div>
                                    <div className='container catslistpg'>
                                        <ul>
                                            {mainCats.map((cat ,i ) =>(
                                            <Link to={"/c/"+cat.id+"/"+cat.slug} title={cat.name} key={i} onClick={() => {setMcatDetails(cat.name)}}>
                                                <li className={(catID == cat.id) ? "actlcat" : ""}>
                                                    {cat.name}
                                                </li>
                                            </Link>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        <div className='w70 float-left subcatsside'>
                            <div className='w90 float-center subcatslist'>
                                <div className='container catlisttitle catslside'>
                                    <div className='w98 float-center'>
                                        <p>Available Categories {(mCatDetails != "") && "in "+mCatDetails}</p>
                                    </div>
                                </div>
                                <div className='container subcatlists'>
                                    {subCatLoading ? 
                                    <div className='container subcatloadingpg'>
                                        <Loading />
                                    </div>
                                    :
                                    (subCats != null && subCats != "") &&
                                    <>
                                    {subCats.map((subcat,i) =>(
                                        subcat.category2.length > 0 ?
                                    <div className='container subcatmainbox' key={i}>
                                        <div className='container subcattitle'>
                                            <p>{subcat.name}</p>
                                        </div>
                                        <div className='container subcatsmlist'>
                                            {subcat.category2.map((cat2, i) =>(
                                                <div className='w20 float-left subcatbx' key={i}>
                                                    <a href={(cat2.next == 0) ? "/c/"+cat2.id+"/"+cat2.slug+"/products" : "/c/"+catID+"/"+subcat.id+"/"+cat2.id+"/"+cat2.slug} title={cat2.name}>
                                                        <div className='w90 float-center dropsubcat center-text'>
                                                            <div className='container dropsubsimg'>
                                                                {(cat2.img != null && cat2.img != "") ? 
                                                                <div className='dropssubimg'>
                                                                    <img src={asseturl + cat2.img} alt={cat2.name} referrerPolicy='no-referrer'/>
                                                                </div>
                                                                :
                                                                <div className='dropssubimg dropsunotav'>
                                                                    <img src={logo} alt={cat2.name} referrerPolicy='no-referrer'/>
                                                                </div>
                                                                }
                                                            </div>
                                                            <div className='container dropsubname'>
                                                                <div className='w95 float-center'>
                                                                    <p>{cat2.name}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    :
                                    <div className='w20 float-left subcatbx' key={i}>
                                        <a href={(subcat.next == 0) ? "/c/"+subcat.id+"/"+subcat.slug+"/products" : "/c/"+catID+"/"+subcat.id+"/"+subcat.id+"/"+subcat.slug} title={subcat.name}>
                                            <div className='w90 float-center dropsubcat center-text'>
                                                <div className='container dropsubsimg'>
                                                    {(subcat.img != null) ? 
                                                    <div className='dropssubimg'>
                                                        <img src={asseturl + subcat.img} alt={subcat.name} referrerPolicy='no-referrer'/>
                                                    </div>
                                                    :
                                                    <div className='dropssubimg dropsunotav'>
                                                        <img src={logo} alt={subcat.name} referrerPolicy='no-referrer'/>
                                                    </div>
                                                    }
                                                </div>
                                                <div className='container dropsubname'>
                                                    <div className='w95 float-center'>
                                                        <p>{subcat.name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    ))}
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <Footer />
    </React.Fragment>
  )
}
