import React from 'react'
import { useCookies,Cookies } from 'react-cookie';

export default function Sidebar(props) {
    const [cookies,setCookie,removeCookie] = useCookies('')
    const user = cookies.user
    const logOut = () =>{
        removeCookie('user',{path : "/"})
        removeCookie('userToken',{path : "/"})
        window.location.href = '/'
    }
  return (
    <div className='container sidebarbox'>
        <div className='w90 float-center sidebarmain'>
            <div className='container sidebarprofile'>
                <div className='w90 float-center'>
                    <div className='sidebardimg container'>
                        <div className='profile-image' style={{borderRadius : (user.img != null && user.img != "" ) ? "50%" : "0"}}>
                            <img src={(user.img != null && user.img != "" ) ? user.img : userDef} alt='' />
                        </div>
                    </div>
                    <div className='container profilename center-text'>
                        <p>{user.name}</p>
                    </div>
                </div>
            </div>
            <div className="container sidebarbottomside">
                <div className='container sidebartitle'>
                    <div className='w90 float-center'>
                        <p>Explore More</p>
                    </div>
                </div>
                <div className='container sidebarlist'>
                    <ul>
                        <a href='/my-account' title='My Account' className={(props.act == "1") ? "acttab" : ""}>
                            <div className='w90 float-center'>
                                <li>My Account</li>
                            </div>
                        </a>
                        <a href='/orders' title='My Orders' className={(props.act == "2") ? "acttab" : ""}>
                            <div className='w90 float-center'>
                                <li>My Orders</li>
                            </div>
                        </a>
                        <a href='/wishlist' title='My Wishlist' className={(props.act == "3") ? "acttab" : ""}>
                            <div className='w90 float-center'>
                                <li>My Wishlist</li>
                            </div>
                        </a>
                        <a href='/manage-address' title='Manage Address' className={(props.act == "4") ? "acttab" : ""}>
                            <div className='w90 float-center'>
                                <li>Manage Address</li>
                            </div>
                        </a>
                        <li className='logbut'>
                            <div className='w90 float-center'>
                                <button className='logoutbutton' onClick={logOut}>Log Out <i className='fas fa-right-from-bracket'></i></button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}
