import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import Loading from '../Loading';
import Menu from '../MenuBar';
import Footer from '../Footer';
import ErrorPg from '../Error';
import { useParams } from 'react-router-dom';

export default function ModelsPage() { 
    var a = 0;
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [errMsg,setErrMsg] = useState("")
    const [models,setModels] = useState("")
    const [searchQuery,setSearchQuery] = useState("")
    const params = useParams()
    const brandID = params.id
    useEffect(() =>{
        if(a == 0){
            getModels()
        }
        a++
    },[])
    const getModels = () =>{
        axios.post(apiurl + "api/getBrands",{
            p_id : brandID 
        },null)
        .then((res) =>{
            console.log(res.data)
            setModels(res.data)
            setLoading(false)
        },(error) =>{
            setErrMsg(error.message)
            setError(true)
        })
    }
    const [noprods,setNoprods] = useState(false)
    const checkProducts = () =>{
        setTimeout(function(){
            var a = $('.modelcardbox').length
            if(a == 0){
                setNoprods(true)
            }else{
                setNoprods(false)
            }
        },100)
    }
    if(loading) {
        return(
            <div className='container mainloading'>
                <Loading />
            </div>
        )
    }
    else if(error){
        return(
            <ErrorPg />
        )
    }else
  return (
    <React.Fragment>
        <Menu />
            <section className='modelslist'>
                <div className='container maxwidth modelsl'>
                    <div className='w90 float-center modelslists'>
                        <div className='container brandstopbox'>
                            <div className='w95 float-center modeltopnty'>
                                <div className='container modelheading center-text'>
                                    <p>Select Model</p>
                                </div>
                                <div className='container seacrhmodel'>
                                    <div className='catsearchside'>
                                        <input type="text" placeholder='Search Model Name' name='search-manager' onChange={(e) => {setSearchQuery(e.target.value),checkProducts()}} />
                                        <i className='fas fa-magnifying-glass'></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(models != null && models != "") &&
                        <div className='container modelsmainlist'>
                            {models.filter(post =>{
                                if(searchQuery == ""){
                                    return post
                                }
                                else if(post.name.toLowerCase().includes(searchQuery.toLocaleLowerCase())){
                                    return post
                                }
                                }).map((model,i) =>(
                                <div className='w20 float-left modelcardbox' key={i}>
                                    <a href={'/brand/'+model.p_id+'/model/'+model.id} title={model.name}>
                                        <div className='w90 float-center modelcardmain'>
                                            <div className={`container modelimage ${(model.img != null && model.img != "") ? "" : "noimage"}`}>
                                                <div className='modelimage'>
                                                    <img src={(model.img != null && model.img != "") ? asseturl+model.img : logo} alt={model.name} referrerPolicy='no-referrer'/>
                                                </div>
                                            </div>
                                            <div className='container modeldatabx'>
                                                <div className='w90 float-center'>
                                                    <div className='container modelname'>
                                                        <p>{model.name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}   
                        </div>
                        }
                        {noprods && 
                        <div className='container noprods'>
                            <div className='w90 float-center'>
                                <div className='container nosearchimg'>
                                    <img src={noDataImg} alt='Nothing found' />
                                </div>
                                <div className='container notfoundtxt center-text'>
                                    <p>Oops! Couldn't Find Any Models</p>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </section>
        <Footer white="true"/>
    </React.Fragment>
  )
}
