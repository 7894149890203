import React, { useEffect, useState } from 'react'
import Footer from '../Footer'
import Menu from '../MenuBar'
import Sidebar from './Sidebar'
import ErrorPg from '../Error';
import Loading from '../Loading';
import axios from 'axios'
import { Cookies, useCookies } from 'react-cookie';
import {FiEdit, FiAlertTriangle} from 'react-icons/fi'
import {AiOutlinePlusCircle,AiOutlineClose} from 'react-icons/ai'
import {BiTrashAlt,BiHomeSmile,BiBuilding} from 'react-icons/bi'

export default function AddressAcc() {
    var a = 0;
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)
    const [errMsg,setErrMsg] = useState("")
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [address,setAddress] = useState("")
    const [displays,setDisplays] = useState(false)
    const [success,setSuccess] = useState(false)
    const [dels,setDels] = useState("")
    const User = cookies.user
    useEffect(() =>{
        if(a == 0){
            fetchUser()
        }
        a++
    },[])
    const fetchUser = () =>{
        if(cookies.user != undefined && cookies.user != null && cookies.user != ""){
            if(cookies.user.emailverified == "" || cookies.user.emailverified == null){
                window.location.href = "/verify-otp"
            }
            else{
                fetchAddress()
            }
        }
        else{
            window.location.href = "/"
        }
    }
    const fetchAddress = () =>{
        axios.post(apiurl+ "api/userAddress",{
          authToken : User.myToken
        },
        { 
          headers : {
            'Content-Type': 'application/json'
          }
        })
        .then(res =>{
          if(res.data.status=="OK"){
            setAddress(res.data.addressList)
          }
          setLoading(false)
        })
        .catch(error =>{
          setError(error)
        })    
    }
    const handleRem = (x) =>{
      setDisplays(true) 
      setDels(x)
    }
    const closeBox = () =>{
      setSuccess(false)
      setDisplays(false)
    }
    const remAddress = (x) =>{
      console.log(x)
      setLoading(true)
      axios.post(apiurl + "api/removeAddress",{
        uid : User.id,
        aid : x
      },{
        headers : {
          'Content-Type': 'application/json'
        }
      })
      .then(res =>{
        // console.log(res.data)
        fetchAddress()
        setSuccess(true)
      })
    }
    const [newName,setNewName] = useState("")
    const [ErrName,setErrName] = useState("")
    const [newPhone,setNewPhone] = useState("")
    const [ErrPhone,setErrPhone] = useState("")
    const [newEmail,setNewEmail] = useState("")
    const [ErrEmail,setErrEmail] = useState("")
    const [newaddress,setNewAddress] = useState("")
    const [ErrAddress,setErrAddress] = useState("")
    const [pincode,setPincode] = useState("")
    const [ErrPincode,setErrPincode] = useState("")
    const [city,setCity] = useState("")
    const [ErrCity,setErrCity] = useState("")
    const [state,setState] = useState("")
    const [ErrState,setErrState] = useState("")
    const [landmark,setLandmark] = useState("")
    const [addType,setAddType] = useState("Home")
    const [existingAddress,setExistingAddress] = useState([])
    const [addPop,setAddPop] = useState(false)
    const [edits,setEdits] = useState(false)
    const [addID,setAddId] = useState()
    
    function getNewName(val) {
      setNewName(val.target.value) 
      }
      function getNewPhone(val) {
          setNewPhone(val.target.value) 
      }
      function getNewEmail(val) {
          setNewEmail(val.target.value) 
      }
      function getAddress(val) {
        setNewAddress(val.target.value) 
      }
      function getPincode(val) {
          setPincode(val.target.value) 
      }
      function getCity(val) {
          setCity(val.target.value) 
      }
      function getState(val) {
          setState(val.target.value) 
      }
      function getLandmark(val) {
          setLandmark(val.target.value) 
      }
      function getAddType(val) {
          setAddType(val.target.value) 
      }
      // ************************add address*******************
      const addAddress = () =>{
          setNewName("")
          setNewAddress("")
          setCity("")
          setState("")
          setPincode("")
          setNewEmail("")
          setNewPhone("")
          setEdits(false)
          setAddPop(true)
      }
      const NewAddress = (e) =>{
          e.preventDefault();
      // perform all neccassary validations
      if(newName == ""){
          setErrName("Enter valid name")
      }
      else if((newPhone == "") || (newPhone.length < 10)) {
          setErrPhone("Enter valid phone number")
      }
      else if(/\S+@\S+\.\S+/.test(newEmail) == false){
          setErrEmail("Enter valid email")
      }
      else if(newEmail == ""){
          setErrEmail("Enter valid email")
      }
      else if(newaddress == ""){
          setErrAddress("Enter valid address")
      }
      else if(city == ""){
          setErrCity("Enter valid city")
      }
      else if(pincode == "" || pincode.length < 6){
          setErrPincode("Enter valid pincode")
      }
      else{
          setLoading(true)
          axios.post(apiurl + "api/addAddress",{
              authToken : User.myToken,
              uid : User.id,
              name : newName,
              email : newEmail,
              contact : newPhone,
              altcontact : "",
              address : newaddress,
              pincode : pincode,
              city: city,
              state : state,
              landmark : landmark,
              addressType : addType
          },
          {headers : {
              'Content-Type': 'application/json'
          }}
          )
          .then(res =>{
              if(res.data.status == "OK"){
                  console.log("Successfully added")
                  fetchAddress();
                  setLoading(false)
                  setAddPop(false)
              }else{
                  console.log("Error Occured")
              }
          }).catch(error =>{
              console.log(error.message)
              setLoading(false)
          })
      }
      }
      // ************************add address end*******************
      
      // ************************edit address*******************
      const editAdd = (id,name,address,city,state,pincode,email,contact) =>{
          setNewName(name)
          setNewAddress(address)
          setCity(city)
          setState(state)
          setPincode(pincode)
          setNewEmail(email)
          setNewPhone(contact)
          setEdits(true)
          setAddPop(true)
          setAddId(id)
      }
      const updateAddress = () =>{
          if(newName == ""){
              setErrName("Please Enter Name!!!")
          }else if(newEmail == ""){
              setErrEmail("Please Enter Email!!!")
          }else if(newPhone == ""){
              setErrPhone("Please Enter Contact!!!")
          }else if(address == ""){
              setErrAddress("Please Enter ADdress!!!")
          }else if(pincode == ""){
              setErrPincode("Please Enter Pincode!!!")
          }else if(city == ""){
              setErrCity("Please Enter City!!!")
          }else if(state == ""){
              setErrState("Please Enter State!!!")
          }else{
              setLoading(true)
              axios.post(apiurl+"api/updateAddress", {
                  authToken : User.myToken,
                  uid : User.id,
                  aid:addID,
                  name:newName,
                  email:newEmail,
                  contact:newPhone,
                  altcontact:"",
                  address:newaddress,
                  pincode:pincode,
                  city:city, 
                  state:state,
                  landmark:landmark,
                  addressType:addType,
              },
              {
                  headers: {
                      'Content-Type': 'application/json'
                  }
              })
              .then(res => {
                  if(res.data.status = "OK"){
                      alert("Address Updated Successfully")
                      fetchAddress();
                  }else{
                      alert("Something went wrong!, Please try again after sometime.")
                  }
              }).then(() => {
                  setAddPop(false)
              }).catch(error => {
                  console.log(error)  
                  alert("Something went wrong. Please try again after sometime.")
                  setLoading(false)
              })
          }
      }
      // ************************edit address end*******************
    if(loading) {
        return(
            <div className='container mainloading'>
                <Loading />
            </div>
        )
    }
    else if(error){
        return(
            <ErrorPg />
        )
    }else
  return (
    <React.Fragment>
        <Menu />
            <section className='myorders dashpage'>
                <div className='my100 maxwidth'>
                    <div className='my90 float-center dahflx'>
                        <div className='my30 float-left sidebarsidem'>
                            <Sidebar act="4"/>
                        </div>
                        <div className='w70 float-left'>
                            <div className='container float-center orderslistside'>
                                <div className='container dashtitle center-text'>
                                    <p>Manage Address</p>
                                </div>
                                <div className='container address-list accaddress'>
                                {(address == null || address == "") ?
                                    <div className='container no-orders'>
                                        <div className='w50 float-center'>
                                            <div className='container float-left'>
                                                <div className='noorders'>
                                                    <img src={noDataImg} alt="No Orders" />
                                                </div>
                                            </div>
                                            <div className='container float-left center-text'>
                                                <div className='w90 float-center'>
                                                    <div className="logoutButton container">
                                                        <p>We couldn't find any address here</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  
                                :
                                address.map((add,i) => (
                                    <div className='w50 float-left' key={i}>
                                        <div className='addressbox w95 float-center float-left'>
                                            <div className='w90 float-center'>
                                                <div className='container address-name'>
                                                    <p>{add.name}</p>
                                                </div>
                                                <div className='container addressfull'>
                                                    <p className='address-p'>
                                                        {add.address}
                                                    </p>
                                                    <p className='address-city'>
                                                        {add.city}
                                                    </p>
                                                    <p className='address-state'>
                                                        {add.state} - {add.pincode}
                                                    </p>
                                                    <p className='address-email'>
                                                        {add.email}
                                                    </p>
                                                    <p className='address-num'>
                                                        {add.contact}
                                                    </p>
                                                </div>
                                                <div className='container editbox'>
                                                    <div className='w50 float-left left-text'>
                                                        <button onClick={() => editAdd(add.id,add.name,add.address,add.city,add.state,add.pincode,add.email,add.contact)}> <FiEdit /> Edit</button>
                                                    </div>
                                                    <div className='w50 float-left deleteadd right-text'>
                                                        <button onClick={() => handleRem(add.id)}><BiTrashAlt /> Remove</button>
                                                    </div>
                                                </div>
                                                <p className='address-type'>{(add.type == "Home") ? <BiHomeSmile /> : <BiBuilding />} {add.type}</p>
                                            </div>
                                        </div>
                                    </div>
                                    ))
                                }   
                                </div>
                                <div className='container add-address'>
                                    <div className='w90 float-center logoutButton'>
                                        <button onClick={() => addAddress()}><AiOutlinePlusCircle /> Add Address</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <Footer white="true" />
        <div className='addpop container' style={{display : (addPop) ? "block" : "none"}}>
            <div className='close-addpop' onClick={() => setAddPop(false)}>
            <AiOutlineClose />
            </div>
            <div className='addpopbox container'>
                <div className='w40 float-center addformbox'>
                    <div className='w90 float-center create-address'>
                        <div className='container cart-heading'>
                            <div className='w95 float-center center-text'>
                                {(edits) ?
                                <p>Update Delivery Address</p>
                                : 
                                <p>Add a New Delivery Address</p>
                                }
                            </div>
                        </div>
                        <div className='container new-address-form'>
                            <div className='w95 float-center'>
                                <form onSubmit={(edits) ? updateAddress : NewAddress}>
                                    <div className='container infields'>
                                        <div className='w95 float-center'>
                                            <input
                                                type="text"
                                                required
                                                placeholder="Name"
                                                value={newName}
                                                onChange={getNewName}
                                                id="name"
                                                />
                                        </div>
                                        <div className='error-message container'>
                                            <p>{ErrName}</p>
                                        </div>
                                    </div>
                                    <div className='container infields'>
                                        <div className='w95 float-center'>
                                            <input
                                                type="number"
                                                required
                                                placeholder="Phone Number"
                                                value={newPhone}
                                                onChange={getNewPhone}
                                                
                                                />
                                        </div>
                                        <div className='error-message container'>
                                            <p>{ErrPhone}</p>
                                        </div>
                                    </div>
                                    <div className='container infields'>
                                        <div className='w95 float-center'>
                                            <input
                                                type="email"
                                                required
                                                placeholder="Email"
                                                value={newEmail}
                                                onChange={getNewEmail}
                                                
                                                />
                                        </div>
                                        <div className='error-message container'>
                                            <p>{ErrEmail}</p>
                                        </div>
                                    </div>
                                    <div className='container infields'>
                                        <div className='w95 float-center'>
                                            <input
                                                type="text"
                                                required
                                                placeholder="Address"
                                                value={newaddress}
                                                onChange={getAddress}
                                                
                                                />
                                        </div>
                                        <div className='error-message container'>
                                            <p>{ErrAddress}</p>
                                        </div>
                                    </div>
                                    <div className='w50 float-left infields'>
                                        <div className='w90 float-center'>
                                            <input
                                                type="text"
                                                required
                                                placeholder="City"
                                                value={city}
                                                onChange={getCity}
                                                
                                                />
                                        </div>
                                        <div className='error-message container'>
                                            <p>{ErrCity}</p>
                                        </div>
                                    </div>
                                    <div className='w50 float-left  infields'>
                                        <div className='w90 float-center'>
                                            <input
                                                type="text"
                                                required
                                                placeholder="State"
                                                value={state}
                                                onChange={getState}
                                                
                                                />
                                        </div>
                                        <div className='error-message container'>
                                            <p>{ErrState}</p>
                                        </div>
                                    </div>
                                    <div className='w50 float-left infields'>
                                        <div className='w90 float-center'>
                                            <input
                                                type="number"
                                                placeholder="Pincode"
                                                value={pincode}
                                                onChange={getPincode}
                                                required
                                                />
                                        </div>
                                        <div className='error-message container'>
                                            <p>{ErrPincode}</p>
                                        </div>
                                    </div>
                                    <div className='w50 float-left infields'>
                                        <div className='w90 float-center'>
                                            <input
                                                type="text"
                                                placeholder="Landmark"
                                                value={landmark}
                                                onChange={getLandmark}
                                                
                                                />
                                        </div>
                                    </div>
                                    <div className='container infields addresstypes'>
                                        <div className='w95 float-center'>
                                            <div className='container addt-head'>
                                                <p>Select an address type</p>
                                            </div>
                                            <div className='container typesofadd'>
                                                <input
                                                    type="radio"
                                                    placeholder="Address Type"
                                                    value="Home"
                                                    id="Home"
                                                    name='address-type'
                                                    onChange={getAddType}
                                                    checked ={addType === "Home"}
                                                    />
                                                <label htmlFor="Home"><BiHomeSmile /> Home</label>
                                                <input
                                                    type="radio"
                                                    placeholder="Address Type"
                                                    value="Office"
                                                    id="Office"
                                                    name='address-type'
                                                    onChange={getAddType}
                                                    checked ={addType === "Office"}
                                                    />
                                                <label htmlFor="Office"><BiBuilding /> Office</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='container deliverhere'>
                                        {(edits) ? 
                                            <button onClick={() => setAddPop(true)}> Update Address</button>
                                        :
                                            <button onClick={() => setAddPop(true)}> Add Address</button>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='delete-pop container successpop' style={{display : (displays) ? "block" : "none"}}>
            <div className='container del-pop'>
                <div className='w30 float-center poptextbox center-text'>
                {(success) ? 
                    <div className='w90 float-center'>
                    <div className='animation-wrapper container center-text'>
                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                        </svg>
                        <p>Address Removed Succesfully</p>
                    </div>
                    <div className='container gobackbut logoutButton'>
                        <button onClick={closeBox}>Okay</button>
                    </div>
                    </div>
                    :
                    <div className='w90 float-center'>
                        <div className='iconalert container center-text'>
                            <FiAlertTriangle />
                        </div>
                        <div className="icontext container">
                            <p>Are you sure you want to remove this address?</p>
                        </div>
                        <div className="container delbutts">
                            <div className="w50 float-left">
                                <div className="w90 float-center">
                                    <button className="cancelbutton" onClick={() => setDisplays(false)}>No, Cancel</button>
                                </div>
                            </div>
                            <div className="w50 float-left">
                                <div className="w90 float-center">
                                    <button className="delbutton" onClick={() => remAddress(dels)}>Yes, Remove</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}
