import React,{useState ,useEffect} from 'react'
import loginImg from './sidelog.png'
import {AiFillEye,AiFillEyeInvisible,AiFillFacebook} from 'react-icons/ai'
import { Link,useNavigate } from 'react-router-dom'
import {BiLockOpenAlt,BiUser} from 'react-icons/bi'
import {FcGoogle} from 'react-icons/fc'
import axios from 'axios'
import { useCookies,Cookies } from 'react-cookie'
// import GoogleLogin from 'react-google-login'
// import baseUrl from '../baseurl'
// import fbIcon from './ficon.png'
// import FacebookLogin from 'react-facebook-login';
import LoadingIndicator from 'react-loading-indicator'
import Loading from '../Loading'
import Footer from '../Footer'
import Menu from '../MenuBar'
import { GoogleLogin } from '@react-oauth/google';

export default function UserLogin() {
    const [hidden2,setHidden2] = useState(true)
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const[invalid,setInvalid] =useState()
    const [gsiScriptLoaded, setGsiScriptLoaded] = useState(false)
    const [cookies,setCookie,removeCookie] = useCookies('')
    const [loggedOut,setLoggedOut] = useState(false)
    const [showGoogleLogin,setShowGoogleLogin] = useState(false)
    let userLog = cookies.user
    useEffect(() => {
          if(userLog != "" && userLog != null){
              navigate("/",{replace : true})
          }
    },[])
    // *************Google Signup***********
    const handleCredentialResponse = (response) =>{
        const responsePayload = decodeJwtResponse(response.credential);
        console.log("ID: " + responsePayload.sub);
        console.log('Full Name: ' + responsePayload.name);
        console.log('Given Name: ' + responsePayload.given_name);
        console.log('Family Name: ' + responsePayload.family_name);
        console.log("Image URL: " + responsePayload.picture);
        console.log("Email: " + responsePayload.email);
        var logData = {
        "displayName" : responsePayload.name,
        "email" : responsePayload.email,
        "phoneNumber" : "",
        "photoURL" : responsePayload.url,
        "uid" : responsePayload.sub,
        "provider" : "Google"
        }
        socialLogin(logData)
        // googleLogs(responsePayload.sub,responsePayload.email,responsePayload.name)
    }
    function decodeJwtResponse (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }
    const socialLogin = (logData) =>{
        axios.post(apiurl + "api/userGoogleLogin",{
            loginData : JSON.stringify(logData)
        },{
            headers : {
                'Content-Type': 'application/json'
            }
        })
        .then((res) =>{
            if(res.data.newuser != 1){
                setCookie('user',JSON.stringify(res.data.user),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
                if(res.data.notverified != 0){
                    window.location.href = "/verify-otp"
                }else{
                    window.location.href = callback
                }
            }else{
                setCookie('tempUser',JSON.stringify(logData),{path : "/",maxAge: 30 })
                setIsLoading(true)
                window.location.href = "/signup"
            }
            setIsLoading(false)
        },(error) =>{
            console.log(error.message)
        })
    }
    // *************Google Signup end***********
    const Hidden2 = () =>{
        setHidden2(false)
    }
    const show2 = () =>{
      setHidden2(true)
     }
     const [email, setEmail] = useState("");
     const [password, setPassword] = useState("");
     function getEmail(val) {
         setEmail(val.target.value) 
     }
     function getPass(val) {
        setPassword(val.target.value) 
    }
    let callback
    if((typeof cookies.callback == "undefined") || (cookies.callback == "")){
        callback = "/"
    }else{
        callback = cookies.callback
    }
    const navigate = useNavigate()
    function logIn(e){
        e.preventDefault()
        setIsLoading(true)
        axios.post(apiurl + "api/userlogin",{
            email:email,
            password : password
        },{
            headers : {
                'Content-Type' : 'application/json'
            }
        })
        .then(
            (response) =>{
                if(response.data.status == "Failed"){
                    setInvalid(true)
                    setIsLoading(false)
                }else{
                    setCookie('user',JSON.stringify(response.data.user),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
                    window.location.href = callback
                    setIsLoading(true)
                }
            },
            (error) =>{
                setError(error)
            }
        )
    }
    const responseMessage = (response) => {
        handleCredentialResponse(response)
    };
    const errorMessage = (error) => {
        console.log(error);
    };
    if(isLoading)
    return(
        <div className='loadingbox signloading'>
            <div className="loadercircle container"><Loading /></div> 
        </div>
        )
    else if(error)
    return(
        <p>{error.message}</p>
    )
  return (
    <React.Fragment>
    <Menu />
    <section className='formbox'>
        <div className='container maxwidth'>
            <div className='container float-center sign-flex'>
                <div className='w50 float-center formwhitebg otp'>
                    <div className='container float-left  overflow-visible otp-layerlogin'>
                        <div className="container float-center otpbox flexboxlog">
                            <div className='container'>
                                <div className='container sign-up-text center-text'>
                                    {/* <div className='sign-e container'>
                                        <p>Login</p>
                                    </div> */}
                                    <div className='container signboxes'>
                                        <div className='signtopbox'>
                                            <div className='signtext actside'>
                                                <p>Log In</p>
                                            </div>
                                            <div className='signtext'>
                                                <Link to="/signup">Sign Up</Link>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='signup-p container'>
                                        <p>Enter your Email and Password in order to Login</p>
                                    </div> */}
                                </div>
                                <div className="container logform">
                                    <div className='w80 float-center'>
                                        <form autoComplete="off" onSubmit={logIn}>
                                            <div className='infields container'>
                                                <div className='container float-center fieldbox'>
                                                    <BiUser />
                                                    <input
                                                    type="email" 
                                                    placeholder="E-mail"
                                                    value={email}
                                                    onChange={getEmail}
                                                    required/>
                                                </div>
                                            </div>
                                            <div className='infields container'>
                                                <div className='container float-center fieldbox'>
                                                    <BiLockOpenAlt />
                                                    <input
                                                    type={(hidden2) ?  'password' : 'text'}
                                                    placeholder='Password' 
                                                    required
                                                    value={password}
                                                    id="password"
                                                    onChange={getPass}
                                                    />
                                                    <div className='show-hide'>
                                                        {(hidden2) ?  <AiFillEyeInvisible  onClick={Hidden2} /> : <AiFillEye onClick={show2}/>}
                                                    </div>
                                                </div>
                                            </div>
                                            {(invalid) &&
                                            <div className='container forgot-passowrd invalid center-text'>
                                                <p>Invalid Login Credentials</p>
                                            </div>
                                            }
                                            <div className='container forgot-passowrd right-text'>
                                                <Link to="/forgot-password" title="">Forgot Password ? </Link>
                                            </div>
                                            <div className='infields container center-text'>
                                                <button type='submit'>Sign In</button>
                                            </div>
                                            <div className='container forgot-passowrd newacc center-text'>
                                                <p>New to our platform? <Link to="/signup" title=""> Create Account </Link></p>
                                            </div>
                                            {showGoogleLogin && <>
                                            <div className='ortext container center-text'>
                                                <p>or</p>
                                            </div>
                                            <div className='social-signups container center-text'>
                                                <div className='container social-head'>
                                                    <p>Continue with your social network</p>
                                                </div>
                                                <div className='container g-button'>
                                                    <GoogleLogin onSuccess={responseMessage} theme='outline' shape='pill' type='icon' onError={errorMessage} useOneTap/>
                                                </div>
                                            </div>
                                            </>}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {loggedOut &&
        <div className='loogedoutnotif'>
            <p>Your were logged out!</p>
        </div>
    }
    <Footer />
    </React.Fragment>
  )
}
