import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useCookies,Cookies } from 'react-cookie'

export default function Footer(props) {
    const [loggedIn,setLoggedIn] = useState(false)
    const [cookies,setCookie,removeCookie] = useCookies('')
    const [helpLinks,setHelpLinks] = useState("")
    var a = 0;
    useEffect(() =>{
        if(cookies.user != undefined && cookies.user != null){
            setLoggedIn(true)
        }else{
            setLoggedIn(false)
        }
        if(a == 0){
            fetchTerms()
        }
        a++
    },[])

    const fetchTerms = () =>{
        axios.post(apiurl + "api/usefullinks")
        .then(res =>{
            setHelpLinks(res.data)
        })
        .catch(error =>{
            setError(error)
        })
    }
  return (
    <section className='footer' style={{background : (props.white != undefined && props.white != "") ? "#FFF" : "var(--lightbg)"}}>
        <div className='container maxwidth foottopbox'>
            <div className='w90 float-center'>
                <div className='w30 float-left'>
                    <div className='w90 float-center footbox'>
                        <div className='container footerlogo'>
                            <div className='footlogo'>
                                <img src={logo} alt='' />
                            </div>
                        </div>
                        <div className='container footpara'>
                            <p>
                            In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available
                            </p>
                        </div>
                    </div>
                </div>
                <div className='w20 float-left tofty'>
                    <div className='w90 float-center footbox'>
                        <div className='container foottitle'>
                            <p>Quick Links</p>
                        </div>
                        <div className='container footlinks'>
                            {loggedIn ? 
                            <ul>
                                <li>
                                    <a href='/' title=''>Home</a>
                                </li>
                                <li>
                                    <a href='/my-account' title='My Account'>My Account</a>
                                </li>
                                <li>
                                    <a href='/orders' title='My Orders'>My Orders</a>
                                </li>
                                <li>
                                    <a href='/wishlist' title='My Wishlist'>My Wishlist</a>
                                </li>
                                <li>
                                    <a href='/manage-address' title='Manage Address'>Manage Address</a>
                                </li>
                            </ul>
                            : 
                            <ul>
                                <li>
                                    <a href='/' title=''>Home</a>
                                </li>
                                <li>
                                    <a href='/user-login' title=''>Log In</a>
                                </li>
                                <li>
                                    <a href='/signup' title=''>Sign Up</a>
                                </li>
                            </ul>}
                        </div>
                    </div>
                </div>
                <div className='w25 float-left tofty'>
                    <div className='w90 float-center footbox'>
                        <div className='container foottitle'>
                            <p>Important Links</p>
                        </div>
                        <div className='container footlinks'>
                            {(helpLinks != null && helpLinks != "") &&
                            <ul>
                                {helpLinks.map((help,i) => (
                                <li key={i}>
                                    <a href={"/policies/" + help.slug} title={help.name}>{help.name}</a>
                                </li>
                                ))}
                            </ul>
                            }
                        </div>
                    </div>
                </div>
                <div className='w25 float-left'>
                    <div className='w90 float-center footbox'>
                        <div className='container foottitle'>
                            <p>Contact Us</p>
                        </div>
                        <div className='container footcontacts'>
                            <ul>
                                <li className='footconttitle'>Sales Enquiry</li>
                                <li>
                                    <a href='#' title=''><i className='fas fa-phone'></i> +9123456781</a>
                                </li>
                                <li>
                                    <a href='#' title=''><i className='fas fa-envelope'></i> sales@mobspare.com</a>
                                </li>
                                <li className='footconttitle foottwotitle'>General Enquiry</li>
                                <li>
                                    <a href='#' title=''><i className='fas fa-phone'></i>+9123456781</a>
                                </li>
                                <li>
                                    <a href='#' title=''><i className='fas fa-envelope'></i> general@mobspare.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='container footsocialsmbox'>
                    <div className='w98 float-center footsocialflex'>
                        <div className='footsocialside'>
                            <ul>
                                <a href="#" title="" target="_blank">
                                    <li>
                                        <i className="fab fa-facebook-f"></i>
                                    </li>
                                </a>
                                <a href="#" title="" target="_blank">
                                    <li>
                                        <i className="fab fa-instagram"></i>
                                    </li>
                                </a>
                                <a href="#" title="" target="_blank">
                                    <li>
                                        <i className="fab fa-twitter"></i>
                                    </li>
                                </a>
                                <a href="#" title="" target="_blank">
                                    <li>
                                        <i className="fab fa-linkedin"></i>
                                    </li>
                                </a>
                                <a href="#" title="" target="_blank">
                                    <li>
                                        <i className="fab fa-youtube"></i>
                                    </li>
                                </a>
                                <a href="#" title="" target="_blank">
                                    <li>
                                        <i className="fab fa-pinterest"></i>
                                    </li>
                                </a>
                                <a href="#" title="" target="_blank">
                                    <li>
                                        <i className="fab fa-whatsapp"></i>
                                    </li>
                                </a>
                            </ul>
                        </div>
                        <div className='footpayside'>
                            <img src={footPay} alt='' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container copyrighttext center-text"  style={{background : (props.white != undefined && props.white != "") ? "var(--lightbg)" : "#fff"}}>
            <div className="w90 float-center">
                <p>© All Right Reserved | Made with <span>❤</span> by <a href="https://website99.net/" title="" target="_blank">Website99 </a></p>
            </div>
        </div>
    </section>
  )
}
