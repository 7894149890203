import axios from 'axios'
import React,{useState,useEffect} from 'react'
import LoadingIndicator from 'react-loading-indicator'
import { Link ,useNavigate,useLocation} from 'react-router-dom'
import { Cookies, useCookies } from 'react-cookie';


export default function MenuSearch() {
    const [searchval,setSearchval] = useState("")
    const [searchProds,setSearchProds] = useState("")
    const [error,setError] = useState()
    const [searchLoading,setSearchLoading] = useState("")
    const [isDisabled,setIsDisabled] = useState(true)
    const [inputError,setInputerror] = useState(false)
    const [trending,setTrending] = useState("")
    const [showSearch,setShowSearch] = useState(false)
    const [showProducts,setShowProducts] = useState(true)
    const navigate = useNavigate()
    const location = useLocation()
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    let User = cookies.user;
    const newToken = cookies.userToken
    
    function naming(name,slug){
        var newSlug
        if(slug == "" || slug == null){
            newSlug = name.replace(/ /g, "-")
            newSlug = newSlug.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
        }else{
            newSlug = slug.replace(/ /g, "-")
            newSlug = newSlug.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
        }
        return newSlug.toLowerCase()
    }
    const setVal = (e) =>{
        setSearchval(e.target.value)
        if(e.target.value == "" || e.target.value == null){
            setIsDisabled(true)
            closeSearch()
        }else{
            setIsDisabled(false)
        }
    }
    const getSearch = (e) =>{
        setSearchLoading(true)
        setInputerror(false)
        if(searchval == ""){
            setShowProducts(true)
            setSearchLoading(false)
        }
        else{
            axios.post(apiurl + "api/searchingterms",null,{
                params :{
                    s : e.target.value
                }
            })
            .then(res =>{
                console.log(res.data)
                setSearchProds(res.data)
                setShowProducts(false)
                setShowSearch(true)
                setSearchLoading(false)
            }).catch(error =>{
                setError(error)
            })
        }
    }

    const getTrending = () =>{
        axios.post(apiurl + "api/trendingsearch")
        .then(res =>{
            setTrending(res.data)
            setShowSearch(true)
            setSearchLoading(false)
        }).catch(error =>{
            setError(error)
        })
    }
    const goToSearch = () =>{
        if(searchval == "" || searchval == null){
            
        }else{
            setIsDisabled(true)
            closeSearch()
        }
    }
    function searchName(name) {
        var newSlug
        newSlug = name.replace(/ /g, "+")
        newSlug = newSlug.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "+")
        return newSlug.toLowerCase()
    }
    const closeSearch = () =>{
        setInputerror(false)
        setSearchval("")
        setShowProducts(true)
        setShowSearch(false)
    }
    const trendClick = (x) =>{
        setInputerror(false)
        setSearchval("")
        setShowProducts(true)
        setShowSearch(false)
        navigate("/search/" + searchName(x) ,{state : {searchKey : x}})
        if(location.state !="" && location.state != null){
            window.location.reload()
        }
    }
  return (
    <React.Fragment>
    <div className={`formmenusearch`} id='menusearch'>
        <input 
        type="text" 
        value={searchval}
        className="pc-search" 
        name=""  
        placeholder={inputError ? "Please enter a valid search input" : "Search for Products , categories and more..."}
        onChange={setVal}
        onClick={getTrending}
        onKeyUp={getSearch}/>
        <button onClick={() => goToSearch()}>{isDisabled ? <i className='fas fa-magnifying-glass' ></i> : <i className='fas fa-times' ></i> }</button>
        
        {showSearch &&
        <div className="container search-pop">
            <div className="container">
                <div className="container search-drop">
                    {searchLoading ?
                    <div className='search-loading container'>
                        <LoadingIndicator segmentWidth={4} segmentLength={9}/> 
                    </div>
                    :
                    showProducts ?
                    <div className="container search-tags">
                        <div className="w98 float-right">
                            <div className="search-tag-head container">
                                <p>Discover products categories and more...</p>
                            </div>
                            <div className="container search-tag-list">
                                <ul>
                                    {trending != "" && trending != null &&
                                    trending.map((search,i) =>(
                                        <li key={i}>
                                            <p onClick={() =>trendClick(search.term)}>{search.term}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>    
                        </div>
                    </div>
                    :
                    searchProds.length < 1 ?
                    <div className='container noprodssearch'>
                        <div className='w90 float-center'>
                            <div className='container nosearchimg'>
                                <img src={noDataImg} alt='Nothing found' />
                            </div>
                            <div className='container notfoundtxt center-text'>
                                <p>Oops! Couldn't Find Anything Related to <br/><span>" {searchval} "</span> <br/>Try Searching Something Else</p>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                    <div className="container float-center">
                        <ul>
                        {searchProds.map((search,i) =>(
                            (() =>{
                                const name = search.name
                                let [mainmame, subtext] = name.split("***")
                                return(
                            <a href={
                                (search.type == "Product") ?
                                "/product/" +  naming(search.name,search.slug) 
                            +"?p_id=" + search.p_id +
                             "&m_id=" + search.m_id + 
                             "&b_id=" + search.b_id
                             : 
                              (search.type == "Category") ?
                              "/c/"+ search.id + "/" + naming(search.name,search.slug)
                             :
                             "/search/" + searchName(search.name)
                            }  
                            state ={{searchKey : search.name,vid : ""}}
                            onClick={closeSearch}
                             title={mainmame} key={i}>
                                <li>
                                    <div className="w95 float-center search-list-flex">
                                        <div className="sm">
                                            <div className="search-img">
                                                <img src={search.img} alt={mainmame}  referrerPolicy="no-referrer"/>
                                            </div>
                                            <div className="search-name">
                                                <div className='container'>
                                                    <p>{mainmame}</p>
                                                    {subtext != "" &&
                                                        <p className='highlightsearch'>{subtext}</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="search-go">
                                            <i data-feather='arrow-up-left'></i>
                                        </div>
                                    </div>
                                </li>
                            </a>
                            )
                            })()
                            ))}
                        </ul>
                    </div>
                    <div className="container search-tags">
                        <div className="w98 float-right">
                            <div className="search-tag-head container">
                                <p>Discover More</p>
                            </div>
                            <div className="container search-tag-list">
                                <ul>
                                {trending != "" && trending != null &&
                                    trending.map((search,i) =>(
                                        <li key={i}>
                                            <p onClick={() =>trendClick(search.term)}>{search.term}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>    
                        </div>
                    </div>
                    </>
                    }
                </div>
            </div>
        </div>
        }
    </div>
        {showSearch &&
    <div className='searchbackclick' onClick={closeSearch}></div>
        }
    </React.Fragment>
  )
}
